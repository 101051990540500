import React, { useState, useEffect, useContext } from "react";
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db, storage } from "../firebase";

import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import CurrencyInput from "react-currency-input-field";

const GestAdmin = () => {
  const [observacoes, setObservacoes] = useState("");

  const [caixatexto1, setCaixatexto1] = useState("");
  const [caixatexto2, setCaixatexto2] = useState("");
  const [caixatexto3, setCaixatexto3] = useState("");
  const [caixatexto4, setCaixatexto4] = useState("");
  const [caixatexto1tit, setCaixatexto1tit] = useState("");
  const [caixatexto2tit, setCaixatexto2tit] = useState("");
  const [caixatexto3tit, setCaixatexto3tit] = useState("");
  const [caixatexto4tit, setCaixatexto4tit] = useState("");
  const [probGanho, setProbGanho] = useState("");
  const [custndef, setCustndef] = useState("");
  const [honorarios, setHonorarios] = useState([]);
  const [taxa_sucesso, setTaxaSucesso] = useState("");
  const [poupanca, setPoupanca] = useState("");
  const [textopartilha, setTextopartilha] = useState("");
  const [textopartilha2, setTextopartilha2] = useState("");
  const [telefone, setTelefone] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAddingHonorario, setIsAddingHonorario] = useState(false);
  const [newHonorarioName, setNewHonorarioName] = useState("");
  const [newHonorarioDescription, setNewHonorarioDescription] = useState("");
  const [newHonorarioPrice, setNewHonorarioPrice] = useState(0);
  const [image1, setImage1] = useState("");
  const [image1URL, setImage1URL] = useState("");
  const [image2, setImage2] = useState("");
  const [image2URL, setImage2URL] = useState("");
  const [image3, setImage3] = useState("");
  const [image3URL, setImage3URL] = useState("");
  const [imgTitle1, setImgTitle1] = useState("");
  const [imgTitle2, setImgTitle2] = useState("");
  const [imgTitle3, setImgTitle3] = useState("");
  const [imgDesc1, setImgDesc1] = useState("");
  const [imgDesc2, setImgDesc2] = useState("");
  const [imgDesc3, setImgDesc3] = useState("");
  const [homeText, setHomeText] = useState("");

  const [custndefTitle, setCustndefTitle] = useState("");
  const [custndefDesc, setCustndefDesc] = useState("");
  const [probGanhoTitle, setprobGanhoTitle] = useState("");
  const [probGanhoDesc, setprobGanhoDesc] = useState("");

  useEffect(() => {
    const fetchDocument = async () => {
      const documentRef = doc(db, "global", "variables");
      const documentSnapshot = await getDoc(documentRef);

      if (documentSnapshot.exists()) {
        const documentData = documentSnapshot.data();
        setCaixatexto1(documentData.caixatexto1 || "");
        setCaixatexto2(documentData.caixatexto2 || "");
        setCaixatexto3(documentData.caixatexto3 || "");
        setCaixatexto4(documentData.caixatexto4 || "");
        setCaixatexto1tit(documentData.caixatexto1tit || "");
        setCaixatexto2tit(documentData.caixatexto2tit || "");
        setCaixatexto3tit(documentData.caixatexto3tit || "");
        setCaixatexto4tit(documentData.caixatexto4tit || "");
        setHonorarios(documentData.honorarios || []);
        setPoupanca(documentData.poupanca || "");
        setTaxaSucesso(documentData.taxa_sucesso || "");
        setTextopartilha(documentData.textopartilha || "");
        setTextopartilha2(documentData.textopartilha2 || "");
        setTelefone(documentData.telefone || "");
        setImage1URL(documentData.image1 || "");
        setImage2URL(documentData.image2 || "");
        setImage3URL(documentData.image3 || "");
        setCustndef(documentData.custndef || "");
        setProbGanho(documentData.probGanho || "");
        setHomeText(documentData.homeText || "");

        setImgTitle1(documentData.imgTitle1 || "");
        setImgTitle2(documentData.imgTitle2 || "");
        setImgTitle3(documentData.imgTitle3 || "");
        setImgDesc1(documentData.imgDesc1 || "");
        setImgDesc2(documentData.imgDesc2 || "");
        setImgDesc3(documentData.imgDesc3 || "");
        setCustndefTitle(documentData.custndefTitle || "");
        setCustndefDesc(documentData.custndefDesc || "");
        setprobGanhoTitle(documentData.probGanhoTitle || "");
        setprobGanhoDesc(documentData.probGanhoDesc || "");
      }

      setIsLoading(false);
    };

    fetchDocument();
  }, []);

  const uploadImageAndGetDownloadURL = async (file, name) => {
    const storageRef = ref(storage, `Images/${name}.jpg`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };

  const handleUpdateDocument = async () => {
    const documentRef = doc(db, "global", "variables");

    const dataToUpdate = {
      caixatexto1,
      caixatexto2,
      caixatexto3,
      caixatexto4,
      caixatexto1tit,
      caixatexto2tit,
      caixatexto3tit,
      caixatexto4tit,
      custndef,
      probGanho,
      honorarios,
      poupanca,
      taxa_sucesso,
      textopartilha,
      textopartilha2,
      telefone,
      imgTitle1,
      imgTitle2,
      imgTitle3,
      imgDesc1,
      imgDesc2,
      imgDesc3,
      custndefTitle,
      custndefDesc,
      probGanhoTitle,
      probGanhoDesc,
      homeText,
    };

    if (image1) {
      const imageURL = await uploadImageAndGetDownloadURL(image1, "image1");
      dataToUpdate.image1 = imageURL;
      setImage1(null);
    }
    if (image2) {
      const imageURL = await uploadImageAndGetDownloadURL(image2, "image2");
      dataToUpdate.image2 = imageURL;
      setImage2(null);
    }
    if (image3) {
      const imageURL = await uploadImageAndGetDownloadURL(image3, "image3");
      dataToUpdate.image3 = imageURL;
      setImage3(null);
    }

    await updateDoc(documentRef, dataToUpdate);

    alert("Editado com sucesso!");
  };

  const handleHonorarioNameChange = (index, value) => {
    const updatedHonorarios = [...honorarios];
    updatedHonorarios[index].name = value;
    setHonorarios(updatedHonorarios);
  };
  const handleHonorarioDescriptionChange = (index, value) => {
    const updatedHonorarios = [...honorarios];
    updatedHonorarios[index].description = value;
    setHonorarios(updatedHonorarios);
  };

  const handleHonorarioPriceChange = (index, value) => {
    const updatedHonorarios = [...honorarios];
    updatedHonorarios[index].price = value;
    setHonorarios(updatedHonorarios);
  };

  const handleDeleteHonorario = async (index) => {
    const updatedHonorarios = honorarios.filter((_, i) => i !== index);

    const documentRef = doc(db, "global", "variables");
    await updateDoc(documentRef, { honorarios: updatedHonorarios });

    setHonorarios(updatedHonorarios);

    alert("Honorario apagado com sucesso!");
  };

  const handleAddHonorario = async () => {
    const newHonorario = {
      name: newHonorarioName,
      description: newHonorarioDescription,
      price: newHonorarioPrice,
    };

    const documentRef = doc(db, "global", "variables");
    const documentSnapshot = await getDoc(documentRef);

    if (documentSnapshot.exists()) {
      const documentData = documentSnapshot.data();
      const updatedHonorarios = [...documentData.honorarios, newHonorario];

      await updateDoc(documentRef, { honorarios: updatedHonorarios });

      setHonorarios(updatedHonorarios);
      setNewHonorarioName("");
      setNewHonorarioDescription("");
      setNewHonorarioPrice("");
      setIsAddingHonorario(false);

      alert("Honorario added successfully!");
    }
  };

  const handleImageChange = (e, setImage, setImageURL) => {
    const file = e.target.files[0];
    setImage(file);
    const imageURL = URL.createObjectURL(file);
    setImageURL(imageURL);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ marginLeft: 300 }}>
      <h1 style={{ fontFamily: "Avenir Next" }}>Gestão</h1>
      <div
        style={{
          backgroundColor: "#fff",
          padding: 20,
          borderRadius: 5,
          marginRight: 35,
          boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.15)",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <input
            style={{
              width: "10%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "transparent",
            }}
            value={caixatexto1tit}
            onChange={(e) => setCaixatexto1tit(e.target.value)}
            type="text"
          />

          <input
            maxLength={300}
            style={{
              width: "85%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "transparent",
            }}
            value={caixatexto1}
            onChange={(e) => setCaixatexto1(e.target.value)}
            type="text"
          />

          <b style={{ fontSize: 12, fontFamily: "Avenir Next" }}>
            ({caixatexto1.length}/300)
          </b>
        </div>
        <br></br>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <input
            maxLength={300}
            style={{
              width: "10%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "transparent",
            }}
            value={caixatexto2tit}
            onChange={(e) => setCaixatexto2tit(e.target.value)}
            type="text"
          />
          <input
            maxLength={300}
            style={{
              width: "85%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "transparent",
            }}
            value={caixatexto2}
            onChange={(e) => setCaixatexto2(e.target.value)}
            type="text"
          />
          <b style={{ fontSize: 12, fontFamily: "Avenir Next" }}>
            ({caixatexto2.length}/300)
          </b>
        </div>
        <br></br>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <input
            style={{
              width: "10%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "transparent",
            }}
            value={caixatexto3tit}
            onChange={(e) => setCaixatexto3tit(e.target.value)}
            type="text"
          />
          <input
            style={{
              width: "85%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "transparent",
            }}
            value={caixatexto3}
            onChange={(e) => setCaixatexto3(e.target.value)}
            type="text"
          />
          <b style={{ fontSize: 12, fontFamily: "Avenir Next" }}>
            ({caixatexto3.length}/300)
          </b>
        </div>
        <br></br>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <input
            style={{
              width: "10%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "transparent",
            }}
            value={caixatexto4tit}
            onChange={(e) => setCaixatexto4tit(e.target.value)}
            type="text"
          />
          <input
            style={{
              width: "85%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "transparent",
            }}
            value={caixatexto4}
            onChange={(e) => setCaixatexto4(e.target.value)}
            type="text"
          />
          <b style={{ fontSize: 12, fontFamily: "Avenir Next" }}>
            ({caixatexto4.length}/300)
          </b>
        </div>
      </div>

      <br></br>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: 40,
        }}
      >
        <div style={{ width: "38vw" }}>
          <label style={{ fontFamily: "Avenir Next" }}>
            Texto - Menu Principal
          </label>
          <b style={{ marginLeft: 5, fontFamily: "Avenir Next", fontSize: 12 }}>
            ({homeText.length}/100)
          </b>
          <input
            maxLength={100}
            style={{
              width: "100%",
              marginTop: 10,
              borderWidth: 0,
              borderBottomWidth: 1,
              backgroundColor: "#f2f2f2",
              padding: 10,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
            }}
            value={homeText}
            onChange={(e) => setHomeText(e.target.value)}
            type="text"
          />
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <label style={{ fontFamily: "Avenir Next" }}>Taxa de Sucesso</label>
            <b
              style={{ marginLeft: 5, fontFamily: "Avenir Next", fontSize: 12 }}
            >
              ({taxa_sucesso.length}/4)
            </b>
            <input
              maxLength={4}
              style={{
                width: "95%",
                marginTop: 10,
                borderWidth: 0,
                borderBottomWidth: 1,
                backgroundColor: "#f2f2f2",
                padding: 10,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
              }}
              value={taxa_sucesso}
              onChange={(e) => setTaxaSucesso(e.target.value)}
              type="text"
            />
          </div>

          <div style={{ marginLeft: 15 }}>
            <label style={{ fontFamily: "Avenir Next" }}>Poupança</label>
            <b
              style={{ marginLeft: 5, fontFamily: "Avenir Next", fontSize: 12 }}
            >
              ({poupanca.length}/10)
            </b>

            <input
              maxLength={10}
              style={{
                width: "95%",
                marginTop: 10,
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                backgroundColor: "#f2f2f2",
                padding: 10,
                borderBottomColor: "rgb(33, 150, 243)",
              }}
              value={poupanca}
              onChange={(e) => setPoupanca(e.target.value)}
              type="text"
            />
          </div>
        </div>
      </div>
      <br></br>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.15)",
          padding: 20,
          borderRadius: 5,
          width: "95%",
        }}
      >
        <div style={{ flex: "40%" }}>
          <label style={{ fontFamily: "Avenir Next" }}>Texto Partilha</label>
          <b style={{ fontSize: 12, marginLeft: 5, fontFamily: "Avenir Next" }}>
            ({textopartilha.length}/300)
          </b>

          <input
            maxLength={300}
            style={{
              width: "95%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "transparent",
            }}
            value={textopartilha}
            onChange={(e) => setTextopartilha(e.target.value)}
            type="text"
          />
          <br></br>
        </div>
        <div style={{ flex: "40%" }}>
          <label style={{ fontFamily: "Avenir Next" }}>Texto Partilha 2</label>
          <b style={{ fontSize: 12, marginLeft: 5, fontFamily: "Avenir Next" }}>
            ({textopartilha2.length}/300)
          </b>

          <input
            maxLength={300}
            style={{
              width: "95%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "transparent",
            }}
            value={textopartilha2}
            onChange={(e) => setTextopartilha2(e.target.value)}
            type="text"
          />
        </div>
      </div>
      <div style={{ marginTop: 20, display: "flex" }}>
        <div>
          <label>Título</label>
          <input
            maxLength={30}
            style={{
              width: "95%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "#f2f2f2",
              padding: 10,
              marginTop: 10,
            }}
            value={probGanhoTitle}
            onChange={(e) => setprobGanhoTitle(e.target.value)}
            type="text"
          />
          <br></br>
          <br></br>
          <label>Descrição</label>
          <input
            maxLength={100}
            style={{
              width: "95%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "#f2f2f2",
              padding: 10,
              marginTop: 10,
            }}
            value={probGanhoDesc}
            onChange={(e) => setprobGanhoDesc(e.target.value)}
            type="text"
          />

          <b style={{ fontSize: 12, marginLeft: 5, fontFamily: "Avenir Next" }}>
            ({probGanho.length}/4)
          </b>

          <input
            maxLength={4}
            style={{
              width: "95%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "#f2f2f2",
              padding: 10,
              marginTop: 10,
            }}
            value={probGanho}
            onChange={(e) => setProbGanho(e.target.value)}
            type="text"
          />
        </div>
        <div style={{ marginLeft: 10 }}>
          <label>Título</label>
          <input
            maxLength={30}
            style={{
              width: "95%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "#f2f2f2",
              padding: 10,
              marginTop: 10,
            }}
            value={custndefTitle}
            onChange={(e) => setCustndefTitle(e.target.value)}
            type="text"
          />
          <br></br>
          <br></br>
          <label>Descrição</label>
          <input
            maxLength={100}
            style={{
              width: "95%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "#f2f2f2",
              padding: 10,
              marginTop: 10,
            }}
            value={custndefDesc}
            onChange={(e) => setCustndefDesc(e.target.value)}
            type="text"
          />
          <b style={{ fontSize: 12, marginLeft: 5, fontFamily: "Avenir Next" }}>
            ({custndef.length}/30)
          </b>

          <input
            maxLength={30}
            style={{
              width: "95%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "#f2f2f2",
              padding: 10,
              marginTop: 10,
            }}
            value={custndef}
            onChange={(e) => setCustndef(e.target.value)}
            type="text"
          />
        </div>
        <div style={{ marginLeft: 10 }}>
          <label style={{ fontFamily: "Avenir Next" }}>Telefone</label>
          <b style={{ fontSize: 12, marginLeft: 5, fontFamily: "Avenir Next" }}>
            ({telefone.length}/9)
          </b>

          <input
            maxLength={9}
            style={{
              width: "95%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "#f2f2f2",
              padding: 10,
              marginTop: 10,
            }}
            value={telefone}
            onChange={(e) => setTelefone(e.target.value)}
            type="text"
          />
        </div>
      </div>

      <h4>Honorários</h4>

      <table
        style={{
          backgroundColor: "#e8e8e9",
          paddingBottom: 10,
          marginTop: 10,
          paddingTop: 10,
          paddingLeft: 10,
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <tr style={{ borderRightWidth: 1 }}>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "10%",
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Nome
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "70%",
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            O que está incluído
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "10%",
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Preço
          </td>
        </tr>
      </table>

      {honorarios.map((honorario, index) => (
        <div style={{ display: "flex", width: "100%" }} key={index}>
          <input
            value={honorario.name}
            onChange={(e) => handleHonorarioNameChange(index, e.target.value)}
            type="text"
            style={{
              width: "10%",
              padding: 10,
              paddingLeft: 25,
              borderBottomWidth: 1,
              borderBottomColor: "rgb(33, 150, 243)",
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
            }}
          />
          <input
            value={honorario.description}
            onChange={(e) =>
              handleHonorarioDescriptionChange(index, e.target.value)
            }
            type="text"
            style={{
              width: "80%",
              marginLeft: 5,
              padding: 10,
              borderBottomWidth: 1,
              borderBottomColor: "rgb(33, 150, 243)",
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
            }}
          />

          <CurrencyInput
            placeholder="Preço do Honorário"
            defaultValue={1000}
            decimalsLimit={2}
            style={{
              marginLeft: 5,
              borderLeftWidth: 0,
              borderBottomWidth: 1,
              borderBottomColor: "rgb(33, 150, 243)",
              borderTopWidth: 0,
              width: "7%",
            }}
            suffix="€"
            value={honorario.price}
            onValueChange={(value) => handleHonorarioPriceChange(index, value)}
          />

          <button
            style={{
              width: "5%",
              border: 0,
              backgroundColor: "#f00",
              color: "#fff",
            }}
            onClick={() => handleDeleteHonorario(index)}
          >
            Apagar
          </button>
        </div>
      ))}

      {isAddingHonorario ? (
        <div style={{ marginTop: 10 }}>
          <input
            value={newHonorarioName}
            onChange={(e) => setNewHonorarioName(e.target.value)}
            type="text"
            placeholder="Nome do Honorario"
            style={{
              width: "10%",
              padding: 10,
              borderBottomWidth: 1,
              borderBottomColor: "rgb(33, 150, 243)",
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
            }}
          />
          <input
            value={newHonorarioDescription}
            onChange={(e) => setNewHonorarioDescription(e.target.value)}
            type="text"
            placeholder="Descrição do Honorario"
            style={{
              width: "75%",
              marginLeft: 5,
              padding: 10,
              borderBottomWidth: 1,
              borderBottomColor: "rgb(33, 150, 243)",
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
            }}
          />
          <CurrencyInput
            placeholder="Preço do Honorário"
            defaultValue={1000}
            decimalsLimit={2}
            suffix="€"
            style={{
              marginLeft: 5,
              paddingBottom: 10,
              borderRightWidth: 0,
              borderLeftWidth: 0,
              borderBottomWidth: 1,
              borderBottomColor: "rgb(33, 150, 243)",
              borderTopWidth: 0,
              width: "7%",
            }}
            onValueChange={(value, name) => setNewHonorarioPrice(value)}
          />

          <button
            style={{
              padding: 10,
              marginLeft: 5,
              borderRadius: 5,
              border: 0,
              width: "8%",
              fontSize: 14,
              marginTop: 10,
              color: "#fff",
              fontWeight: "500",
              cursor: "pointer",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
              backgroundColor: "rgb(33, 150, 243)",
              fontFamily: "Avenir Next",
            }}
            onClick={handleAddHonorario}
          >
            Adicionar
          </button>
        </div>
      ) : (
        <button
          style={{
            padding: 10,
            marginTop: 10,
            borderRadius: 5,
            border: 0,
            width: 200,
            fontSize: 14,
            color: "#fff",
            fontWeight: "500",
            cursor: "pointer",
            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
            backgroundColor: "rgb(33, 150, 243)",
            fontFamily: "Avenir Next",
          }}
          onClick={() => setIsAddingHonorario(true)}
        >
          Adicionar Novo Honorário
        </button>
      )}
      <h4>Imagens</h4>
      <div style={{ display: "flex", gap: "3rem" }}>
        <div>
          <p>Imagem 1</p>
          {image1URL && (
            <img
              style={{ width: 120, height: 120 }}
              src={image1URL}
              alt="Image 1"
            />
          )}
          <br></br>
          <input
            type="file"
            onChange={(e) => handleImageChange(e, setImage1, setImage1URL)}
          />
          <p>Título</p>
          <input
            maxLength={40}
            numberOfLines={2}
            style={{
              width: "120%",
              height: 50,
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "#f2f2f2",
              padding: 10,
              marginTop: 10,
            }}
            value={imgTitle1}
            onChange={(e) => setImgTitle1(e.target.value)}
            type="text"
          />
          <p>Descrição</p>
          <textarea
            maxLength={200}
            multiline
            style={{
              width: "120%",
              resize: "none",
              height: 50,
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "#f2f2f2",
              padding: 10,
              marginTop: 10,
            }}
            value={imgDesc1}
            onChange={(e) => setImgDesc1(e.target.value)}
            type="text"
          />
        </div>
        <br></br>
        <div style={{ marginLeft: 20 }}>
          <p>Imagem 2</p>
          {image2URL && (
            <img
              style={{ width: 120, height: 120 }}
              src={image2URL}
              alt="Image 2"
            />
          )}
          <div></div>
          <input
            type="file"
            onChange={(e) => handleImageChange(e, setImage2, setImage2URL)}
          />
          <p>Título</p>
          <input
            maxLength={40}
            style={{
              width: "120%",
              height: 50,
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "#f2f2f2",
              padding: 10,
              marginTop: 10,
            }}
            value={imgTitle2}
            onChange={(e) => setImgTitle2(e.target.value)}
            type="text"
          />
          <p>Descrição</p>
          <textarea
            maxLength={200}
            style={{
              resize: "none",
              width: "120%",
              height: 50,
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "#f2f2f2",
              padding: 10,
              marginTop: 10,
            }}
            value={imgDesc2}
            onChange={(e) => setImgDesc2(e.target.value)}
            type="text"
          />
        </div>
        <br></br>
        <div style={{ marginLeft: 20 }}>
          <p>Imagem 3</p>
          {image3URL && (
            <img
              style={{ width: 120, height: 120 }}
              src={image3URL}
              alt="Image 3"
            />
          )}
          <br></br>
          <input
            type="file"
            onChange={(e) => handleImageChange(e, setImage3, setImage3URL)}
          />
          <p>Título</p>
          <input
            maxLength={40}
            style={{
              width: "120%",
              height: 50,
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "#f2f2f2",
              padding: 10,
              marginTop: 10,
            }}
            value={imgTitle3}
            onChange={(e) => setImgTitle3(e.target.value)}
            type="text"
          />
          <p>Descrição</p>
          <textarea
            maxLength={200}
            style={{
              resize: "none",
              width: "120%",
              height: 50,
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "#f2f2f2",
              padding: 10,
              marginTop: 10,
            }}
            value={imgDesc3}
            onChange={(e) => setImgDesc3(e.target.value)}
            type="text"
          />
        </div>
      </div>
      <br></br>
      <br></br>
      <button
        style={{
          padding: 10,
          marginTop: 10,
          borderRadius: 5,
          border: 0,
          width: 200,
          fontSize: 14,
          color: "#fff",
          fontWeight: "500",
          cursor: "pointer",
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
          backgroundColor: "rgb(33, 150, 243)",
          fontFamily: "Avenir Next",
        }}
        onClick={handleUpdateDocument}
      >
        Guardar Alterações
      </button>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default GestAdmin;
