import React, { useEffect, useState } from "react";
import { db, auth } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Conta = () => {
  const { user, logout } = UserAuth();

  const navigate = useNavigate();

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [nif, setNif] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (user) {
      setFname(user.fname);
      setLname(user.lname);
      setEmail(user.email);
      setNif(user.nif);
    }
  }, [user]);

  const changePassword = () => {
    sendPasswordResetEmail(auth, email);
    alert("Foi enviado um pedido para o seu email");
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/signin");
      alert("Sessão Terminada");
      console.log("You are logged out");
      window.location.reload();
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateDoc(doc(db, "administradores", user.id), {
        fname,
        lname,
        nif,
        email,
      });
      console.log("Document Added");
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      <h1 style={{ marginLeft: 300, opacity: 0, fontFamily: "Avenir Next" }}>
        Conta
      </h1>
      <div style={{ marginLeft: 300 }}>
        <form onSubmit={handleSubmit}>
          <fieldset
            style={{
              boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
              border: "0px",
              borderRadius: 5,
              marginRight: 30,
              marginTop: 70,
              paddingBottom: 80,
            }}
          >
            <div
              style={{
                marginTop: -20,
                width: "100%",
                boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div
                style={{
                  backgroundColor: "#2196f3",
                  fontWeight: "500",
                  fontSize: "18px",
                  fontFamily: "Avenir Next",
                  color: "#fff",
                  paddingLeft: 20,
                  paddingRight: 0,
                  paddingTop: 20,
                  borderTopRightRadius: 5,
                  borderTopLeftRadius: 5,
                }}
              >
                Minha Conta
              </div>
              <div
                style={{
                  backgroundColor: "#2196f3",
                  fontFamily: "Avenir Next",
                  color: "#fff",
                  paddingLeft: 20,
                  paddingRight: 0,
                  paddingBottom: 20,
                  marginTop: -1,
                  paddingTop: 10,
                  borderBottomRightRadius: 5,
                  borderBottomLeftRadius: 5,
                }}
              >
                {user.id + " " + user.fname + " " + user.lname}
              </div>
            </div>
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                paddingLeft: 10,
                paddingTop: 10,
              }}
            >
              <div style={{ display: "block", flex: "50%" }}>
                <p
                  style={{
                    fontFamily: "Avenir Next",
                    color: "#8c8c8c",
                    fontSize: "14px",
                  }}
                >
                  Nº interno
                </p>
                <h4
                  style={{
                    marginTop: -15,
                    fontFamily: "Avenir Next",
                    color: "#8c8c8c",
                    fontWeight: "500",
                    borderBottom: "1px solid #e8e8e9",
                    marginRight: 10,
                    paddingBottom: 10,
                  }}
                >
                  {user.id}
                </h4>
              </div>

              <div style={{ display: "block", flex: "50%" }}>
                <p
                  style={{
                    fontFamily: "Avenir Next",
                    color: "#8c8c8c",
                    fontSize: "14px",
                  }}
                >
                  Nome
                </p>
                <h4
                  style={{
                    marginTop: -15,
                    fontFamily: "Avenir Next",
                    color: "#8c8c8c",
                    fontWeight: "500",
                    borderBottom: "1px solid #e8e8e9",
                    marginRight: 10,
                    paddingBottom: 10,
                  }}
                >
                  {user.fname + " " + user.lname}
                </h4>
              </div>
            </div>

            <div
              style={{
                display: "inline-flex",
                width: "100%",
                paddingLeft: 10,
                paddingTop: 0,
              }}
            >
              <div
                style={{
                  display: "block",
                  flex: "50%",
                  borderBottom: "1px solid #e8e8e9",
                  marginRight: 15,
                }}
              >
                <p
                  style={{
                    fontFamily: "Avenir Next",
                    color: "#8c8c8c",
                    fontSize: "14px",
                  }}
                >
                  Primeiro Nome
                </p>
                <input
                  type="text"
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                  style={{
                    marginTop: -20,
                    fontFamily: "Avenir Next",
                    color: "#444",
                    fontWeight: "500",
                    borderBottom: "1px solid #e8e8e9",
                    marginRight: 10,
                    width: "100%",
                    fontSize: "16px",
                    paddingBottom: 10,
                    border: 0,
                  }}
                />
              </div>

              <div
                style={{
                  display: "block",
                  flex: "50%",
                  borderBottom: "1px solid #e8e8e9",
                  marginRight: 15,
                }}
              >
                <p
                  style={{
                    fontFamily: "Avenir Next",
                    color: "#8c8c8c",
                    fontSize: "14px",
                  }}
                >
                  Último Nome
                </p>
                <input
                  type="text"
                  value={lname}
                  onChange={(e) => setLname(e.target.value)}
                  style={{
                    marginTop: -20,
                    fontFamily: "Avenir Next",
                    color: "#444",
                    fontWeight: "500",
                    borderBottom: "1px solid #e8e8e9",
                    marginRight: 10,
                    width: "100%",
                    fontSize: "16px",
                    paddingBottom: 10,
                    border: 0,
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: "inline-flex",
                width: "100%",
                paddingLeft: 10,
                paddingTop: 0,
              }}
            >
              <div
                style={{
                  display: "block",
                  flex: "50%",
                  borderBottom: "1px solid #e8e8e9",
                  marginRight: 15,
                }}
              >
                <p
                  style={{
                    fontFamily: "Avenir Next",
                    color: "#8c8c8c",
                    fontSize: "14px",
                  }}
                >
                  E-mail
                </p>
                <p
                  style={{
                    fontFamily: "Avenir Next",
                    color: "#8c8c8c",
                    fontSize: "14px",
                  }}
                >
                  {email}
                </p>
              </div>

              <div
                style={{
                  display: "block",
                  flex: "50%",
                  borderBottom: "1px solid #e8e8e9",
                  marginRight: 15,
                }}
              >
                <p
                  style={{
                    fontFamily: "Avenir Next",
                    color: "#8c8c8c",
                    fontSize: "14px",
                  }}
                >
                  Telemóvel
                </p>
                <input
                  type="text"
                  value={nif}
                  onChange={(e) => setNif(e.target.value)}
                  style={{
                    marginTop: -20,
                    fontFamily: "Avenir Next",
                    color: "#444",
                    fontWeight: "500",
                    borderBottom: "1px solid #e8e8e9",
                    marginRight: 10,
                    width: "100%",
                    fontSize: "16px",
                    paddingBottom: 10,
                    border: 0,
                  }}
                />
              </div>
            </div>

            <button
              style={{
                backgroundColor: "#2196f3",
                marginTop: 15,
                borderRadius: 5,
                border: 0,
                padding: 15,
                fontSize: 12,
                marginRight: 45,
                width: "100px",
                position: "absolute",
                fontFamily: "Avenir Next",
                color: "#fff",
                right: 0,
              }}
              type="text"
            >
              GUARDAR
            </button>
          </fieldset>
        </form>
        <button
          style={{
            backgroundColor: "#2196f3",
            marginTop: -65,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            marginRight: 45,
            width: "100px",
            position: "absolute",
            fontFamily: "Avenir Next",
            color: "#fff",
            left: 325,
          }}
          type="text"
          onClick={changePassword}
        >
          Mudar Pass
        </button>
        <button
          style={{
            backgroundColor: "#f00",
            marginTop: -65,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            marginRight: 0,
            width: "150px",
            position: "absolute",
            fontFamily: "Avenir Next",
            color: "#fff",
            left: 440,
          }}
          onClick={handleLogout}
        >
          Terminar Sessão
        </button>
      </div>
    </div>
  );
};

export default Conta;
