import { Link, useNavigate } from "react-router-dom";

const AtividadeCard2 = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <table
        border={1}
        style={{
          backgroundColor: "#fff",
          borderWidth: 0.6,
          borderColor: "#e8e8e9",
          paddingBottom: 10,
          paddingLeft: 300,
          paddingTop: 10,
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <tr>
          <Link
            style={{ textDecoration: "none" }}
            to={`/tarefas/${props.value.item.id}`}
          >
            <>
              <td
                style={{
                  borderWidth: 0,
                  borderColor: "#000",
                  width: 150,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                  color: "#000",
                }}
              >
                {props.value.item.processo}
              </td>
              <td
                style={{
                  borderWidth: 0,
                  borderColor: "#000",
                  width: 200,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                  color: "#000",
                }}
              >
                {props.value.item.admin}
              </td>
              <td
                style={{
                  borderWidth: 0,
                  borderColor: "#000",
                  width: 250,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                  color: "#000",
                }}
              >
                {props.value.item.observacoes}
              </td>
              {props.value.item.dataConclu != null ? (
                <td
                  style={{
                    borderWidth: 0,
                    borderColor: "#000",
                    width: 200,
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                    color: "#000",
                  }}
                >
                  {String(
                    new Date(
                      props.value.item.dataConclu.seconds * 1000
                    ).getDate()
                  ) +
                    "/" +
                    String(
                      new Date(
                        props.value.item.dataConclu.seconds * 1000
                      ).getMonth() + 1
                    ) +
                    "/" +
                    String(
                      new Date(
                        props.value.item.dataConclu.seconds * 1000
                      ).getFullYear()
                    )}
                </td>
              ) : (
                <td
                  style={{
                    borderWidth: 0,
                    borderColor: "#000",
                    width: 200,
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                    color: "#000",
                  }}
                >
                  Não definida
                </td>
              )}
              {props.value.item.dataPrevA != null ? (
                <td
                  style={{
                    borderWidth: 0,
                    borderColor: "#000",
                    width: 200,
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                    color: "#000",
                  }}
                >
                  {String(
                    new Date(
                      props.value.item.dataPrevA.seconds * 1000
                    ).getDate()
                  ) +
                    "/" +
                    String(
                      new Date(
                        props.value.item.dataPrevA.seconds * 1000
                      ).getMonth() + 1
                    ) +
                    "/" +
                    String(
                      new Date(
                        props.value.item.dataPrevA.seconds * 1000
                      ).getFullYear()
                    )}
                </td>
              ) : (
                <td
                  style={{
                    borderWidth: 0,
                    borderColor: "#000",
                    width: 200,
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                    color: "#000",
                  }}
                >
                  Não definida
                </td>
              )}
            </>
          </Link>

          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 0,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            <button
              onClick={() => navigate(`/tarefas/${props.value.item.id}/edit`)}
              style={{  padding: 3, borderRadius: 5, border: 0, width: 80, fontSize: 14, color: "#fff", fontWeight: "500", cursor: "pointer", boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)", backgroundColor: "rgb(33, 150, 243", fontFamily: "Avenir Next" }}
            >
              Editar
            </button>

          </td>
        </tr>
      </table>
    </div>
  );
};

export default AtividadeCard2;
