import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { db, auth } from "../firebase";
import { sendPasswordResetEmail } from "firebase/auth";

const Signin = () => {
  const { signIn } = UserAuth();

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [forgotPassword, setForgotPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await signIn(email, password);
      navigate("/");
      window.location.reload();
    } catch (e) {
      console.log(e.message);
    }
  };

  const get = (e) => {
    e.preventDefault();
    try {
      sendPasswordResetEmail(auth, email);
      alert("Foi enviado um pedido para o seu email");
    } catch (e) {
      alert(`Erro ao enviar email de redefinição de senha: ${e.message}`);
    }
  };

  return (
    <div
      style={{
        width: 500,
        padding: 15,
        borderRadius: 15,
        marginBottom: 15,
        left: 0,
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
        position: "absolute",
        marginTop: 200,
        borderWidth: 1,
        borderColor: "#f1f1f1",
        boxShadow: "2px 2px 10px rgba(0, 0, 0 ,0.1)",
      }}
    >
      <div>
        <h1 style={{ fontFamily: "Avenir Next" }}>
          {forgotPassword
            ? "Recupera a tua palavra passe"
            : "Entra na tua conta"}
        </h1>
      </div>
      <form onSubmit={forgotPassword ? get : handleSubmit}>
        <div style={{ marginBottom: 30 }}>
          <label style={{ fontFamily: "Avenir Next" }}>Email</label>
          <br></br>
          <input
            style={{
              width: "95%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
            }}
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
        </div>
        {forgotPassword == false && (
          <div>
            <label style={{ fontFamily: "Avenir Next" }}>Password</label>
            <br></br>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
              }}
              required
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
          </div>
        )}
        <button
          style={{
            backgroundColor: "#2196f3",
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
          }}
          type="text"
        >
          {forgotPassword ? "Recuperar" : "Entrar"}
        </button>
      </form>
        
      <div style={{display: "flex", alignItems: "center", marginTop: 15, justifyContent: "space-between"}}>
       
        <button style={{width: 100,
              textDecoration: "none",
              fontSize: 14,
              borderRadius: 5,
              padding: 5,
              color: "rgb(33, 150, 243)",
              backgroundColor: "#f2f2f2",
              fontWeight: "500",
              cursor: "pointer",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
              fontFamily: "Avenir Next",
              border: 0,}}>
          <Link
            style={{color:"rgb(33, 150, 243)", textDecoration: "none" }}
            to="/signup"
          >
            Novo?
          </Link>
        </button>
        <button style={{width: 200,
                        fontSize: 14,
                        borderRadius: 5,
                        padding: 5,
                        color: "rgb(33, 150, 243)",
                        fontWeight: "500",
                        cursor: "pointer",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                        fontFamily: "Avenir Next",
                        border: 0,
                      }} 
          onClick={() => setForgotPassword(!forgotPassword)}>
          {forgotPassword ? "Voltar ao login" : "Recuperar palavra-passe"}
        </button>
      </div>
    </div>
  );
};

export default Signin;
