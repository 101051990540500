const ProcessoCard2 = (props) => {
  const options = [
    {
      value: "Elaboração defesa",
      label: "Elaboração defesa",
    },
    {
      value: "Enviada defesa",
      label: "Enviada defesa",
    },
    {
      value: "Aguarda Decisão",
      label: "Aguarda Decisão",
    },
    { value: "Por pagar", label: "Aguarda Pagamento" },
    { value: "Pago", label: "Pagamento Efetuado" },
    { value: "Pedido de Reembolso", label: "Pedido de Reembolso" },
    { value: "Concluído", label: "Concluído" },
  ];

  const estadoLabel = options.find(
    (option) => option.value === props.value.estado
  )?.label;
  return (
    <div>
      <table
        border={1}
        style={{
          backgroundColor: "#fff",
          borderWidth: 0.6,
          borderColor: "#e8e8e9",
          paddingBottom: 10,
          paddingTop: 10,
          paddingLeft: 300,
          justifyContent: "space-evenly",
          width: "100%",
          paddingRight: 20,
        }}
      >
        <tr style={{ borderWidth: 0 }}>
          <td
            style={{
              borderWidth: 0,
              borderLeftColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.item.id}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 150,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.item.dataCriacao.toDate().toLocaleDateString("en-GB")}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.item.infracao.infracao}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.isMorto == true
              ? "Morto"
              : estadoLabel || props.value.estado}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.client.gestor.fname} {props.value.client.gestor.lname}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default ProcessoCard2;
