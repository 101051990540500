import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Navigate, useLocation, useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from "../firebase";
import AtividadeCard from "../components/cards/AtividadeCard";
import DatePicker from "react-date-picker";
import { distritos, concelhos, tribunais } from "../libs/courts";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  uploadBytes,
  getMetadata,
} from "firebase/storage";

function EditProcesso(props) {
  const { affairId } = useParams();
  console.log(affairId);
  const [selectedImage, setSelectedImage] = useState(null); // constante nao e a mesma do processo

  const navigate = useNavigate();

  const [dadoscliente, setDadosliente] = useState("");
  const [entidade, setEntidade] = useState("");
  const [tipo_de_veiculo, setTipo_de_veiculo] = useState("");
  const [distrito, setDistrito] = useState("");
  const [concelho, setConcelho] = useState("");
  const [tribunal, setTribunal] = useState("");
  const [dateNotifi, setDateNotifi] = useState("");
  const [dateInfra, setDateInfra] = useState("");
  const [prazo, setPrazo] = useState("");
  const [pagouCoima, setPagouCoima] = useState("");
  const [envolveu_em_acidente, setEnvolveu_em_acidente] = useState("");
  const [envolveu_em_acidenteTxt, setEnvolveu_em_acidenteTxt] = useState("");
  const [infracao, setInfracao] = useState(null);
  const [dateTri, setDateTri] = useState("");
  const [dataDecisao, setDataDecisao] = useState("");

  const [decisaoAdmin, setDecisaoAdmin] = useState("");
  const [decisaoJudicial, setDecisaoJudicial] = useState("");
  const [dataDefesa, setDataDefesa] = useState(null);
  const [dataPrecricao, setDataPrecricao] = useState(null);
  const [dataPagamento, setDataPagamento] = useState("");

  const [dataConclusao, setDataConclusao] = useState(null);

  const [nmr_do_auto, setNmr_do_auto] = useState("");
  const [ref_multibanco, setRef_multibanco] = useState("");

  const [distritosOptions, setDistritosOptions] = useState([]);
  const [concelhosOptions, setConcelhosOptions] = useState([]);
  const [infracoesOptions, setInfracoesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [estado, setEstado] = useState("");
  const [pagamento, setPagamento] = useState(null);

  const [source, setSource] = useState(null);
  const [source2, setSource2] = useState(null);
  const [updatedFoto, setUpdatedFoto] = useState(null);
  const [updatedFoto2, setUpdatedFoto2] = useState(null);

  const [observacoes, setObservacoes] = useState("");

  const [userId, setUserId] = useState("");

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [affairFiles, setAffairFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const [manualPay, setManualPay] = useState(null);

  const [isMorto, setIsMorto] = useState(false);

  useEffect(() => {
    fetchData();
  }, [affairId]);

  const fetchData = async () => {
    const fetchFileNames = async (files) => {
      const storage = getStorage();
      const names = await Promise.all(
        files.map(async (file) => {
          const fileRef = ref(storage, file);
          const metadata = await getMetadata(fileRef);
          return metadata.name || "Unnamed File";
        })
      );
      setFileNames(names);
    };
    const fetchAffair = async () => {
      const docRef = doc(db, "processos", affairId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const affairData = docSnap.data();
        const userId = affairData.userId;

        if (userId) {
          const userDocRef = doc(db, "users", userId);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = { ...userDocSnap.data(), id: userDocSnap.id };
            const affairWithUserData = {
              id: docSnap.id,
              ...affairData,
              user: userData,
            };
            setDadosliente(userData);
            setEntidade(affairData.entidade);
            setTipo_de_veiculo(affairData.tipo_de_veiculo);
            setObservacoes(affairData.observacoes);

            setDistrito(affairData.distrito);
            setConcelho(affairData.concelho);
            setTribunal(affairData.tribunal);
            setDataPagamento(affairData.dataPagamento);
            setDateNotifi(
              affairData.dateNotifi != null
                ? affairData.dateNotifi.toDate()
                : null
            );
            setDateInfra(
              affairData.dateInfra != null
                ? affairData.dateInfra.toDate()
                : null
            );
            setPrazo(affairData.prazo);
            setPagouCoima(affairData.pagouCoima);
            setEnvolveu_em_acidente(affairData.envolveu_em_acidente);
            setEnvolveu_em_acidenteTxt(affairData.envolveu_em_acidenteTxt);
            setInfracao(affairData.infracao);
            setDateTri(
              affairData.dateTri != null ? affairData.dateTri.toDate() : null
            );
            setDataDecisao(
              affairData.dataDecisao != null
                ? affairData.dataDecisao.toDate()
                : null
            );
            setDataConclusao(
              affairData.dataConclusao != null
                ? affairData.dataConclusao.toDate()
                : null
            );

            setIsMorto(affairData.isMorto);

            setDecisaoAdmin(affairData.decisaoAdmin);
            setDecisaoJudicial(affairData.decisaoJudicial);
            setDataDefesa(
              affairData.dataDefesa != null
                ? affairData.dataDefesa.toDate()
                : null
            );
            setDataPrecricao(
              affairData.dataPrecricao != null
                ? affairData.dataPrecricao.toDate()
                : null
            );
            setUpdatedFoto(affairData.source);
            setUpdatedFoto2(affairData.source2);
            setSource(affairData.source);
            setSource2(affairData.source2);
            setPagamento(affairData.pagamento);
            setEstado(affairData.estado);
            setUserId(affairData.userId);
            setManualPay(affairData.estado == "Por pagar");
            setAffairFiles(affairData.files);
            fetchFileNames(affairData.files);
          } else {
            console.log("User document does not exist!");
          }
        } else {
          console.log("User field is not present in the document!");
        }
      } else {
        console.log("No such document!");
      }
    };

    const fetchInfracoes = async () => {
      const docRef = doc(db, "global", "infracoes");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const infracoesData = docSnap.data().infracoes;

        const options = infracoesData.map((infracao) => ({
          value: infracao,
          label: infracao.infracao,
        }));

        setInfracoesOptions(options);
      } else {
        console.log("No such document!");
      }
    };

    await Promise.all([fetchAffair(), fetchInfracoes()]);

    setIsLoading(false); // Set loading state to false
  };

  useEffect(() => {
    const fetchOptions = () => {
      const options = distritos.map((distrito) => ({
        value: distrito,
        label: distrito,
      }));

      const options2 = concelhos.map((concelhoGroup) =>
        concelhoGroup.map((concelho) => ({ value: concelho, label: concelho }))
      );

      setDistritosOptions(options);
      setConcelhosOptions(options2);
    };

    fetchOptions();
  }, []);

  if (isLoading) {
    return <h1>Loading</h1>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedFiles.length > 0) {
      console.log("sentrou");
      handleUpload();
    }

    try {
      if (updatedFoto) {
        await handlePhotoSaving(updatedFoto, "frente");
      }

      if (updatedFoto2) {
        await handlePhotoSaving(updatedFoto2, "verso");
      }
      await updateDoc(doc(db, "processos", affairId), {
        entidade,
        tipo_de_veiculo,
        distrito,
        concelho,
        tribunal,
        dateNotifi:
          dateNotifi !== null ? Timestamp.fromDate(dateNotifi) : dateNotifi,
        dateInfra:
          dateInfra !== null ? Timestamp.fromDate(dateInfra) : dateInfra,
        dateTri: dateTri !== null ? Timestamp.fromDate(dateTri) : dateTri,
        dataPrecricao:
          dataPrecricao !== null
            ? Timestamp.fromDate(dataPrecricao)
            : dataPrecricao,
        dataDefesa:
          dataDefesa !== null ? Timestamp.fromDate(dataDefesa) : dataDefesa,
        dataDecisao:
          dataDecisao !== null ? Timestamp.fromDate(dataDecisao) : dataDecisao,
        prazo,
        envolveu_em_acidente,
        envolveu_em_acidenteTxt,
        isMorto,
        dataConclusao,
        pagouCoima,
        infracao,
        decisaoAdmin,
        decisaoJudicial,
        pagamento,
        estado,
        observacoes,
        dataPagamento:
          manualPay == true && estado == "Pago" ? new Date() : "N/D",
      });

      console.log("Photos Saved");
      alert("Informações guardadas com sucesso.");
    } catch (e) {
      console.log(e.message);
    }
  };

  const handlePhotoSaving = async (image, verso) => {
    try {
      const childPath = `Anexos/${userId}/${verso}/${affairId}`;
      const response = await fetch(image);
      const blob = await response.blob();
      const storage = getStorage();
      const storageRef = ref(storage, childPath);
      const uploadTask = uploadBytesResumable(storageRef, blob);
      const taskProgress = (snapshot) => {
        console.log(`transferred: ${snapshot.bytesTransferred}`);
      };
      const taskCompleted = async () => {
        try {
          const downloadURL = await getDownloadURL(storageRef);
          if (verso === "frente") {
            setUpdatedFoto(downloadURL);
            setSource(downloadURL);
          } else {
            setUpdatedFoto2(downloadURL);
            setSource2(downloadURL);
          }
          await updateDoc(doc(db, "processos", affairId), {
            source: verso === "frente" ? downloadURL : source,
            source2: verso === "verso" ? downloadURL : source2,
          });
        } catch (error) {
          console.log(error);
        }
      };

      const taskError = (error) => {
        console.log(error);
      };
      uploadTask.on("state_changed", taskProgress, taskError, taskCompleted);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDeletePhoto = async (verso) => {
    try {
      const childPath = `Anexos/${userId}/${verso}/${affairId}`;
      const storage = getStorage();
      const storageRef = ref(storage, childPath);

      await deleteObject(storageRef);

      if (verso === "frente") {
        setUpdatedFoto(null);
        setSource(null);
      } else {
        setUpdatedFoto2(null);
        setSource2(null);
      }

      await updateDoc(doc(db, "processos", affairId), {
        source: verso === "frente" ? null : source,
        source2: verso === "verso" ? null : source2,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFileNames = async (files) => {
    const storage = getStorage();
    const names = await Promise.all(
      files.map(async (file) => {
        const fileRef = ref(storage, file);
        const metadata = await getMetadata(fileRef);
        return metadata.name || "Unnamed File";
      })
    );
    setFileNames(names);
  };

  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files);
    setSelectedFiles(filesArray);
  };

  function cleanFileName(fileName) {
    // Remove special characters and replace accented characters
    const cleanedFileName = fileName
      .normalize("NFD") // Normalize accented characters
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritic marks
      .replace(/[^\w\s.-]/g, "") // Remove special characters except for space, period, and hyphen
      .replace(/\s+/g, "_") // Replace spaces with underscores
      .replace(/_+/g, "_"); // Replace consecutive underscores with a single underscore

    return cleanedFileName;
  }

  const handleUpload = async () => {
    // Upload files to Firebase Storage and get download URLs

    try {
      const storage = getStorage();

      const downloadURLs = await Promise.all(
        selectedFiles.map(async (file) => {
          const fileNameWithoutSpecialChars = cleanFileName(file.name);
          const storageRef = ref(
            storage,
            `Processos/${affairId}/${fileNameWithoutSpecialChars}`
          );

          await uploadBytes(storageRef, file);
          return getDownloadURL(storageRef);
        })
      );

      // Initialize affairFiles with an empty array if it's undefined
      const updatedAffairFiles = affairFiles || [];

      // Update affairFiles with the new download URLs
      const userRef = doc(db, "processos", affairId);
      await updateDoc(userRef, {
        files: [...updatedAffairFiles, ...downloadURLs],
      });

      console.log("Download URLs:", downloadURLs);
    } catch (e) {
      console.log(e.message);
    }
    fetchData();
  };

  const handleDelete = async (index, fileURL) => {
    const storage = getStorage();
    const fileRef = ref(storage, fileURL);

    // Delete the file from Firebase Storage
    await deleteObject(fileRef);

    // Remove the file URL from clientFiles state
    const updatedClientFiles = affairFiles.filter((url, i) => i !== index);
    setAffairFiles(updatedClientFiles);

    // Update the fetched data
    const userRef = doc(db, "processos", affairId);
    await updateDoc(userRef, { files: updatedClientFiles });

    // Update the file names
    fetchFileNames(updatedClientFiles);
  };

  const options = [
    {
      value: "Elaboração defesa",
      label: "Elaboração defesa",
    },
    {
      value: "Enviada defesa",
      label: "Enviada defesa",
    },
    {
      value: "Aguarda Decisão",
      label: "Aguarda Decisão",
    },
    { value: "Por pagar", label: "Aguarda Pagamento" },
    { value: "Pago", label: "Pagamento Efetuado" },
    { value: "Pedido de Reembolso", label: "Pedido de Reembolso" },
    { value: "Concluído", label: "Concluído" },
  ];

  const entOptions = [
    { value: "GNR", label: "GNR" },
    { value: "PSP", label: "PSP" },
    { value: "IMT", label: "IMT" },
    { value: "ASNR", label: "ASNR" },
    { value: "Outra", label: "Outra" },
  ];

  const veicOptions = [
    { value: "Veículo Ligeiro", label: "Veículo Ligeiro" },
    { value: "Moto", label: "Moto" },
    { value: "Veículo Pesado", label: "Veículo Pesado" },
    {
      value: "Veículo Pesado de Mercadorias",
      label: "Veículo Pesado de Mercadoria",
    },
  ];

  const prazoOptions = [
    { value: "Ignoro", label: "Ignoro" },
    { value: "5 dias", label: "5 dias" },
    { value: "10 dias", label: "10 dias" },
    { value: "15 dias", label: "15 dias" },
    { value: "20 dias", label: "20 dias" },
    { value: "25 dias", label: "25 dias" },
    { value: "30 dias", label: "30 dias" },
  ];

  const pagouCoimaOptions = [
    { value: "", label: "" },
    { value: "Não", label: "Não" },
    {
      value: "Sim, dentro do período das 48h",
      label: "Sim, dentro do período das 48h",
    },
    {
      value: "Sim, 48h depois da infração",
      label: "Sim, 48h depois da infração",
    },
  ];

  return (
    <div>
      <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
        Editar Assunto
      </h1>
      <form onSubmit={handleSubmit}>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              N.º do assunto
            </td>
            <p
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
                margin: 0,
              }}
            >
              {affairId}
            </p>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Cliente
            </td>
            <p>
              <Link
                style={{ textDecoration: "none", color: "rgb(33, 150, 243)" }}
                to={`/clientes/${dadoscliente.id}`}
              >
                {dadoscliente.fname} {dadoscliente.lname}
              </Link>
            </p>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Entidade atuante
            </td>
            <Select
              options={entOptions}
              value={entOptions.find((option) => option.value === entidade)}
              onChange={(value) => setEntidade(value.value)}
              placeholder="Selecionar"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Tipo de veículo
            </td>
            <Select
              options={veicOptions}
              value={veicOptions.find(
                (option) => option.value === tipo_de_veiculo
              )}
              onChange={(value) => {
                setTipo_de_veiculo(value.value);
                console.log(value.value);
              }}
              placeholder="Selecionar"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Distrito
            </td>
            <Select
              options={distritosOptions}
              value={distritosOptions.find(
                (option) => option.value === distrito
              )}
              onChange={(selectedOption) => {
                setDistrito(selectedOption.value);
                setConcelho(
                  concelhos[distritos.indexOf(selectedOption.value)][0]
                );
                setTribunal(
                  tribunais[distritos.indexOf(selectedOption.value)][0]
                );
              }}
              placeholder="Selecionar"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Concelho
            </td>
            <Select
              options={concelhosOptions[distritos.indexOf(distrito)]}
              value={concelhosOptions[distritos.indexOf(distrito)].find(
                (option) => option.value === concelho
              )}
              onChange={(selectedOption) => {
                setConcelho(selectedOption.value);
                setTribunal(
                  tribunais[distritos.indexOf(distrito)][
                    concelhos[distritos.indexOf(distrito)].indexOf(
                      selectedOption.value
                    )
                  ]
                );
              }}
              placeholder="Selecionar"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Tribunal
            </td>
            {tribunal}
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data da Notificação
            </td>
            <DatePicker
              maxDate={new Date()}
              onChange={setDateNotifi}
              value={dateNotifi}
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data da infração
            </td>
            <DatePicker
              maxDate={new Date()}
              onChange={setDateInfra}
              value={dateInfra}
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Prazo na carta para se defender
            </td>
            <Select
              options={prazoOptions}
              value={prazoOptions.find((option) => option.value === prazo)}
              onChange={(value) => setPrazo(value.value)}
              placeholder="Selecionar"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Pagou Coima?
            </td>
            <Select
              options={pagouCoimaOptions}
              value={pagouCoimaOptions.find(
                (option) => option.value === pagouCoima
              )}
              onChange={(value) => setPagouCoima(value.value)}
              placeholder="Selecionar"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data para julgamento
            </td>
            <DatePicker onChange={setDateTri} value={dateTri} />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Envolveu um acidente de aviação
            </td>
            <p
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
                margin: 0,
              }}
            >
              <input
                type="checkbox"
                checked={envolveu_em_acidente}
                onChange={() => setEnvolveu_em_acidente(!envolveu_em_acidente)}
              />
              <input
                maxLength={300}
                style={{
                  width: "95%",
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  fontFamily: "Avenir Next",
                  fontSize: 14,
                  borderBottomColor: "rgb(33, 150, 243)",
                  backgroundColor: "transparent",
                }}
                onChange={(e) => setEnvolveu_em_acidenteTxt(e.target.value)}
                value={envolveu_em_acidenteTxt}
                type="text"
              />
            </p>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Infração
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              <Select
                options={infracoesOptions}
                value={infracoesOptions.find(
                  (option) => option.label === infracao.infracao
                )}
                onChange={(value) => setInfracao(value.value)}
                placeholder="Selecionar"
              />
              <br></br>
              Moldura de Coima: {infracao.moldura}€ - {infracao.moldura2}€
              <br></br>
              Pontos das Carta de Condução: {infracao.pontos}
              <br></br>
              {infracao.sancao != "" &&
                infracao.sancao != null &&
                "Sanção Acessória: " + infracao.sancao}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data da Prescrição
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              <DatePicker onChange={setDataPrecricao} value={dataPrecricao} />
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data da defesa
            </td>
            <DatePicker onChange={setDataDefesa} value={dataDefesa} />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Decisão Administrativa
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setDecisaoAdmin(e.target.value)}
              value={decisaoAdmin}
              type="text"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Decisão Judicial
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setDecisaoJudicial(e.target.value)}
              value={decisaoJudicial}
              type="text"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Foto do auto frontal
            </td>

            {updatedFoto && (
              <div>
                <a download target={"_blank"} href={updatedFoto}>
                  <img
                    style={{ borderRadius: 15, width: 110 }}
                    src={updatedFoto}
                    alt="Auto frontal"
                  />
                </a>
                <br />
                <button
                  style={{
                    backgroundColor: "#2196f3",
                    padding: 15,
                    border: 0,
                    borderRadius: 5,
                    color: "#fff",
                    fontFamily: "Avenir Next",
                  }}
                  onClick={() => handleDeletePhoto("frente")}
                >
                  Eliminar
                </button>
              </div>
            )}

            <br />

            <br />
            <input
              type="file"
              name="myImage"
              onChange={(event) => {
                setUpdatedFoto(URL.createObjectURL(event.target.files[0]));
              }}
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Foto do auto verso
            </td>
            {updatedFoto2 && (
              <div>
                <a download target={"_blank"} href={updatedFoto2}>
                  <img
                    style={{ borderRadius: 15, width: 110 }}
                    src={updatedFoto2}
                    alt="Auto verso"
                  />
                </a>
                <br />
                <button
                  style={{
                    backgroundColor: "#2196f3",
                    padding: 15,
                    border: 0,
                    borderRadius: 5,
                    color: "#fff",
                    fontFamily: "Avenir Next",
                  }}
                  onClick={() => handleDeletePhoto("verso")}
                >
                  Eliminar
                </button>
              </div>
            )}

            <br />

            <br />
            <input
              type="file"
              name="myImage"
              onChange={(event) => {
                setUpdatedFoto2(URL.createObjectURL(event.target.files[0]));
              }}
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Ficheiros
              </td>
              
              <div>
                {fileNames.map((fileName, index) => (
                  <div key={index}>
                    <p>{fileName}</p>
                    <button
                      style={{
                        marginTop: -15,
                        marginBottom: 10,
                        padding: 10,
                        borderRadius: 5,
                        border: 0,
                        width: 120,
                        fontSize: 14,
                        color: "#fff",
                        fontWeight: "500",
                        cursor: "pointer",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                        backgroundColor: "rgb(33, 150, 243)",
                        fontFamily: "Avenir Next",
                      }}
                      type="button"
                      onClick={() => window.open(affairFiles[index], "_blank")}
                    >
                      Abrir
                    </button>
                    <button
                      type="button"
                      style={{
                        marginTop: -15,
                        marginBottom: 10,
                        padding: 10,
                        marginLeft: 10,
                        borderRadius: 5,
                        border: 0,
                        width: 120,
                        fontSize: 14,
                        color: "#fff",
                        fontWeight: "500",
                        cursor: "pointer",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                        backgroundColor: "rgb(255, 60, 60)",
                        fontFamily: "Avenir Next",
                      }}
                      onClick={() => handleDelete(index, affairFiles[index])}
                    >
                      Apagar
                    </button>
                  </div>
                ))}
              </div>
            <input type="file" multiple onChange={handleFileChange} />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Observações
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setObservacoes(e.target.value)}
              value={observacoes}
              type="text"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de Decisão
            </td>
            <DatePicker onChange={setDataDecisao} value={dataDecisao} />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de conclusão
            </td>
            <DatePicker onChange={setDataConclusao} value={dataConclusao} />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Estado do Processo
            </td>
            <br></br>
            <div style={{ alignItems: "center", display: "flex" }}>
              <label style={{ fontFamily: "Avenir Next" }}>Morto?</label>
              <input
                style={{
                  borderWidth: 0,
                  marginLeft: 10,
                  marginBottom: 2,
                  borderBottomWidth: 1,
                  fontFamily: "Avenir Next",
                  fontSize: 14,
                  borderBottomColor: "rgb(33, 150, 243)",
                  backgroundColor: "transparent",
                }}
                type="checkbox"
                checked={isMorto}
                onChange={() => setIsMorto(!isMorto)}
              />
            </div>
            <br></br>
            {isMorto == false && (
              <Select
                options={options}
                value={options.find((option) => option.value === estado)}
                onChange={(value) => setEstado(value.value)}
                placeholder="Selecionar"
              />
            )}
          </tr>
        </table>

        <h1
          style={{ paddingLeft: 300, marginTop: 25, fontFamily: "Avenir Next" }}
        >
          Referência MB
        </h1>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Ref Multibanco
            </td>
            {pagamento.referencia}
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Fatura/Recibo emitida
            </td>
            A definir
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#ffffff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Montante
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {pagamento.preco}€
            </td>
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de pagamento
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {dataPagamento == "N/D"
                ? dataPagamento
                : dataPagamento.toDate().toLocaleDateString("en-GB")}
            </td>
          </tr>
        </table>

        <button
          style={{
            backgroundColor: "#2196f3",
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
            marginLeft: 300,
          }}
          type="text"
        >
          Guardar
        </button>
      </form>
    </div>
  );
}

export default EditProcesso;
