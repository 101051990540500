import React from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

const ProtectedRoute2 = ({ children }) => {
  const { user, loading } = UserAuth();

  const currentURL = window.location.href;
  console.log(currentURL);

  if (user.fname != null && loading == false) {
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedRoute2;
