import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  addDoc,
  serverTimestamp,
  orderBy,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import AtividadeCard2 from "../components/cards/AtividadeCard2";
import { db } from "../firebase";
import Select from "react-select";
import DatePicker from "react-date-picker";

function Reuniao(props) {
  const { reuniaoId } = useParams();

  const [reuniao, setReuniao] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAffair = async () => {
      const docRef = doc(db, "reunioes", reuniaoId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const affairData = docSnap.data();
        const userId = affairData.userId;

        if (userId) {
          const userDocRef = doc(db, "users", userId);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            const gestorId = userData.gestorId;

            if (gestorId) {
              const gestorDocRef = doc(db, "administradores", affairData.admin);
              const gestorDocSnap = await getDoc(gestorDocRef);

              if (gestorDocSnap.exists()) {
                const gestorData = gestorDocSnap.data();
                const affairWithUserData = {
                  id: docSnap.id,
                  ...affairData,
                  user: {
                    ...userData,
                    gestor: gestorData,
                  },
                };
                setReuniao(affairWithUserData);
              } else {
                console.log("Gestor document does not exist!");
              }
            } else {
              console.log(
                "gestorId field is not present in the user document!"
              );
            }
          } else {
            console.log("User document does not exist!");
          }
        } else {
          console.log("User field is not present in the document!");
        }
      } else {
        console.log("No such document!");
      }

      setLoading(false); // Set loading to false after fetching the data
    };

    fetchAffair();
  }, [reuniaoId]);

  if (loading) {
    return <h1 style={{ marginLeft: 300 }}>Loading...</h1>;
  }

  const convertTimestampToStringHours = (meetingSlot) => {
    const date = meetingSlot.toDate();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  return (
    <div>
      <div>
        <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>Reunião</h1>
        <Link
          style={{ textDecoration: "none" }}
          to={`/reunioes/${reuniao.id}/editar`}
          state={{ reuniao }}
        >
          <button
            style={{
              marginLeft: 300,
              marginTop: -15,
              marginBottom: 25,
              padding: 10,
              borderRadius: 5,
              border: 0,
              width: 100,
              fontSize: 14,
              color: "#000",
              fontWeight: "500",
              cursor: "pointer",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
              backgroundColor: "#e8e8e9",
              fontFamily: "Avenir Next",
            }}
          >
            Editar
          </button>
        </Link>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Cliente
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              <Link
                style={{ textDecoration: "none" }}
                to={`/clientes/${reuniao.userId}`}
              >
                {reuniao.user.fname} {reuniao.user.lname}
              </Link>
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {reuniao.meetingSlot.toDate().toLocaleDateString("en-GB")}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Hora
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {convertTimestampToStringHours(reuniao.meetingSlot)}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Gestor
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {reuniao.user.gestor.fname} {reuniao.user.gestor.lname}
            </td>
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Estado
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {reuniao.estado}
            </td>
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Assunto
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {reuniao.assunto}
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default Reuniao;
