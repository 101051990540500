const PagamentoCard = (props) => {
  const options = [
    {
      value: "Elaboração defesa",
      label: "Elaboração defesa",
    },
    {
      value: "Enviada defesa",
      label: "Enviada defesa",
    },
    {
      value: "Aguarda Decisão",
      label: "Aguarda Decisão",
    },
    { value: "Por pagar", label: "Aguarda Pagamento" },
    { value: "Pago", label: "Pagamento Efetuado" },
    { value: "Pedido de Reembolso", label: "Pedido de Reembolso" },
    { value: "Concluído", label: "Concluído" },
  ];

  const estadoLabel = options.find(
    (option) => option.value === props.value.estado
  )?.label;
  return (
    <div>
      <table
        border={1}
        style={{
          backgroundColor: "#fff",
          borderWidth: 0.6,
          borderColor: "#e8e8e9",
          paddingBottom: 10,
          paddingTop: 10,
          paddingLeft: 300,
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <tr>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 190,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.id}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 190,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.infracao.infracao}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.user.gestor.fname} {props.value.user.gestor.lname}
          </td>

          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 150,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.pagamento.referencia}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.plano.name}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.user.fname} {props.value.user.lname}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.user.nif != "" ? props.value.user.nif : "a definir"}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.pagamento.preco} €
          </td>

          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 70,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.isMorto == true
              ? "Morto"
              : estadoLabel || props.value.estado}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.dataPagamento != "N/D"
              ? String(
                  new Date(props.value.dataPagamento.seconds * 1000).getDate()
                ) +
                "/" +
                String(
                  new Date(
                    props.value.dataPagamento.seconds * 1000
                  ).getMonth() + 1
                ) +
                "/" +
                String(
                  new Date(
                    props.value.dataPagamento.seconds * 1000
                  ).getFullYear()
                )
              : "N/D"}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default PagamentoCard;
