import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { auth, db } from "../firebase";
const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { createUser } = UserAuth();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createUser(email, password);

      console.log("Document Added");
      navigate("/");
      window.location.reload();
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div
      style={{
        width: 500,
        padding: 15,
        borderRadius: 15,
        marginBottom: 15,
        left: 0,
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
        position: "absolute",
        marginTop: 200,
        borderWidth: 1,
        borderColor: "#f1f1f1",
        boxShadow: "2px 2px 10px rgba(0, 0, 0 ,0.1)",
      }}
    >
      <div>
        <h1 style={{ fontFamily: "Avenir Next" }}>Criar Conta</h1>
        <p style={{ marginTop: -20, marginBottom: 40 }}>
          <Link
            style={{ color: "rgb(33, 150, 243)", fontFamily: "Avenir Next" }}
            to="/signin"
          >
            Já tenho conta
          </Link>
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: 30 }}>
          <label style={{ fontFamily: "Avenir Next" }}>Email</label>
          <br></br>
          <input
            style={{
              width: "95%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
            }}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
        </div>

        <div>
          <label style={{ fontFamily: "Avenir Next" }}>Password</label>
          <br></br>
          <input
            style={{
              width: "95%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
            }}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </div>
        <button
          style={{
            backgroundColor: "#2196f3",
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
          }}
          type="text"
        >
          Criar Conta
        </button>
      </form>
    </div>
  );
};

export default Signup;
