const CrmCard = (props) => {
  const formatTime = (timestamp) => {
    if (!timestamp) {
      return "";
    }

    const date = timestamp.toDate();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  };
  return (
    <div>
      <table
        border={1}
        style={{
          backgroundColor: "#fff",
          paddingLeft: 15,
          paddingTop: 10,
          borderWidth: 0.6,
          borderColor: "#e8e8e9",
          paddingBottom: 10,
          width: "100%",
        }}
      >
        <tr style={{ borderWidth: 0 }}>
          <td
            style={{
              borderWidth: 0,
              borderLeftColor: "#000",
              minWidth: 150,
              maxWidth: 150,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.fname} {props.value.lname}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderLeftColor: "#000",
              minWidth: 200,
              maxWidth: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.email}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderLeftColor: "#000",
              minWidth: 120,
              maxWidth: 120,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.phone}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderLeftColor: "#000",
              minWidth: 180,
              maxWidth: 180,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.gestor.fname} {props.value.gestor.lname}
          </td>

          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              minWidth: 120,
              maxWidth: 120,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.tipo}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              minWidth: 120,
              maxWidth: 120,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.feito}
          </td>
          {props.value.dataPrev != null ? (
            <td
              style={{
                borderWidth: 0,
                borderColor: "#000",
                minWidth: 120,
                maxWidth: 120,
                fontFamily: "Avenir Next",
                fontWeight: "500",
                color: "#000",
              }}
            >
              {String(new Date(props.value.dataPrev.seconds * 1000).getDate()) +
                "/" +
                String(
                  new Date(props.value.dataPrev.seconds * 1000).getMonth() + 1
                ) +
                "/" +
                String(
                  new Date(props.value.dataPrev.seconds * 1000).getFullYear()
                )}
            </td>
          ) : (
            <td
              style={{
                borderWidth: 0,
                borderColor: "#000",
                minWidth: 120,
                maxWidth: 120,
                fontFamily: "Avenir Next",
                fontWeight: "500",
                color: "#000",
              }}
            >
              Não definida
            </td>
          )}
          {props.value.dataRea != null ? (
            <td
              style={{
                borderWidth: 0,
                borderColor: "#000",
                minWidth: 150,
                maxWidth: 150,
                fontFamily: "Avenir Next",
                fontWeight: "500",
                color: "#000",
              }}
            >
              {String(new Date(props.value.dataRea.seconds * 1000).getDate()) +
                "/" +
                String(
                  new Date(props.value.dataRea.seconds * 1000).getMonth() + 1
                ) +
                "/" +
                String(
                  new Date(props.value.dataRea.seconds * 1000).getFullYear()
                )}{" "}
              {formatTime(props.value.dataRea)}
            </td>
          ) : (
            <td
              style={{
                borderWidth: 0,
                borderColor: "#000",
                minWidth: 150,
                maxWidth: 150,
                fontFamily: "Avenir Next",
                fontWeight: "500",
                color: "#000",
              }}
            >
              Não definida
            </td>
          )}
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
              minWidth: 250,
              maxWidth: 250,
            }}
          >
            {props.value.observacoes}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default CrmCard;
