import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  orderBy,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import Select from "react-select";
import PagamentoCard from "../components/cards/PagamentoCard";

const Pagamentos = (props) => {
  const [pagamentos, setPagamentos] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [clients, setClients] = useState([]);

  const [estadoQuery, setEstadoQuery] = useState("");
  const [gestorQuery, setGestorQuery] = useState("");
  const [clienteQuery, setClienteQuery] = useState("");

  const fetchPagamentoss = async () => {
    const affairsCollection = collection(db, "processos");

    // Query for documents with non-null "dataPagamento" in descending order
    const queryWithPagamento = query(
      affairsCollection,
      where("dataPagamento", "!=", "N/D"),
      orderBy("dataPagamento", "desc")
    );

    // Query for documents with null "dataPagamento" in descending order by "dataCriacao"
    const queryWithoutPagamento = query(
      affairsCollection,
      where("dataPagamento", "==", "N/D"),
      orderBy("dataCriacao", "desc")
    );

    // Get snapshots for both queries
    const withPagamentoSnapshot = await getDocs(queryWithPagamento);
    const withoutPagamentoSnapshot = await getDocs(queryWithoutPagamento);

    // Combine the results
    const fetchedAffairs = [];

    await Promise.all(
      withPagamentoSnapshot.docs.map(async (docSnapshot) => {
        const affair = docSnapshot.data();
        const affairId = docSnapshot.id;
        const userId = affair.userId;

        // Fetch user and gestor data here...
        const userDocRef = doc(db, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);
        const userData = userDocSnapshot.data();
        const gestorId = userData.gestorId;

        const gestorDocRef = doc(db, "administradores", gestorId);
        const gestorDocSnapshot = await getDoc(gestorDocRef);
        const gestorData = gestorDocSnapshot.data();

        const processedAffair = {
          id: affairId,
          user: {
            ...userData,
            gestor: gestorData,
          },
          ...affair,
        };

        fetchedAffairs.push(processedAffair);
      })
    );

    await Promise.all(
      withoutPagamentoSnapshot.docs.map(async (docSnapshot) => {
        const affair = docSnapshot.data();
        const affairId = docSnapshot.id;
        const userId = affair.userId;

        // Fetch user and gestor data here...
        const userDocRef = doc(db, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);
        const userData = userDocSnapshot.data();
        const gestorId = userData.gestorId;

        const gestorDocRef = doc(db, "administradores", gestorId);
        const gestorDocSnapshot = await getDoc(gestorDocRef);
        const gestorData = gestorDocSnapshot.data();

        const processedAffair = {
          id: affairId,
          user: {
            ...userData,
            gestor: gestorData,
          },
          ...affair,
        };

        fetchedAffairs.push(processedAffair);
      })
    );

    // Now, set the sorted list to your state variable
    setPagamentos(fetchedAffairs);
  };

  const fetchPagamentos = async () => {
    const affairsCollection = collection(db, "processos");
    // Add orderBy clause to order the documents by the 'dataCriacao' field
    const queryy = query(affairsCollection, orderBy("dataCriacao", "desc"));
    const affairsSnapshot = await getDocs(queryy);

    const fetchedAffairs = [];

    for (const docSnapshot of affairsSnapshot.docs) {
      const affair = docSnapshot.data();
      const affairId = docSnapshot.id;
      const userId = affair.userId;

      const userDocRef = doc(db, "users", userId);
      const userDocSnapshot = await getDoc(userDocRef);
      const userData = userDocSnapshot.data();
      const gestorId = userData.gestorId;

      const gestorDocRef = doc(db, "administradores", gestorId);
      const gestorDocSnapshot = await getDoc(gestorDocRef);
      const gestorData = gestorDocSnapshot.data();

      const processedAffair = {
        id: affairId,
        user: {
          ...userData,
          gestor: gestorData,
        },
        ...affair,
      };

      fetchedAffairs.push(processedAffair);
    }

    setPagamentos(fetchedAffairs);
  };

  const fetchAdmins = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "administradores"));
      const docList = [];
      setAdmins([]);
      querySnapshot.docs.map((doc) =>
        docList.push({
          value: doc.id,
          label: doc.data().fname + " " + doc.data().lname,
        })
      );
      setAdmins(docList);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchClients = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const docList = [];
      setClients([]);
      querySnapshot.docs.map((doc) =>
        docList.push({
          value: doc.id,
          label:
            doc.data().fname +
            " " +
            doc.data().lname +
            " - " +
            doc.data().email,
        })
      );
      setClients(docList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPagamentos();
    fetchAdmins();
    fetchClients();
  }, []);

  const filteredAffairs = pagamentos.filter((item) => {
    // Filter by estado
    if (estadoQuery === "Morto") {
      // Filter by isMorto when "Morto" is selected
      return item.isMorto === true;
    } else if (estadoQuery && item.estado !== estadoQuery) {
      // Filter by estado when any other option is selected
      return false;
    }

    // Filter by gestor
    if (gestorQuery && item.user.gestorId !== gestorQuery) {
      return false;
    }

    // Filter by client
    if (clienteQuery && item.userId !== clienteQuery) {
      return false;
    }

    return !item.isMorto;
  });

  const options = [
    {
      value: "Elaboração defesa",
      label: "Elaboração defesa",
    },
    {
      value: "Enviada defesa",
      label: "Enviada defesa",
    },
    {
      value: "Aguarda Decisão",
      label: "Aguarda Decisão",
    },
    { value: "Por pagar", label: "Aguarda Pagamento" },
    { value: "Pago", label: "Pagamento Efetuado" },
    { value: "Pedido de Reembolso", label: "Pedido de Reembolso" },
    { value: "Concluído", label: "Concluído" },
    { value: "Morto", label: "Morto" },
  ];

  return (
    <div>
      <div>
        <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
          Pagamentos
        </h1>

        <div style={{ marginLeft: 300, marginBottom: 10 }}>
          <h2 style={{ fontFamily: "Avenir Next" }}>Filtrar por:</h2>
          <p>Estado</p>
          {/* <h1>{estadoQuery}</h1> */}

          <div style={{ display: "flex" }}>
            <div style={{ width: "90%" }}>
              <Select
                options={options}
                autosize={false}
                value={
                  estadoQuery === ""
                    ? { value: "", label: "Selecionar" }
                    : options.find(
                        (option) => option.value === estadoQuery
                      ) || { value: "", label: "Selecionar" }
                }
                onChange={(selectedOption) =>
                  setEstadoQuery(selectedOption.value)
                }
                placeholder="Selecionar"
              />
            </div>

            <button
              style={{
                padding: 5,
                marginLeft: 10,
                borderRadius: 5,
                border: 0,
                width: 120,
                fontSize: 14,
                color: "#000",
                fontWeight: "500",
                cursor: "pointer",
                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                backgroundColor: "#e8e8e9",
                fontFamily: "Avenir Next",
              }}
              onClick={() => setEstadoQuery("")}
            >
              Limpar Filtro
            </button>
          </div>
          <p>Gestor</p>

          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ width: "90%" }}>
              <Select
                options={admins}
                value={
                  gestorQuery === ""
                    ? { value: "", label: "Selecionar" }
                    : admins.find((admin) => admin.value === gestorQuery)
                }
                onChange={(value) => setGestorQuery(value.value)}
                placeholder="Selecionar"
              />
            </div>

            <button
              style={{
                padding: 5,
                marginLeft: 10,
                borderRadius: 5,
                border: 0,
                width: 120,
                fontSize: 14,
                color: "#000",
                fontWeight: "500",
                cursor: "pointer",
                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                backgroundColor: "#e8e8e9",
                fontFamily: "Avenir Next",
              }}
              onClick={() => setGestorQuery("")}
            >
              Limpar Filtro
            </button>
          </div>

          <p>Cliente</p>

          <div style={{ display: "flex", marginBottom: 30 }}>
            <div style={{ width: "90%" }}>
              <Select
                options={clients}
                value={
                  clienteQuery === ""
                    ? { value: "", label: "Selecionar" }
                    : clients.find((client) => client.value === clienteQuery)
                }
                onChange={(value) => setClienteQuery(value.value)}
                placeholder="Selecionar"
              />
            </div>
            <button
              style={{
                padding: 5,
                marginLeft: 10,
                borderRadius: 5,
                border: 0,
                width: 120,
                fontSize: 14,
                color: "#000",
                fontWeight: "500",
                cursor: "pointer",
                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                backgroundColor: "#e8e8e9",
                fontFamily: "Avenir Next",
              }}
              onClick={() => setClienteQuery("")}
            >
              Limpar Filtro
            </button>
          </div>
        </div>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <tr style={{}}>
            <td
              style={{
                borderColor: "#000",
                width: 200,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              ID
            </td>
            <td
              style={{
                borderColor: "#000",
                width: 200,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Infração
            </td>
            <td
              style={{
                borderColor: "#000",
                width: 200,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Gestor do Processo
            </td>

            <td
              style={{
                borderColor: "#000",
                width: 150,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Referência
            </td>
            <td
              style={{
                borderColor: "#000",
                width: 100,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Plano
            </td>
            <td
              style={{
                borderColor: "#000",
                width: 250,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Subscritor
            </td>
            <td
              style={{
                borderColor: "#000",
                width: 100,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              NIF
            </td>
            <td
              style={{
                borderColor: "#000",
                width: 100,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Preço
            </td>

            <td
              style={{
                borderColor: "#000",
                width: 70,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Estado
            </td>
            <td
              style={{
                borderColor: "#000",
                width: 250,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de Pagamento
            </td>
          </tr>
        </table>
        {filteredAffairs.map((pagamento, index) => (
          <div className="box" key={index}>
            <Link
              style={{ textDecoration: "none" }}
              to={`/pagamentos/${pagamento.id}`}
              state={{ pagamento }}
            >
              <PagamentoCard key={index} value={pagamento} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pagamentos;
