import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  orderBy,
  serverTimestamp,
  addDoc,
  updateDoc,
  arrayUnion,
  Timestamp,
} from "firebase/firestore";
import { db, storage } from "../firebase";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  getMetadata,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";
import DatePicker from "react-date-picker";

function Carteira(props) {
  const { clientId, carteiraId } = useParams();

  const [client, setClient] = useState(null);
  const [carteira, setCarteira] = useState(null);

  const [loading, setLoading] = useState(true);

  const fetchClientData = async () => {
    try {
      const userDocRef = doc(db, "users", clientId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const clientIdWithId = { ...userData, userId: userDoc.id };

        setClient(clientIdWithId);
      } else {
        throw new Error("User not found");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  };

  const fetchCarteira = async () => {
    try {
      const userDocRef = doc(db, "matriculas", carteiraId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();

        // Iterate through userData and convert timestamp fields to Date objects
        for (const key in userData) {
          if (
            userData.hasOwnProperty(key) &&
            userData[key] instanceof Timestamp
          ) {
            userData[key] = userData[key].toDate();
          }
        }

        const clientIdWithId = { ...userData, userId: userDoc.id };

        setCarteira(clientIdWithId);
      } else {
        throw new Error("User not found");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchClientData();
        await fetchCarteira();
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  function sliceString(str) {
    if (str.includes("Front")) {
      return [str.slice(0, 5), str.slice(5)];
    }
    return [str.slice(0, 4), str.slice(4)];
  }

  function clientOrWallet(string) {
    if (
      string == "Carta de Condução" ||
      string == "Documento de Identificação"
    ) {
      return client;
    }
    return carteira;
  }

  function clientOrWallet2(string) {
    if (
      string == "Carta de Condução" ||
      string == "Documento de Identificação"
    ) {
      return setClient;
    }
    return setCarteira;
  }
  function clientOrWallet3(string) {
    if (
      string.includes("CartadeConducao") ||
      string.includes("DocumentodeIdentificacao")
    ) {
      return client;
    }
    return carteira;
  }

  const documents = [
    "FrontCartadeConducao",
    "BackCartadeConducao",
    "FrontDocumentodeIdentificacao",
    "BackDocumentodeIdentificacao",
    "FrontDocumentoUnicoAutomovel",
    "BackDocumentoUnicoAutomovel",
    "FrontSeguroAutomovel",
    "BackSeguroAutomovel",
    "FrontCertificadodeInspecao",
    "BackCertificadodeInspecao",
  ];

  const documents2 = [
    "Carta de Condução",
    "Documento de Identificação",
    "Documento Único Automóvel",
    "Seguro Automóvel",
    "Certificado de Inspeção",
  ];

  function trimthatShit(string) {
    return string
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s.-]/g, "")
      .replace(/\s+/g, "");
  }

  const handlePhotoSaving = async () => {
    try {
      for (let i = 0; i < documents.length; i++) {
        console.log(documents[i]);
        if (
          clientOrWallet3(documents[i])[documents[i]].includes(
            "firebasestorage"
          ) == false &&
          clientOrWallet3(documents[i])[documents[i]] != ""
        ) {
          const nameSegment =
            documents[i].includes("CartadeConducao") ||
            documents[i].includes("DocumentodeIdentificacao")
              ? ""
              : `${carteiraId}/`;
          const childPath = `Carteira/${clientId}/${
            sliceString(documents[i])[1]
          }/${nameSegment}${sliceString(documents[i])[0]}`;
          const response = await fetch(
            clientOrWallet3(documents[i])[documents[i]]
          );
          const blob = await response.blob();
          const storageRef = ref(storage, childPath);
          const uploadTask = uploadBytesResumable(storageRef, blob);
          const taskProgress = (snapshot) => {
            console.log(`transferred: ${snapshot.bytesTransferred}`);
          };
          const taskCompleted = async () => {
            const isCartadeConducaoOrDocumentodeIdentificacao =
              documents[i].includes("CartadeConducao") ||
              documents[i].includes("DocumentodeIdentificacao");

            const coll = isCartadeConducaoOrDocumentodeIdentificacao
              ? "users"
              : "matriculas";
            const collId = isCartadeConducaoOrDocumentodeIdentificacao
              ? clientId
              : carteiraId;

            const downloadURL = await getDownloadURL(storageRef);
            await updateDoc(doc(db, coll, collId), {
              [documents[i]]: downloadURL,
              addedFiles: arrayUnion(sliceString(documents[i])[1]),
            });
          };

          const taskError = (error) => {
            console.log(error);
          };
          uploadTask.on(
            "state_changed",
            taskProgress,
            taskError,
            taskCompleted
          );
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await handlePhotoSaving();

    try {
      await updateDoc(doc(db, "matriculas", carteiraId), carteira);
      console.log("Document Added");
      alert("Alterações guardadas com sucesso");
    } catch (e) {
      console.log(e.message);
    }
  };

  if (loading) {
    return (
      <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
        Carregando...
      </h1>
    );
  }

  return (
    <div style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
      <h1>
        Carteira - {client.fname} {client.lname}
      </h1>
      <form onSubmit={handleSubmit}>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 10,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Matricula
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              <input
                style={{
                  width: "95%",
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  fontFamily: "Avenir Next",
                  fontSize: 14,
                  borderBottomColor: "rgb(33, 150, 243)",
                  backgroundColor: "transparent",
                }}
                onChange={(e) =>
                  setCarteira({ ...carteira, matricula: e.target.value })
                }
                value={carteira.matricula}
                type="text"
              />
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 10,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Marca
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              <input
                style={{
                  width: "95%",
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  fontFamily: "Avenir Next",
                  fontSize: 14,
                  borderBottomColor: "rgb(33, 150, 243)",
                  backgroundColor: "transparent",
                }}
                onChange={(e) =>
                  setCarteira({ ...carteira, marca: e.target.value })
                }
                value={carteira.marca}
                type="text"
              />
            </td>
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 10,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Modelo
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              <input
                style={{
                  width: "95%",
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  fontFamily: "Avenir Next",
                  fontSize: 14,
                  borderBottomColor: "rgb(33, 150, 243)",
                  backgroundColor: "transparent",
                }}
                onChange={(e) =>
                  setCarteira({ ...carteira, modelo: e.target.value })
                }
                value={carteira.modelo}
                type="text"
              />
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 10,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de Compra
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              <DatePicker
                onChange={(value) =>
                  setCarteira({ ...carteira, dateComp: value })
                }
                value={carteira.dateComp}
              />
            </td>
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 10,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de Venda
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              <DatePicker
                onChange={(value) =>
                  setCarteira({ ...carteira, dateVend: value })
                }
                value={carteira.dateVend}
              />
            </td>
          </tr>
        </table>
        {documents2.map((document) => {
          return (
            <div>
              <h2>{document}</h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginRight: 60,
                }}
              >
                <div>
                  <h3>Frente</h3>
                  {clientOrWallet(document)["Front" + trimthatShit(document)] ==
                  "" ? (
                    <p>Ainda sem imagem</p>
                  ) : (
                    <a
                      download
                      target={"_blank"}
                      href={
                        clientOrWallet(document)[
                          "Front" + trimthatShit(document)
                        ]
                      }
                    >
                      <img
                        style={{ borderRadius: 15, width: 110 }}
                        src={
                          clientOrWallet(document)[
                            "Front" + trimthatShit(document)
                          ]
                        }
                        alt="frente"
                      />
                    </a>
                  )}
                  <br></br>
                  <input
                    type="file"
                    name="myImage"
                    onChange={(event) => {
                      clientOrWallet2(document)({
                        ...clientOrWallet(document),
                        ["Front" + trimthatShit(document)]: URL.createObjectURL(
                          event.target.files[0]
                        ),
                      });
                    }}
                  />
                </div>
                <div>
                  <h3>Verso</h3>
                  {clientOrWallet(document)["Back" + trimthatShit(document)] ==
                  "" ? (
                    <p>Ainda sem imagem</p>
                  ) : (
                    <a
                      download
                      target={"_blank"}
                      href={
                        clientOrWallet(document)[
                          "Back" + trimthatShit(document)
                        ]
                      }
                    >
                      <img
                        style={{ borderRadius: 15, width: 110 }}
                        src={
                          clientOrWallet(document)[
                            "Back" + trimthatShit(document)
                          ]
                        }
                        alt="verso"
                      />
                    </a>
                  )}
                  <br></br>
                  <input
                    type="file"
                    name="myImage"
                    onChange={(event) => {
                      clientOrWallet2(document)({
                        ...clientOrWallet(document),
                        ["Back" + trimthatShit(document)]: URL.createObjectURL(
                          event.target.files[0]
                        ),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}

        <button
          style={{
            backgroundColor: "#2196f3",
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
          }}
          type="text"
        >
          Guardar
        </button>
      </form>
    </div>
  );
}

export default Carteira;
