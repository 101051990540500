const entOptions = [
  { value: "GNR", label: "GNR" },
  { value: "PSP", label: "PSP" },
  { value: "IMT", label: "IMT" },
  { value: "ASNR", label: "ASNR" },
  { value: "Outra", label: "Outra" },
];

const veicOptions = [
  { value: "Veículo Ligeiro", label: "Veículo Ligeiro" },
  { value: "Moto", label: "Moto" },
  { value: "Veículo Pesado", label: "Veículo Pesado" },
  {
    value: "Veículo Pesado de Mercadorias",
    label: "Veículo Pesado de Mercadorias",
  },
];

const prazoOptions = [
  { value: "Ignoro", label: "Ignoro" },
  { value: "5 dias", label: "5 dias" },
  { value: "10 dias", label: "10 dias" },
  { value: "15 dias", label: "15 dias" },
  { value: "20 dias", label: "20 dias" },
  { value: "25 dias", label: "25 dias" },
  { value: "30 dias", label: "30 dias" },
];

const pagouCoimaOptions = [
  { value: "Não", label: "Não" },
  {
    value: "Sim, dentro do período das 48h",
    label: "Sim, dentro do período das 48h",
  },
  {
    value: "Sim, 48h depois da infração",
    label: "Sim, 48h depois da infração",
  },
];

export { entOptions, veicOptions, prazoOptions, pagouCoimaOptions };
