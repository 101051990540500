import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Navigate, useLocation, useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import DatePicker from "react-date-picker";

function EditAtividade(props) {
  const navigate = useNavigate();
  const { tarefaId } = useParams();
  const dataHoje = new Date();

  const [tarefa, setTarefa] = useState(null);
  const [loading, setLoading] = useState(true);

  const [dataPrevA, setDataPrevA] = useState(null);
  const [dataConclu, setDataConclu] = useState(null);
  const [processo, setProcesso] = useState("");
  const [colaborador, setColaborador] = useState(null);
  const [observacoes, setObservacoes] = useState("");
  const [cliente, setCliente] = useState("");
  const [estado, setEstado] = useState(null);

  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    const fetchTarefa = async () => {
      const docRef = doc(db, "atividades", tarefaId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const atividadeData = docSnap.data();
        atividadeData.id = docSnap.id; // Add the document ID as id field
        const userDocRef = doc(db, "users", atividadeData.user);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          atividadeData.user = userData;
          setDataPrevA(
            atividadeData.dataPrevA != null
              ? new Date(atividadeData.dataPrevA.seconds * 1000)
              : atividadeData.dataPrevA
          );
          setDataConclu(
            atividadeData.dataConclu != null
              ? new Date(atividadeData.dataConclu.seconds * 1000)
              : atividadeData.dataConclu
          );
          setProcesso(atividadeData.processo);
          setColaborador({
            value: atividadeData.admin,
            label: atividadeData.admin,
          });
          setObservacoes(atividadeData.observacoes);
          setCliente(userData);
          setEstado({
            value: atividadeData.estado,
            label: atividadeData.estado,
          });
        } else {
          console.log("User document not found!");
        }
      } else {
        console.log("No such document!");
      }

      setLoading(false);
    };
    const fetchAdmins = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "administradores"));
        const docList = [];
        setAdmins([]);
        querySnapshot.docs.map((doc) =>
          docList.push({
            value: doc.id,
            label: doc.data().fname + " " + doc.data().lname,
          })
        );
        setAdmins(docList);
      } catch (error) {
        console.log(error);
      }
    };

    fetchTarefa();
    fetchAdmins();
  }, [tarefaId]);

  if (loading) {
    return <h1 style={{ marginLeft: 300 }}>Loading...</h1>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, "atividades", tarefaId), {
        observacoes,
        dataConclu,
        dataPrevA,
        admin: colaborador.value,
        estado: dataConclu == null ? "Por Realizar" : estado.value,
      });
      console.log("Document Added");
      alert("Alterações guardadas com sucesso");
    } catch (e) {
      console.log(e.message);
    }
  };

  const deleteCrm = async () => {
    try {
      await deleteDoc(doc(db, "atividades", tarefaId));
      navigate("/tarefas");
    } catch (e) {
      console.log(e.message);
    }
  };

  const options = [
    { value: "Por Realizar", label: "Por Realizar" },
    { value: "Realizada", label: "Realizada" },
    {
      value: "Cancelada a pedido do cliente",
      label: "Cancelada a pedido do cliente",
    },
    { value: "Cancelada", label: "Cancelada" },
  ];

  const filteredOptions =
    dataConclu != null ? options.slice(1, 4) : options.slice(0, 1);
  return (
    <div>
      <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
        Editar Tarefa
      </h1>
      <form onSubmit={handleSubmit}>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Processo
            </td>
            <p
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
                margin: 0,
              }}
            >
              {processo}
            </p>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Colaborador
            </td>
            <Select
              options={admins}
              value={colaborador}
              onChange={setColaborador}
              placeholder="Selecionar"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Observações
            </td>
            <input
              maxLength={65}
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setObservacoes(e.target.value)}
              value={observacoes}
              type="text"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de Conclusão
            </td>
            <DatePicker
              maxDate={dataHoje}
              onChange={setDataConclu}
              value={dataConclu}
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data Prevista
            </td>
            <DatePicker
              minDate={dataHoje}
              onChange={setDataPrevA}
              value={dataPrevA}
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Cliente
            </td>
            {cliente.fname} {cliente.lname}
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Estado
            </td>
            <Select
              options={filteredOptions}
              value={
                dataConclu == null
                  ? { value: "Por Realizar", label: "Por Realizar" }
                  : estado
              }
              onChange={setEstado}
              placeholder="Selecionar"
            />
          </tr>
        </table>

        <button
          style={{
            backgroundColor: "#2196f3",
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
            marginLeft: 300,
          }}
          type="text"
        >
          Guardar
        </button>
      </form>
      <button
        onClick={deleteCrm}
        style={{
          backgroundColor: "#f00",
          marginTop: -50,
          borderRadius: 5,
          border: 0,
          padding: 15,
          fontSize: 12,
          right: 0,
          position: "absolute",
          marginRight: 45,
          width: "100px",
          fontFamily: "Avenir Next",
          color: "#fff",
        }}
      >
        Apagar
      </button>
    </div>
  );
}

export default EditAtividade;
