import React from 'react'


const GestNotifica = () => {
  return (
    <div>
      <h1 style={{marginLeft: 300, fontFamily: "Avenir Next"}}>Gest Admin - Notificações</h1>
    </div>
  )
}

export default GestNotifica