import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  updateDoc,
  doc,
  getDoc,
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";

import { db } from "../firebase";
import Select from "react-select";
import DatePicker from "react-date-picker";

function AddReuniao(props) {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);

  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [data, setData] = useState(
    new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000)
  );
  const [hora, setHora] = useState(null);
  const [estado, setEstado] = useState(null);
  const [assunto, setAssunto] = useState("");

  const [reunioes, setReunioes] = useState([]);
  const [timeSlotsData, setTimeSlotsData] = useState(null);

  const [loading, setLoading] = useState(true);
  const [horas, setHoras] = useState([]);

  const convertTimestampToStringHours = (timestamp) => {
    if (timestamp == null) {
      return "Não Especificado";
    }
    const date = timestamp.toDate();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  const fetchUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const docList = [];
      setUsers([]);
      querySnapshot.docs.map((doc) => {
        docList.push({
          value: { ...doc.data(), id: doc.id },
          label: doc.data().email,
        });
      });
      setUsers(docList);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAdmins = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "administradores"));
      const docList = [];
      setAdmins([]);
      querySnapshot.docs.map((doc) =>
        docList.push({
          value: { ...doc.data(), id: doc.id },
          label: doc.data().fname + " " + doc.data().lname,
        })
      );
      setAdmins(docList);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTimeSlotsData = async () => {
    try {
      const docRef = doc(db, "global", "reunioes");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();

        setTimeSlotsData(data);
        console.log(data);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const fetchReunioes = async () => {
    try {
      const reunioesRef = collection(db, "reunioes");
      const querySnapshot = await getDocs(reunioesRef);

      const fetchedReunioes = querySnapshot.docs.map((doc) => {
        const timestamp = doc.data().meetingSlot; // Convert timestamp to Date object

        return {
          dia: timestamp.toDate().getDate(),
          mes: timestamp.toDate().getMonth() + 1, // Adding 1 because months are zero-indexed
          ano: timestamp.toDate().getFullYear(),
          slot: convertTimestampToStringHours(timestamp),
        };
      });

      setReunioes(fetchedReunioes);
    } catch (error) {
      console.log(error);
    }
  };

  const generateHoursOptions = () => {
    const dayOfWeek = data.getDay(); // Get the day of the week (0-6)
    const daysOfWeek = ["dom", "seg", "ter", "qua", "qui", "sex", "sab"];

    const options = [];

    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minute = 0; minute < 60; minute += intervalMinutes) {
        const time = `${hour.toString().padStart(2, "0")}:${minute
          .toString()
          .padStart(2, "0")}`;

        var slot_name =
          daysOfWeek[dayOfWeek].toLowerCase() + "-" + time.replace(":", "_");

        var startTimeExists = filteredSlots.some((reuniao) => {
          if (reuniao.slot === time) {
            return true;
          }
          return false;
        });

        if (timeSlotsData[slot_name] == true && startTimeExists == false) {
          options.push({ label: time, value: time });
        }
      }
    }

    return options;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchUsers();
        await fetchAdmins();
        await fetchTimeSlotsData();
        await fetchReunioes();

        setLoading(false);
      } catch (error) {
        // Handle error
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredSlots = reunioes.filter((reuniao) => {
    if (data) {
      return (
        reuniao.dia === data.getDate() &&
        reuniao.mes === data.getMonth() + 1 &&
        reuniao.ano === data.getFullYear()
      );
    }
  });

  const startHour = 9; // Starting hour (9:00)
  const endHour = 22; // Ending hour (22:00)
  const intervalMinutes = 20; // Interval between time slots (20 minutes)

  useEffect(() => {
    if (!loading) {
      const horas = generateHoursOptions();
      setHoras(horas);
    }
  }, [data, loading]);

  if (loading) {
    return <h1 style={{ marginLeft: 300 }}>Loading...</h1>;
  }

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const sendEmail = async () => {
    const url = new URL(
      "https://servermultazero-v1vk.onrender.com/sendMeetingCreationEmail"
    );
    const name = user.fname + " " + user.lname;
    const gestor = admin.fname + " " + admin.lname;
    const formattedDate = new Date(data);
    const date11 = formatDate(formattedDate);
    url.searchParams.append("name", name);
    url.searchParams.append("email", user.email);
    url.searchParams.append("date", date11);
    url.searchParams.append("hour", hora);
    url.searchParams.append("gestor", gestor);

    try {
      const response = await fetch(url.toString());
      const data = await response.json();

      return;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  /* const sendEmail = async () => {
    const functionURL =
      "https://us-central1-multa-zero-teste.cloudfunctions.net/sendMeetingCreationEmail"; // Replace with your Cloud Function URL

    const formattedDate = new Date(data);
    const date11 = formatDate(formattedDate);

    const data1 = {
      name: user.fname + " " + user.lname,
      email: user.email,
      date: date11,
      hour: hora,
      gestor: admin.fname + " " + admin.lname,
    };

    try {
      const response = await fetch(functionURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data1),
      });
      if (response.ok) {
        return;
      } else {
        // Handle errors, e.g., display an error message to the user
        const errorData = await response.json();
        console.error("Error changing email:", errorData.error);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  }; */

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formattedDate = new Date(data);
    const [hours, minutes] = hora.split(":");
    formattedDate.setHours(Number(hours), Number(minutes), 0, 0);
    try {
      const reunioesRef = collection(db, "reunioes");
      await addDoc(reunioesRef, {
        userId: user.id,
        admin: admin.id,
        meetingSlot: formattedDate,
        estado: estado,
        assunto,
        created_at: serverTimestamp(),
      });

      alert("Informações guardadas com sucesso.");
      navigate(`/reunioes`);
      await sendEmail();
    } catch (e) {
      console.log(e.message);
    }
  };

  const estadoOptions = [
    { value: "Por Realizar", label: "Por Realizar" },
    { value: "Realizado", label: "Realizado" },
    { value: "Adiado", label: "Adiado" },
    { value: "Cancelado pelo cliente", label: "Cancelado pelo cliente" },
    { value: "Cancelado", label: "Cancelado" },
  ];

  return (
    <div style={{ marginLeft: 300 }}>
      <h1 style={{ fontFamily: "Avenir Next" }}>Nova Reunião</h1>
      <form onSubmit={handleSubmit}>
        <p style={{ fontFamily: "Avenir Next" }}>Cliente</p>
        <Select
          options={users}
          onChange={(value) => setUser(value.value)}
          placeholder="Selecionar"
        />
        <p style={{ fontFamily: "Avenir Next" }}>Colaborador</p>
        <Select
          options={admins}
          onChange={(admin) => setAdmin(admin.value)}
          placeholder="Selecionar"
        />

        <p style={{ fontFamily: "Avenir Next" }}>Data</p>
        <DatePicker
          minDate={new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000)} // Add 4 days in milliseconds
          onChange={setData}
          value={data}
        />

        <p style={{ fontFamily: "Avenir Next" }}>Hora</p>
        <Select
          options={horas}
          value={horas.find((option) => option.value === hora)}
          onChange={(value) => setHora(value.value)}
          placeholder="Selecionar"
        />
        <p style={{ fontFamily: "Avenir Next" }}>Estado</p>
        <Select
          options={estadoOptions}
          value={estadoOptions.find((option) => option.value === estado)}
          onChange={(value) => setEstado(value.value)}
          placeholder="Selecionar"
        />
        <p style={{ fontFamily: "Avenir Next" }}>Assunto</p>
        <b style={{ fontSize: 12, marginLeft: 5, fontFamily: "Avenir Next" }}>
          ({assunto.length}/300)
        </b>
        <input
          maxLength={300}
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            backgroundColor: "transparent",
          }}
          onChange={(e) => setAssunto(e.target.value)}
          value={assunto}
          type="text"
        />
        <button
          style={{
            backgroundColor: "#2196f3",
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
          }}
          type="text"
        >
          Guardar
        </button>
      </form>
    </div>
  );
}

export default AddReuniao;
