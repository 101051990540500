import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  updateDoc,
  doc,
  getDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";

import { db } from "../firebase";
import Select from "react-select";
import DatePicker from "react-date-picker";

function EditReuniao(props) {
  const navigate = useNavigate();
  const { reuniaoId } = useParams();

  const [reuniao, setReuniao] = useState(null);

  const [loading, setLoading] = useState(true);

  const [estado, setEstado] = useState("");
  const [data, setData] = useState(null);
  const [dataa, setDataa] = useState(null);
  const [hora, setHora] = useState(null);
  const [timeSlotsData, setTimeSlotsData] = useState(null);
  const [assunto, setAssunto] = useState("");
  const [admin, setAdmin] = useState("");

  const [reunioes, setReunioes] = useState([]);

  const convertTimestampToStringHours = (timestamp) => {
    if (timestamp == null) {
      return "Não Especificado";
    }
    const date = timestamp.toDate();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  const fetchAffair = async () => {
    const docRef = doc(db, "reunioes", reuniaoId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const affairData = docSnap.data();
      const userId = affairData.userId;

      if (userId) {
        const userDocRef = doc(db, "users", userId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const gestorId = userData.gestorId;

          if (gestorId) {
            const gestorDocRef = doc(db, "administradores", affairData.admin);
            const gestorDocSnap = await getDoc(gestorDocRef);

            if (gestorDocSnap.exists()) {
              const gestorData = gestorDocSnap.data();
              const affairWithUserData = {
                id: docSnap.id,
                ...affairData,
                user: {
                  ...userData,
                  gestor: gestorData,
                },
              };
              setAdmin(gestorData);
              setAssunto(affairData.assunto);
              setReuniao(affairWithUserData);
              setEstado(affairData.estado);
              setData(
                affairData.meetingSlot != null
                  ? affairData.meetingSlot.toDate()
                  : null
              );
              setDataa(
                affairData.meetingSlot != null
                  ? affairData.meetingSlot.toDate()
                  : null
              );
              if (affairData !== null) {
                const date = affairData.meetingSlot.toDate();
                const hours = date.getHours().toString().padStart(2, "0");
                const minutes = date.getMinutes().toString().padStart(2, "0");
                const timeString = `${hours}:${minutes}`;
                setHora(timeString);
              } else {
                setHora(null);
              }
            } else {
              console.log("Gestor document does not exist!");
            }
          } else {
            console.log("gestorId field is not present in the user document!");
          }
        } else {
          console.log("User document does not exist!");
        }
      } else {
        console.log("User field is not present in the document!");
      }
    } else {
      console.log("No such document!");
    }
  };

  const fetchTimeSlotsData = async () => {
    try {
      const docRef = doc(db, "global", "reunioes");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setTimeSlotsData(data);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const fetchReunioes = async () => {
    try {
      const reunioesRef = collection(db, "reunioes");
      const querySnapshot = await getDocs(reunioesRef);

      const fetchedReunioes = querySnapshot.docs.map((doc) => {
        const timestamp = doc.data().meetingSlot; // Convert timestamp to Date object

        return {
          dia: timestamp.toDate().getDate(),
          mes: timestamp.toDate().getMonth() + 1, // Adding 1 because months are zero-indexed
          ano: timestamp.toDate().getFullYear(),
          slot: convertTimestampToStringHours(timestamp),
        };
      });

      setReunioes(fetchedReunioes);
    } catch (error) {
      console.log(error);
    }
  };

  const [admins, setAdmins] = useState([]);

  const fetchAdmins = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "administradores"));
      const docList = [];
      setAdmins([]);
      querySnapshot.docs.map((doc) =>
        docList.push({
          value: { ...doc.data(), id: doc.id },
          label: doc.data().fname + " " + doc.data().lname,
        })
      );
      setAdmins(docList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchAffair();
        await fetchTimeSlotsData();
        await fetchReunioes();
        await fetchAdmins();
        setLoading(false);
      } catch (error) {
        // Handle error
        setLoading(false);
      }
    };

    fetchData();
  }, [reuniaoId]);

  if (loading) {
    return <h1 style={{ marginLeft: 300 }}>Loading...</h1>;
  }

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const sendEmail = async () => {
    const url = new URL(
      "https://servermultazero-v1vk.onrender.com/sendMeetingCreationEmail"
    );
    const name = reuniao.user.fname + " " + reuniao.user.lname;
    const gestor = admin.fname + " " + admin.lname;
    const formattedDate = new Date(data);
    const date11 = formatDate(formattedDate);
    url.searchParams.append("name", name);
    url.searchParams.append("email", reuniao.user.email);
    url.searchParams.append("date", date11);
    url.searchParams.append("hour", hora);
    url.searchParams.append("gestor", gestor);

    try {
      const response = await fetch(url.toString());
      const data = await response.json();

      return;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(data);
    console.log(hora);

    const formattedDate = new Date(data);
    const [hours, minutes] = hora.split(":");
    formattedDate.setHours(Number(hours), Number(minutes), 0, 0);
    console.log("EE", admin);
    try {
      await updateDoc(doc(db, "reunioes", reuniaoId), {
        estado,
        meetingSlot: formattedDate,
        assunto,
        admin: admin.adminId,
      });

      alert("Informações guardadas com sucesso.");
      navigate(`/reunioes/${reuniaoId}`);
      sendEmail();
    } catch (e) {
      console.log(e.message);
    }
  };

  const estadoOptions = [
    { value: "Por Realizar", label: "Por Realizar" },
    { value: "Realizado", label: "Realizado" },
    { value: "Adiado", label: "Adiado" },
    { value: "Cancelado pelo cliente", label: "Cancelado pelo cliente" },
    { value: "Cancelado", label: "Cancelado" },
  ];

  const filteredSlots = reunioes.filter((reuniao) => {
    if (data) {
      return (
        reuniao.dia === data.getDate() &&
        reuniao.mes === data.getMonth() + 1 &&
        reuniao.ano === data.getFullYear()
      );
    }
  });

  const startHour = 9; // Starting hour (9:00)
  const endHour = 22; // Ending hour (22:00)
  const intervalMinutes = 20; // Interval between time slots (20 minutes)

  const generateHoursOptions = () => {
    const dayOfWeek = data.getDay(); // Get the day of the week (0-6)
    const daysOfWeek = ["dom", "seg", "ter", "qua", "qui", "sex", "sab"];

    const options = [];

    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minute = 0; minute < 60; minute += intervalMinutes) {
        const time = `${hour.toString().padStart(2, "0")}:${minute
          .toString()
          .padStart(2, "0")}`;
        var slot_name =
          daysOfWeek[dayOfWeek].toLowerCase() + "-" + time.replace(":", "_");

        var startTimeExists = filteredSlots.some((reuniao) => {
          if (reuniao.slot === time) {
            if (
              reuniao.dia == dataa.getDate() &&
              reuniao.mes == dataa.getMonth() + 1 &&
              reuniao.ano == dataa.getFullYear()
            ) {
              return false;
            } else {
              return true;
            }
          }
          return false;
        });

        if (timeSlotsData[slot_name] == true && startTimeExists == false) {
          options.push({ label: time, value: time });
        }
      }
    }

    return options;
  };

  // Usage
  const horas = generateHoursOptions();

  if (loading) {
    return (
      <div>
        <h1>Loading</h1>
      </div>
    );
  }

  return (
    <div>
      <div>
        <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
          Editar Reunião
        </h1>

        <form onSubmit={handleSubmit}>
          <table
            style={{
              backgroundColor: "#e8e8e9",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Cliente
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/clientes/${reuniao.userId}`}
                >
                  {reuniao.user.fname} {reuniao.user.lname}
                </Link>
              </td>
            </tr>
          </table>

          <table
            style={{
              backgroundColor: "#fff",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Data
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                <DatePicker
                  minDate={
                    new Date(new Date().setDate(new Date().getDate() + 4))
                  }
                  onChange={setData}
                  value={data}
                />
              </td>
            </tr>
          </table>

          <table
            style={{
              backgroundColor: "#e8e8e9",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Hora
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                <Select
                  options={horas}
                  value={horas.find((option) => option.value === hora)}
                  onChange={(value) => setHora(value.value)}
                  placeholder="Selecionar"
                />
              </td>
            </tr>
          </table>

          <table
            style={{
              backgroundColor: "#fff",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Gestor
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                <Select
                  options={admins}
                  value={admins.find(
                    (option) => option.label === admin.fname + " " + admin.lname
                  )}
                  onChange={(value) => setAdmin(value.value)}
                  placeholder="Selecionar"
                />
              </td>
            </tr>
          </table>
          <table
            style={{
              backgroundColor: "#e8e8e9",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Estado
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                <Select
                  options={estadoOptions}
                  value={estadoOptions.find(
                    (option) => option.value === estado
                  )}
                  onChange={(value) => setEstado(value.value)}
                  placeholder="Selecionar"
                />
              </td>
            </tr>
          </table>
          <table
            style={{
              backgroundColor: "#fff",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Assunto
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                <input
                  style={{
                    width: "95%",
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    fontFamily: "Avenir Next",
                    fontSize: 14,
                    borderBottomColor: "rgb(33, 150, 243)",
                    backgroundColor: "transparent",
                  }}
                  onChange={(e) => setAssunto(e.target.value)}
                  value={assunto}
                  type="text"
                />
              </td>
            </tr>
          </table>
          <button
            style={{
              backgroundColor: "#2196f3",
              marginTop: 15,
              borderRadius: 5,
              border: 0,
              padding: 15,
              fontSize: 12,
              right: 0,
              marginRight: 45,
              width: "100px",
              fontFamily: "Avenir Next",
              color: "#fff",
              right: 0,
              marginLeft: 300,
            }}
            type="text"
          >
            Guardar
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditReuniao;
