import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  getDocs,
  doc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";

const AddSubscritor = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [tipo, setTipo] = useState("");
  const [telemovel, setTelemovel] = useState("");
  const [pais, setPais] = useState("");
  const [freguesia, setFreguesia] = useState("");
  const [concelho, setConcelho] = useState("");
  const [morada, setMorada] = useState("");
  const [pontos, setPontos] = useState("");
  const [nif, setNif] = useState("");
  const [gestor, setGestor] = useState("");
  const [observacoes, setObservacoes] = useState("");
  const [desconto, setDesconto] = useState("");
  const [isProtocolo, setIsProtocolo] = useState(false);
  const [isMotorista, setIsMotorista] = useState(false);

  const [managers, setManagers] = useState([]);

  const navigate = useNavigate();

  const fetchAdmins = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "administradores"));
      const docList = [];
      setManagers([]);
      querySnapshot.docs.map((doc) =>
        docList.push({
          value: doc.id,
          label: doc.data().fname + " " + doc.data().lname,
        })
      );
      setManagers(docList);
    } catch (error) {
      console.log(error);
    }
  };

  const generateReferal = () => {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dbRef = collection(db, "users");
      addDoc(dbRef, {
        fname,
        lname,
        email,
        phone: telemovel,
        referral: "",
        isMotorista,
        isProtocolo,
        referal: generateReferal(),
        pais,
        freguesia,
        concelho,
        morada,
        created_at: serverTimestamp(),

        nif,
        gestorId: gestor.value,
        observacoes,
        desconto,
        pontos,
        nifMotorista: "",
        nifComProtocolo: "",
        files: [],
        inf_graves: "",
        inf_mtgraves: "",
        crimes: "",
        sancoes: "",
        bo_created: true,
        referral_uses: 0,
        desconto_given: "5",
        hasPayed: false,
        addedFiles: [],
        FrontCartadeConducao: "",
        BackCartadeConducao: "",
        FrontDocumentodeIdentificacao: "",
        BackDocumentodeIdentificacao: "",
        dateExpCartadeConducao: null,
        dateExpDocumentodeIdentificacao: null,
        descontoEmpresa: "0",
        referals: [],
        usedReferals: [],
      }).then(console.log("Document has been added successfully"));
      console.log("Document Added");
      navigate("/clientes");
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  return (
    <div style={{ marginLeft: 300 }}>
      <h1 style={{ fontFamily: "Avenir Next" }}>Criar Subscritor</h1>

      <form onSubmit={handleSubmit} style={{ marginRight: 25 }}>
        <h5 style={{ fontFamily: "Avenir Next" }}>Primeiro Nome</h5>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setFname(e.target.value)}
          type="text"
        />
        <h5 style={{ fontFamily: "Avenir Next" }}>Último Nome</h5>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setLname(e.target.value)}
          type="text"
        />
        <h5 style={{ fontFamily: "Avenir Next" }}>Email</h5>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
        />

        <h5 style={{ fontFamily: "Avenir Next" }}>Telemóvel</h5>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setTelemovel(e.target.value)}
          type="text"
        />
        <h5 style={{ fontFamily: "Avenir Next" }}>País</h5>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setPais(e.target.value)}
          type="text"
        />
        <h5 style={{ fontFamily: "Avenir Next" }}>Frequesia</h5>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setFreguesia(e.target.value)}
          type="text"
        />
        <h5 style={{ fontFamily: "Avenir Next" }}>Concelho</h5>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setConcelho(e.target.value)}
          type="text"
        />
        <h5 style={{ fontFamily: "Avenir Next" }}>Morada</h5>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setMorada(e.target.value)}
          type="text"
        />
        <h5 style={{ fontFamily: "Avenir Next" }}>Pontos</h5>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setPontos(e.target.value)}
          type="text"
        />
        <h5 style={{ fontFamily: "Avenir Next" }}>NIF</h5>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setNif(e.target.value)}
          type="text"
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <h5 style={{ fontFamily: "Avenir Next" }}>Protocolo?</h5>
          <input
            style={{
              width: "100%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "transparent",
              marginLeft: 10,
            }}
            type="checkbox"
            checked={isProtocolo}
            onChange={() => setIsProtocolo(!isProtocolo)}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h5 style={{ fontFamily: "Avenir Next" }}>Motorista?</h5>
          <input
            style={{
              width: "95%",
              borderWidth: 0,
              borderBottomWidth: 1,
              fontFamily: "Avenir Next",
              fontSize: 14,
              borderBottomColor: "rgb(33, 150, 243)",
              backgroundColor: "transparent",
              marginLeft: 10,
            }}
            type="checkbox"
            checked={isMotorista}
            onChange={() => setIsMotorista(!isMotorista)}
          />
        </div>
        <h5 style={{ fontFamily: "Avenir Next" }}>Gestor</h5>
        <Select
          options={managers}
          onChange={setGestor}
          placeholder="Selecionar"
        />
        <h5 style={{ fontFamily: "Avenir Next" }}>Observações</h5>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setObservacoes(e.target.value)}
          type="text"
        />
        <h5 style={{ fontFamily: "Avenir Next" }}>Desconto</h5>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setDesconto(e.target.value)}
          type="text"
        />

        <button
          style={{
            backgroundColor: "#2196f3",
            marginBottom: 15,
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
          }}
          type="text"
        >
          Submeter
        </button>
      </form>
    </div>
  );
};

export default AddSubscritor;
