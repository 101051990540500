import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Reunioes from "./pages/Reunioes";
import Processos from "./pages/Processos";
import Processo from "./pages/Processo";
import Alertas from "./pages/Alertas";
import NavBar from "./components/NavBar";
import Subscritores from "./pages/Subscritores";
import Subscritor from "./pages/Subscritor";
import Pagamentos from "./pages/Pagamentos";
import Pagamento from "./pages/Pagamento";
import Notificacoes from "./pages/Notificacoes";
import Referrals from "./pages/Referrals";
import Conta from "./pages/Conta";
import Ausencias from "./pages/Ausencias";
import GestAdmin from "./pages/GestAdmin";
import GestUtilizadores from "./pages/GestUtilizadores";
import GestProcessos from "./pages/GestProcessos";
import GestInfracoes from "./pages/GestInfracoes";
import GestNotifica from "./pages/GestNotifica";
import GestReuni from "./pages/GestReuni";
import GestEmpresas from "./pages/GestEmpresas";
import Atividades from "./pages/Atividades";
import Carteira from "./pages/Carteira";
import EditProcesso from "./pages/EditProcesso";
import EditSubscritor from "./pages/EditSubscritor";
import PainelBordo from "./pages/PainelBordo";
import EditReferral from "./pages/EditReferral";
import EditCrm from "./pages/EditCrm";
import Atividade from "./pages/Atividade";
import EditAtividade from "./pages/EditAtividade";
import AddSubscritor from "./pages/AddSubscritor";
import AddProcesso from "./pages/AddProcesso";

import Utilizador from "./pages/Utilizador";
import Infracao from "./pages/Infracao";
import Empresa from "./pages/Empresa";
import Notificacao from "./pages/Notificacao";
import Reuniao from "./pages/Reuniao";
import EditReuniao from "./pages/EditReuniao";
import AddReuniao from "./pages/AddReuniao";

import Crm from "./pages/Crm";

import Signup from "./pages/Signup";
import Signin from "./pages/Signin";
import ProtectedRoute from "./pages/ProtectedRoute";
import ProtectedRoute2 from "./pages/ProtectedRoute2";
import { AuthContextProvider } from "./context/AuthContext";

function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <NavBar />
        <Header />
        <Routes>
          <Route
            path="/reunioes"
            element={
              <ProtectedRoute>
                <Reunioes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reunioes/:reuniaoId"
            element={
              <ProtectedRoute>
                <Reuniao />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reunioes/:reuniaoId/editar"
            element={
              <ProtectedRoute>
                <EditReuniao />
              </ProtectedRoute>
            }
          />
          <Route
            path="/nova-reuniao"
            element={
              <ProtectedRoute>
                <AddReuniao />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tarefas"
            element={
              <ProtectedRoute>
                <Atividades />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tarefas/:tarefaId"
            element={
              <ProtectedRoute>
                <Atividade />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tarefas/:tarefaId/edit"
            element={
              <ProtectedRoute>
                <EditAtividade />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <PainelBordo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/crm"
            element={
              <ProtectedRoute>
                <Crm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/crm/:crmId/edit"
            element={
              <ProtectedRoute>
                <EditCrm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assuntos"
            element={
              <ProtectedRoute>
                <Processos />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assuntos/:affairId"
            element={
              <ProtectedRoute>
                <Processo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assuntos/:affairId/editar"
            element={
              <ProtectedRoute>
                <EditProcesso />
              </ProtectedRoute>
            }
          />
          <Route
            path="/novo-assunto"
            element={
              <ProtectedRoute>
                <AddProcesso />
              </ProtectedRoute>
            }
          />
          <Route
            path="/alertas"
            element={
              <ProtectedRoute>
                <Alertas />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clientes"
            element={
              <ProtectedRoute>
                <Subscritores />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clientes/:clientId"
            element={
              <ProtectedRoute>
                <Subscritor />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clientes/:clientId/edit"
            element={
              <ProtectedRoute>
                <EditSubscritor />
              </ProtectedRoute>
            }
          />
          <Route
            path="/novo-cliente"
            element={
              <ProtectedRoute>
                <AddSubscritor />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clientes/:clientId/carteira/:carteiraId"
            element={
              <ProtectedRoute>
                <Carteira />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pagamentos"
            element={
              <ProtectedRoute>
                <Pagamentos />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pagamentos/:affairId"
            element={
              <ProtectedRoute>
                <Pagamento />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notificacoes"
            element={
              <ProtectedRoute>
                <Notificacoes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notificacao/:notiId"
            element={
              <ProtectedRoute>
                <Notificacao />
              </ProtectedRoute>
            }
          />
          <Route
            path="/referrals"
            element={
              <ProtectedRoute>
                <Referrals />
              </ProtectedRoute>
            }
          />
          <Route
            path="/conta"
            element={
              <ProtectedRoute>
                <Conta />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ausencias"
            element={
              <ProtectedRoute>
                <Ausencias />
              </ProtectedRoute>
            }
          />
          <Route
            path="/gestaoadministrativa"
            element={
              <ProtectedRoute>
                <GestAdmin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/gestaoutilizadores"
            element={
              <ProtectedRoute>
                <GestUtilizadores />
              </ProtectedRoute>
            }
          />
          <Route
            path="/utilizador/:adminId"
            element={
              <ProtectedRoute>
                <Utilizador />
              </ProtectedRoute>
            }
          />
          <Route
            path="/gestaoprocessos"
            element={
              <ProtectedRoute>
                <GestProcessos />
              </ProtectedRoute>
            }
          />
          <Route
            path="/gestaoinfracoes"
            element={
              <ProtectedRoute>
                <GestInfracoes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/infracao/:index"
            element={
              <ProtectedRoute>
                <Infracao />
              </ProtectedRoute>
            }
          />
          <Route
            path="/gestaonotificacoes"
            element={
              <ProtectedRoute>
                <GestNotifica />
              </ProtectedRoute>
            }
          />
          <Route
            path="/gestaoreunioes"
            element={
              <ProtectedRoute>
                <GestReuni />
              </ProtectedRoute>
            }
          />

          <Route
            path="/gestaoempresas"
            element={
              <ProtectedRoute>
                <GestEmpresas />
              </ProtectedRoute>
            }
          />
          <Route
            path="/empresa/:index"
            element={
              <ProtectedRoute>
                <Empresa />
              </ProtectedRoute>
            }
          />
          <Route
            path="/referrals/:clientId/edit"
            element={
              <ProtectedRoute>
                <EditReferral />
              </ProtectedRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <ProtectedRoute2>
                <Signup />
              </ProtectedRoute2>
            }
          />
          <Route
            path="/signin"
            element={
              <ProtectedRoute2>
                <Signin />
              </ProtectedRoute2>
            }
          />
        </Routes>
      </AuthContextProvider>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
