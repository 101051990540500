import React, { useState, useEffect } from "react";
import { collection, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const ProcessoCard3 = (props) => {
  const options = [
    {
      value: "Elaboração defesa",
      label: "Elaboração defesa",
    },
    {
      value: "Enviada defesa",
      label: "Enviada defesa",
    },
    {
      value: "Aguarda Decisão",
      label: "Aguarda Decisão",
    },
    { value: "Por pagar", label: "Aguarda Pagamento" },
    { value: "Pago", label: "Pagamento Efetuado" },
    { value: "Pedido de Reembolso", label: "Pedido de Reembolso" },
    { value: "Concluído", label: "Concluído" },
  ];

  const estadoLabel = options.find(
    (option) => option.value === props.value.estado
  )?.label;
  return (
    <div>
      <div>
        <table
          border={1}
          style={{
            backgroundColor: "transparent",
            borderColor: "#e8e8e9",
            borderWidth: 0.6,
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 10,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr>
            <td
              style={{
                fontFamily: "Avenir Next",
                color: "#000",
                width: 100,
                border: 0,
              }}
            >
              {props.value.id}
            </td>
            <td
              style={{
                fontFamily: "Avenir Next",
                width: 150,
                border: 0,
                color: "#000",
              }}
            >
              {props.value.dataCriacao.toDate().toLocaleDateString("en-GB")}
            </td>
            <td
              style={{
                fontFamily: "Avenir Next",
                width: 200,
                border: 0,
                color: "#000",
              }}
            >
              {props.value.isMorto == true
                ? "Morto"
                : estadoLabel || props.value.estado}
            </td>
            <td
              style={{
                fontFamily: "Avenir Next",
                width: 250,
                border: 0,
                color: "#000",
              }}
            >
              {props.value.user.fname} {props.value.user.lname}
            </td>{" "}
            <td
              style={{
                fontFamily: "Avenir Next",
                width: 100,
                border: 0,
                color: "#000",
              }}
            >
              {props.value.user.nif != "" ? props.value.user.nif : "a definir"}
            </td>
            <td
              style={{
                fontFamily: "Avenir Next",
                width: 250,
                border: 0,
                color: "#000",
              }}
            >
              {props.value.user.gestor.fname} {props.value.user.gestor.lname}
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default ProcessoCard3;
