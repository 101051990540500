const AtividadeCard = (props) => {
  return (
    <div>
      <table
        border={1}
        style={{
          backgroundColor: "#fff",
          borderWidth: 0.6,
          borderColor: "#e8e8e9",
          paddingBottom: 10,
          paddingTop: 10,
          paddingLeft: 25,
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <tr>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 150,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.processo}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.gestor.fname} {props.value.gestor.lname}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.user.fname} {props.value.user.lname}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.estado}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.observacoes}
          </td>
          {props.value.dataConclu != null ? (
            <td
              style={{
                borderWidth: 0,
                borderColor: "#000",
                width: 200,
                fontFamily: "Avenir Next",
                fontWeight: "500",
                color: "#000",
              }}
            >
              {String(
                new Date(props.value.dataConclu.seconds * 1000).getDate()
              ) +
                "/" +
                String(
                  new Date(props.value.dataConclu.seconds * 1000).getMonth() + 1
                ) +
                "/" +
                String(
                  new Date(props.value.dataConclu.seconds * 1000).getFullYear()
                )}
            </td>
          ) : (
            <td
              style={{
                borderWidth: 0,
                borderColor: "#000",
                width: 200,
                fontFamily: "Avenir Next",
                fontWeight: "500",
                color: "#000",
              }}
            >
              Não definida
            </td>
          )}
          {props.value.dataPrevA != null ? (
            <td
              style={{
                borderWidth: 0,
                borderColor: "#000",
                width: 200,
                fontFamily: "Avenir Next",
                fontWeight: "500",
                color: "#000",
              }}
            >
              {String(
                new Date(props.value.dataPrevA.seconds * 1000).getDate()
              ) +
                "/" +
                String(
                  new Date(props.value.dataPrevA.seconds * 1000).getMonth() + 1
                ) +
                "/" +
                String(
                  new Date(props.value.dataPrevA.seconds * 1000).getFullYear()
                )}
            </td>
          ) : (
            <td
              style={{
                borderWidth: 0,
                borderColor: "#000",
                width: 200,
                fontFamily: "Avenir Next",
                fontWeight: "500",
                color: "#000",
              }}
            >
              Não definida
            </td>
          )}
        </tr>
      </table>
    </div>
  );
};

export default AtividadeCard;
