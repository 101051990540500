import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import AtividadeCard from "../components/cards/AtividadeCard";
import UtilizadorCard from "../components/cards/UtilizadorCard";

function Infracao(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { item } = location.state;

  const [infracao, setInfracao] = useState(item.item.infracao);
  const [pontos, setPontos] = useState(item.item.pontos);
  const [moldura, setMoldura] = useState(item.item.moldura);
  const [moldura2, setMoldura2] = useState(item.item.moldura2);

  const [infracoes, setInfracoes] = useState(item.infracoes);
  useEffect(() => {
    console.log(infracoes);
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    var infra = infracoes;
    infra[item.index].infracao = infracao;
    infra[item.index].pontos = pontos;
    infra[item.index].moldura = moldura;
    infra[item.index].moldura2 = moldura2;
    console.log(infra);
    setInfracoes(infra);

    try {
      await updateDoc(doc(db, "global", "infracoes"), {
        infracoes,
      });
      console.log("Document Added");
      alert("Infração editada com sucesso.");
    } catch (e) {
      console.log(e.message);
    }
  };

  const deleteInfra = async () => {
    try {
      const confirmed = window.confirm(
        "Tens a certeza que queres apagar esta infração?"
      );

      if (confirmed) {
        infracoes.splice(item.index, 1);
        await updateDoc(doc(db, "global", "infracoes"), {
          infracoes,
        });
        navigate("/gestaoinfracoes");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
        Editar Infração
      </h1>

      <form onSubmit={handleSubmit}>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Infração
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setInfracao(e.target.value)}
              value={infracao}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Pontos
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setPontos(e.target.value)}
              value={pontos}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              De
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setMoldura(e.target.value)}
              value={moldura}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              A
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setMoldura2(e.target.value)}
              value={moldura2}
              type="text"
            />
          </tr>
        </table>

        <button
          style={{
            backgroundColor: "#2196f3",
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
            marginLeft: 300,
          }}
          type="text"
        >
          Guardar
        </button>
        <button
          onClick={deleteInfra}
          style={{
            backgroundColor: "#f00",
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            position: "absolute",
          }}
        >
          Apagar
        </button>
      </form>
    </div>
  );
}

export default Infracao;
