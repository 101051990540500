const InfracoesCard = (props) => {
  console.log(props)

  return (
    <div>
    <table border={1} style={{ backgroundColor: "#fff", borderWidth: 0.6, borderColor: "#e8e8e9", paddingBottom: 10, paddingTop: 10, paddingLeft: 10, justifyContent: "space-evenly",  width: "100%", paddingRight: 20}}>
        <tr style={{borderWidth: 0}}>
          <td style={{borderWidth: 0, color: "#000", borderLeftColor: "#000", width: 500, fontFamily: "Avenir Next", fontWeight: "500"}}>{props.value.infracao}</td>
          <td style={{borderWidth: 0, color: "#000", borderColor: "#000", width: 100, fontFamily: "Avenir Next", fontWeight: "500"}}>{props.value.pontos}</td>
          <td style={{borderWidth: 0, color: "#000", borderColor: "#000", width: 100, fontFamily: "Avenir Next", fontWeight: "500"}}>{props.value.moldura}€</td>
          <td style={{borderWidth: 0, color: "#000", borderColor: "#000", width: 100, fontFamily: "Avenir Next", fontWeight: "500"}}>{props.value.moldura2}€</td>
          
        </tr>
      </table>

    </div>

  )
}

export default InfracoesCard