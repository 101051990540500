import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  addDoc,
  serverTimestamp,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
import Select from "react-select";

function Pagamento(props) {
  const { affairId } = useParams();

  const [affair, setAffair] = useState(null);
  const [loading, setLoading] = useState(true);
  const [estado, setEstado] = useState("");

  useEffect(() => {
    const fetchAffair = async () => {
      const docRef = doc(db, "processos", affairId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const affairData = docSnap.data();
        const userId = affairData.userId;

        if (userId) {
          const userDocRef = doc(db, "users", userId);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            const affairWithUserData = {
              id: docSnap.id,
              ...affairData,
              user: userData,
            };
            setAffair(affairWithUserData);
            setEstado(affairWithUserData.estado);
          } else {
            console.log("User document does not exist!");
          }
        } else {
          console.log("User field is not present in the document!");
        }
      } else {
        console.log("No such document!");
      }

      setLoading(false); // Set loading to false after fetching the data
    };
    fetchAffair();
  }, [affairId]);

  if (loading) {
    return <h1 style={{ marginLeft: 300 }}>Loading...</h1>;
  }

  const options = [
    {
      value: "Elaboração defesa",
      label: "Elaboração defesa",
    },
    {
      value: "Enviada defesa",
      label: "Enviada defesa",
    },
    {
      value: "Aguarda Decisão",
      label: "Aguarda Decisão",
    },
    { value: "Por pagar", label: "Aguarda Pagamento" },
    { value: "Pago", label: "Pagamento Efetuado" },
    { value: "Pedido de Reembolso", label: "Pedido de Reembolso" },
    { value: "Concluído", label: "Concluído" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateDoc(doc(db, "processos", affairId), {
        estado,
      });

      alert("Informações guardadas com sucesso.");
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      <div>
        <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
          Pagamento
        </h1>
        <form onSubmit={handleSubmit}>
          <table
            style={{
              backgroundColor: "#fff",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                ID do Processo
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                {affair.id}
              </td>
            </tr>
          </table>

          <table
            style={{
              backgroundColor: "#e8e8e9",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Infração
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                {affair.infracao.infracao}
              </td>
            </tr>
          </table>

          <table
            style={{
              backgroundColor: "#fff",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Referência
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                {affair.pagamento.referencia}
              </td>
            </tr>
          </table>

          <table
            style={{
              backgroundColor: "#e8e8e9",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Plano
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                {affair.plano.name}
              </td>
            </tr>
          </table>

          <table
            style={{
              backgroundColor: "#fff",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Subscritor
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                <Link
                  style={{ textDecoration: "none", color: "rgb(33, 150, 243)" }}
                  to={`/clientes/${affair.userId}`}
                >
                  {affair.user.fname} {affair.user.lname}
                </Link>
              </td>
            </tr>
          </table>

          <table
            style={{
              backgroundColor: "#e8e8e9",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                NIF
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                {affair.user.nif != "" ? affair.user.nif : "a definir"}
              </td>
            </tr>
          </table>

          <table
            style={{
              backgroundColor: "#fff",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Preço
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                {affair.pagamento.preco}
              </td>
            </tr>
          </table>
          <table
            style={{
              backgroundColor: "#e8e8e9",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Estado
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                <Select
                  options={options}
                  value={options.find((option) => option.value === estado)}
                  onChange={(value) => setEstado(value.value)}
                  placeholder="Selecionar"
                />
              </td>
            </tr>
          </table>
          <table
            style={{
              backgroundColor: "#fff",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Data de pagamento
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                {affair.dataPagamento == "N/D"
                  ? affair.dataPagamento
                  : affair.dataPagamento.toDate().toLocaleDateString("en-GB")}
              </td>
            </tr>
          </table>
          <button
            style={{
              backgroundColor: "#2196f3",
              marginTop: 15,
              borderRadius: 5,
              border: 0,
              padding: 15,
              fontSize: 12,
              right: 0,
              marginRight: 45,
              width: "100px",
              fontFamily: "Avenir Next",
              color: "#fff",
              right: 0,
              marginLeft: 300,
            }}
            type="text"
          >
            Guardar
          </button>
        </form>
      </div>
    </div>
  );
}

export default Pagamento;
