const ReuniaoCard = (props) => {
  const convertTimestampToStringHours = (meetingSlot) => {
    const date = meetingSlot.toDate();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };
  return (
    <div>
      <table
        border={1}
        style={{
          backgroundColor: "#fff",
          borderColor: "#e8e8e9",
          borderWidth: 0.6,
          paddingBottom: 10,
          marginTop: 5,
          paddingTop: 10,
          paddingLeft: 300,
          justifyContent: "space-evenly",
          width: "100%",
          paddingRight: 20,
        }}
      >
        <tr style={{ borderRightWidth: 1 }}>
          <td
            style={{
              borderWidth: 0,
              width: 300,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.user.fname} {props.value.user.lname}
          </td>
          <td
            style={{
              borderWidth: 0,
              width: 150,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.user.phone}
          </td>
          <td
            style={{
              borderWidth: 0,
              width: 150,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.meetingSlot.toDate().toLocaleDateString("en-GB")}
          </td>
          <td
            style={{
              borderWidth: 0,
              width: 150,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {convertTimestampToStringHours(props.value.meetingSlot)}
          </td>
          <td
            style={{
              borderWidth: 0,
              width: 300,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.user.gestor.fname} {props.value.user.gestor.lname}
          </td>
          <td
            style={{
              borderWidth: 0,
              width: 300,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.estado}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default ReuniaoCard;
