import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  where,
  query,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
import UserCard from "../components/cards/UserCard";
import AtividadeCard2 from "../components/cards/AtividadeCard2";
import Select from "react-select";
import DatePicker from "react-date-picker";

import ExcelJS from "exceljs";
const Subscritores = (props) => {
  const [users, setUsers] = useState([]);
  const [queryy, setQueryy] = useState("");
  const [query2, setQueryy2] = useState(false);

  const [affairs, setAffairs] = useState();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [refi, setRefi] = useState("");

  useEffect(() => {
    fetchUsers();
    getAllProcessos();
  }, []);

  const getAllProcessos = async () => {
    const processosCollection = collection(db, "processos");

    try {
      const querySnapshot = await getDocs(processosCollection);

      const docList = [{ value: "", label: "Selecionar" }];
      setAffairs([]);

      querySnapshot.forEach((doc) => {
        docList.push({
          value: doc.data(),
          label: doc.data().pagamento.referencia,
        });
      });

      setAffairs(docList);
    } catch (error) {
      console.error("Error fetching processos:", error);
      return [];
    }
  };

  const getUserById = async (userId) => {
    const userDocRef = doc(db, "users", userId);

    try {
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        console.log(userData);

        if (userData.gestorId) {
          const gestorDocRef = doc(db, "administradores", userData.gestorId);
          const gestorDocSnapshot = await getDoc(gestorDocRef);

          if (gestorDocSnapshot.exists()) {
            const gestorData = gestorDocSnapshot.data();
            // Assuming 'user' is your user state variable, update it with the gestor data
            setUsers([{ ...userData, gestor: gestorData }]);
          } else {
            console.error("Gestor not found with ID: " + userData.gestorId);
          }
        } else {
          console.error("Gestor ID not found in user data.");
        }
      } else {
        console.error("User not found with ID: " + userId);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  const getUserByRef = (doc) => {
    console.log(doc.userId);
    if (doc == "") {
      setRefi("");
      fetchUsers();
    } else {
      getUserById(doc.userId);
      setRefi(doc.pagamento.referencia);
    }
  };

  const exportToExcel = async () => {
    try {
      const usersRef = collection(db, "users");
      const affairsRef = collection(db, "processos");
      const crmRef = collection(db, "crm");

      const usersData = [];
      const usersSnapshot = await getDocs(usersRef);

      for (const userDoc of usersSnapshot.docs) {
        const userData = userDoc.data();
        const userId = userDoc.id;

        const q = query(affairsRef, where("userId", "==", userId));
        const userAffairsSnapshot = await getDocs(q);

        if (userAffairsSnapshot.empty) {
          // If the user has no affairs, add a separate entry
          const userName = userData.fname + " " + userData.lname;
          const userEmail = userData.email;
          const userPhone = userData.phone;
          const userNif = userData.nif;

          const userAffairInfo = {
            name: userName,
            email: userEmail,
            phone: userPhone,
            nif: userNif,
            affairName: "Ainda sem pacote adquirido",
            paymentDate: "N/D", // You can set this to an empty
          };

          usersData.push(userAffairInfo);
        } else {
          // If the user has affairs, process them as before
          for (const affairDoc of userAffairsSnapshot.docs) {
            const affairData = affairDoc.data();
            const userName = userData.fname + " " + userData.lname;
            const userEmail = userData.email;
            const userPhone = userData.phone;
            const userNif = userData.nif;
            const affairName = affairDoc.id + " - " + affairData.plano.name;
            const paymentDate =
              affairData.dataPagamento !== "N/D"
                ? affairData.dataPagamento.toDate().toLocaleDateString("en-GB")
                : affairData.dataPagamento;

            const userAffairInfo = {
              name: userName,
              email: userEmail,
              phone: userPhone,
              nif: userNif,
              affairName,
              paymentDate,
            };

            usersData.push(userAffairInfo);
          }
        }
      }
      const crmSnapshot = await getDocs(crmRef);
      for (const crmDoc of crmSnapshot.docs) {
        const crmUserData = crmDoc.data();
        const crmUserName = crmUserData.name;
        const crmUserEmail = crmUserData.email;
        const crmUserPhone = crmUserData.phone;
        const crmUserNif = crmUserData.nif;

        const crmUserAffairInfo = {
          name: crmUserName,
          email: crmUserEmail,
          phone: crmUserPhone,
          nif: crmUserNif, // You can set this to an empty string or provide a default value
          affairName: "Ainda sem pacote adquirido",
          paymentDate: "N/D", // You can set this to an empty string or provide a default value
        };

        usersData.push(crmUserAffairInfo);
      }

      // Prepare the data for Excel
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("MZ Clientes");
      worksheet.columns = [
        { header: "Nome", key: "name", width: 20 }, // Set width for Name column
        { header: "Email", key: "email", width: 30 }, // Set width for Email column
        { header: "Telemóvel", key: "phone", width: 15 }, // Set width for Phone column
        { header: "NIF", key: "nif", width: 15 }, // Set width for NIF column
        { header: "Pacote", key: "affairName", width: 25 }, // Set width for Affair column
        { header: "Data de Pagamento", key: "paymentDate", width: 15 }, // Set width for Payment Date column
        // Add more columns as needed
      ];

      usersData.forEach((affairData) => {
        worksheet.addRow(affairData);
      });

      // Create a Blob and trigger a download
      const blob = await workbook.xlsx.writeBuffer();
      const blobData = new Blob([blob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blobData);

      const a = document.createElement("a");
      a.href = url;
      a.download = "mz_clientes.xlsx";
      a.click();

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const fetchUsers = async () => {
    const usersCollection = collection(db, "users");
    // Add the "orderBy" clause to sort by the "created_at" field in descending order (most recent first).
    const queryy = query(usersCollection, orderBy("created_at", "desc"));
    const usersSnapshot = await getDocs(queryy);

    const fetchedUsers = [];

    for (const userDoc of usersSnapshot.docs) {
      const userData = userDoc.data();

      // Get the userId and gestorId from user data
      const { gestorId, ...otherUserFields } = userData;

      // Get the gestor data from firestore/administradores/gestorId
      const gestorDocRef = doc(db, "administradores", gestorId);
      const gestorDoc = await getDoc(gestorDocRef);
      const gestorData = gestorDoc.data();

      // Add the userId and gestor data as fields in user data
      const userWithGestor = {
        userId: userDoc.id,
        ...otherUserFields,
        gestor: gestorData,
      };

      fetchedUsers.push(userWithGestor);
    }

    setUsers(fetchedUsers);
  };

  return (
    <div>
      <div>
        <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>Clientes</h1>
        <button
          style={{
            marginLeft: 300,
            marginTop: -15,
            marginBottom: 10,
            padding: 10,
            borderRadius: 5,
            border: 0,
            width: 180,
            fontSize: 14,
            color: "#fff",
            fontWeight: "500",
            cursor: "pointer",
            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
            backgroundColor: "rgb(33, 150, 243",
            fontFamily: "Avenir Next",
          }}
          onClick={exportToExcel}
        >
          Exportar para Excel
        </button>
        <Link style={{ textDecoration: "none" }} to={`/novo-cliente`}>
          <button
            style={{
              marginLeft: 10,
              marginTop: -15,
              marginBottom: 10,
              padding: 10,
              borderRadius: 5,
              border: 0,
              width: 120,
              fontSize: 14,
              color: "#fff",
              fontWeight: "500",
              cursor: "pointer",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
              backgroundColor: "rgb(33, 150, 243",
              fontFamily: "Avenir Next",
            }}
          >
            Criar Cliente
          </button>
        </Link>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 25,
          }}
        >
          <div style={{ flex: "33%", marginRight: 10 }}>
            <input
              placeholder="Pesquisar por nome, email ou tlm"
              type="text"
              value={queryy}
              onChange={(e) => {
                setQueryy(e.target.value);
              }}
              style={{
                width: 530,
                marginLeft: 300,
                marginTop: 35,
                paddingBottom: 5,
                backgroundColor: "transparent",
                color: "#000",
                fontFamily: "Avenir Next",
                fontSize: "14px",
                border: 0,
                borderBottom: "1px solid rgb(33, 150, 243)",
              }}
            />
          </div>
          <div style={{ flex: "33%", marginRight: 10 }}>
            <p>Por referência:</p>
            <Select
              options={affairs}
              value={
                refi === ""
                  ? { value: "", label: "Selecionar" }
                  : affairs.find((admin) => admin.value === refi)
              }
              onChange={(value) => getUserByRef(value.value)}
              placeholder="Selecionar"
            />
          </div>
          <div style={{ flex: "33%" }}>
            <p>Selecionar Intervalo de Datas:</p>
            <div>
              <DatePicker
                onChange={(date) => setStartDate(date)}
                value={startDate}
                clearIcon={null}
              />
              <span> - </span>
              <DatePicker
                onChange={(date) => setEndDate(date)}
                value={endDate}
                clearIcon={null}
              />
            </div>
          </div>
        </div>
        <div style={{ marginLeft: 300 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3
              style={{
                fontFamily: "Avenir Next",
                fontWeight: 500,
                fontSize: 14,
                backgroundColor: "#333",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 3,
                paddingBottom: 3,
                borderRadius: 5,
                color: "#fff",
              }}
            >
              Já adquiriu algum pacote?
            </h3>
            <input
              style={{
                borderWidth: 0,
                borderRadius: 10,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
                marginLeft: 10,
              }}
              type="checkbox"
              checked={query2}
              onChange={() => setQueryy2(!query2)}
            />
          </div>
        </div>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            marginTop: 15,
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: 300,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Nome
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: 300,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              E-mail
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: 100,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Telemóvel
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: 100,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              NIF
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: 200,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Responsável
            </td>
          </tr>
        </table>
        {users
          .filter((user) => {
            if (queryy === "") {
              return user;
            } else if (
              user.fname.toLowerCase().includes(queryy.toLowerCase())
            ) {
              return user;
            } else if (
              user.email.toLowerCase().includes(queryy.toLowerCase())
            ) {
              return user;
            } else if (
              user.phone.toLowerCase().includes(queryy.toLowerCase())
            ) {
              return user;
            } else if (user.nif.toLowerCase().includes(queryy.toLowerCase())) {
              return user;
            }
          })
          .filter((user) => user.hasPayed === query2)

          .filter((item) => {
            if (startDate && !endDate) {
              const itemDate = item.created_at.toDate(); // Convert Firestore timestamp to Date
              return (
                itemDate.getFullYear() >= startDate.getFullYear() &&
                itemDate.getMonth() >= startDate.getMonth() &&
                itemDate.getDate() >= startDate.getDate()
              );
            } else if (!startDate && endDate) {
              const itemDate = item.created_at.toDate(); // Convert Firestore timestamp to Date
              return (
                itemDate.getFullYear() <= endDate.getFullYear() &&
                itemDate.getMonth() <= endDate.getMonth() &&
                itemDate.getDate() <= endDate.getDate()
              );
            } else if (startDate && endDate) {
              const itemDate = item.created_at.toDate(); // Convert Firestore timestamp to Date
              const startYear = startDate.getFullYear();
              const startMonth = startDate.getMonth();
              const startDay = startDate.getDate();
              const endYear = endDate.getFullYear();
              const endMonth = endDate.getMonth();
              const endDay = endDate.getDate();

              return (
                itemDate.getFullYear() >= startYear &&
                itemDate.getMonth() >= startMonth &&
                itemDate.getDate() >= startDay &&
                itemDate.getFullYear() <= endYear &&
                itemDate.getMonth() <= endMonth &&
                itemDate.getDate() <= endDay
              );
            } else {
              return true; // If both startDate and endDate are null, don't apply date filter.
            }
          })

          .map((user, index) => (
            <div className="box" key={index}>
              <Link
                style={{ textDecoration: "none" }}
                to={`/clientes/${user.userId}`}
                state={{ user }}
              >
                <UserCard key={index} value={user} />
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Subscritores;
