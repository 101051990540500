const MatriculaCard = (props) => {
  console.log(props);
  return (
    <div>
      <table
        border={1}
        style={{
          backgroundColor: "#fff",
          borderWidth: 0.6,
          borderColor: "#e8e8e9",
          paddingBottom: 10,
          paddingTop: 10,
          paddingLeft: 300,
          justifyContent: "space-evenly",
          width: "100%",
          paddingRight: 20,
          color: "#000",
        }}
      >
        <tr style={{ borderWidth: 0 }}>
          <td
            style={{
              borderWidth: 0,
              borderLeftColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            {props.value.item.matricula}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 150,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            {props.value.item.marca}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            {props.value.item.modelo}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            {props.value.item.dateComp != null
              ? props.value.item.dateComp.toDate().toLocaleDateString("en-GB")
              : "Não definido"}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            {props.value.item.dateVend != null
              ? props.value.item.dateVend.toDate().toLocaleDateString("en-GB")
              : "Não definido"}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            {props.value.item.addedFiles.length +
              props.value.client.addedFiles.length}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default MatriculaCard;
