import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../imgs/logo.png";
import "@fontsource/material-icons";
import { db, auth } from "../firebase";
import { doc, setDoc, getDoc, onSnapshot } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";

const NavBar = () => {
  const { user, loading } = UserAuth();

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [nivel, setNivel] = useState("");

  var [pBottom, setPBottom] = useState(0);

  var [painel, setPainel] = useState(true);
  var [backcorPainel, setBackcorPainel] = useState(false);
  var [corPainel, setCorPainel] = useState(false);
  var [shadowPainel, setShadowPainel] = useState(false);

  if (painel === false) {
    setPainel("none");
    setBackcorPainel("transparent");
    setCorPainel("#8c8c8c");
    setShadowPainel("0px 0px 0px rgba(0, 0, 0, 0.0)");

    setPBottom(0);
  } else if (painel === true) {
    setPainel("block");
    setBackcorPainel("#2196f3");
    setCorPainel("#fff");
    setShadowPainel("2px 2px 10px rgba(0, 0, 0, 0.2)");

    setPBottom(150);
  }

  function openPainel() {
    setAtividades(false);
    setCRM(false);
    setProcessos(false);
    setReunioes(false);
    setAlerta(false);
    setPainel(true);
    setSubs(false);
    setPagamentos(false);
    setNoti(false);
    setReff(false);
    setConfi(false);
    setGest(false);
  }

  var [atividades, setAtividades] = useState(false);
  var [backcorAtividades, setBackcorAtividades] = useState(false);
  var [corAtividades, setCorAtividades] = useState(false);
  var [shadowAtividades, setShadowAtividades] = useState(false);

  if (atividades === false) {
    setAtividades("none");
    setBackcorAtividades("transparent");
    setCorAtividades("#8c8c8c");
    setShadowAtividades("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (atividades === true) {
    setAtividades("block");
    setBackcorAtividades("#55aef6");
    setCorAtividades("#fff");
    setShadowAtividades("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openAtividades() {
    setAtividades(true);
    setProcessos(false);
    setCRM(false);
    setReunioes(false);
    setAlerta(false);
  }

  var [crm, setCRM] = useState(false);
  var [backcorCRM, setBackcorCRM] = useState(false);
  var [corCRM, setCorCRM] = useState(false);
  var [shadowCRM, setShadowCRM] = useState(false);

  if (crm === false) {
    setCRM("none");
    setBackcorCRM("transparent");
    setCorCRM("#8c8c8c");
    setShadowCRM("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (crm === true) {
    setCRM("block");
    setBackcorCRM("#55aef6");
    setCorCRM("#fff");
    setShadowCRM("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openCRM() {
    setAtividades(false);
    setProcessos(false);
    setCRM(true);
    setReunioes(false);
    setAlerta(false);
  }

  var [processos, setProcessos] = useState(false);
  var [backcorProcessos, setBackcorProcessos] = useState(false);
  var [corProcessos, setCorProcessos] = useState(false);
  var [shadowProcessos, setShadowProcessos] = useState(false);

  if (processos === false) {
    setProcessos("none");
    setBackcorProcessos("transparent");
    setCorProcessos("#8c8c8c");
    setShadowProcessos("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (processos === true) {
    setProcessos("block");
    setBackcorProcessos("#55aef6");
    setCorProcessos("#fff");
    setShadowProcessos("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openProcessos() {
    setAtividades(false);
    setProcessos(true);
    setCRM(false);
    setReunioes(false);
    setAlerta(false);
  }

  var [reunioes, setReunioes] = useState(false);
  var [backcorReunioes, setBackcorReunioes] = useState(false);
  var [corReunioes, setCorReunioes] = useState(false);
  var [shadowReunioes, setShadowReunioes] = useState(false);

  if (reunioes === false) {
    setReunioes("none");
    setBackcorReunioes("transparent");
    setCorReunioes("#8c8c8c");
    setShadowReunioes("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (reunioes === true) {
    setReunioes("block");
    setBackcorReunioes("#55aef6");
    setCorReunioes("#fff");
    setShadowReunioes("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openReunioes() {
    setAtividades(false);
    setProcessos(false);
    setCRM(false);
    setReunioes(true);
    setAlerta(false);
  }

  var [alerta, setAlerta] = useState(false);
  var [backcorAlerta, setBackcorAlerta] = useState(false);
  var [corAlerta, setCorAlerta] = useState(false);
  var [shadowAlerta, setShadowAlerta] = useState(false);

  if (alerta === false) {
    setAlerta("none");
    setBackcorAlerta("transparent");
    setCorAlerta("#8c8c8c");
    setShadowAlerta("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (alerta === true) {
    setAlerta("block");
    setBackcorAlerta("#55aef6");
    setCorAlerta("#fff");
    setShadowAlerta("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openAlerta() {
    setAtividades(false);
    setProcessos(false);
    setCRM(false);
    setReunioes(false);
    setAlerta(true);
  }

  var [subs, setSubs] = useState(false);
  var [backcorSubs, setBackcorSubs] = useState(false);
  var [corSubs, setCorSubs] = useState(false);
  var [shadowSubs, setShadowSubs] = useState(false);

  if (subs === false) {
    setSubs("none");
    setBackcorSubs("transparent");
    setCorSubs("#8c8c8c");
    setShadowSubs("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (subs === true) {
    setSubs("block");
    setBackcorSubs("#2196f3");
    setCorSubs("#fff");
    setShadowSubs("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openSubs() {
    setPainel(false);
    setSubs(true);
    setPagamentos(false);
    setNoti(false);
    setReff(false);
    setConfi(false);
    setGest(false);
  }

  var [pagamentos, setPagamentos] = useState(false);
  var [backcorPagamentos, setBackcorPagamentos] = useState(false);
  var [corPagamentos, setCorPagamentos] = useState(false);
  var [shadowPagamentos, setShadowPagamentos] = useState(false);

  if (pagamentos === false) {
    setPagamentos("none");
    setBackcorPagamentos("transparent");
    setCorPagamentos("#8c8c8c");
    setShadowPagamentos("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (pagamentos === true) {
    setPagamentos("block");
    setBackcorPagamentos("#2196f3");
    setCorPagamentos("#fff");
    setShadowPagamentos("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openPagamentos() {
    setPainel(false);
    setSubs(false);
    setPagamentos(true);
    setNoti(false);
    setReff(false);
    setConfi(false);
    setGest(false);
  }

  var [noti, setNoti] = useState(false);
  var [backcorNoti, setBackcorNoti] = useState(false);
  var [corNoti, setCorNoti] = useState(false);
  var [shadowNoti, setShadowNoti] = useState(false);

  if (noti === false) {
    setNoti("none");
    setBackcorNoti("transparent");
    setCorNoti("#8c8c8c");
    setShadowNoti("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (noti === true) {
    setNoti("block");
    setBackcorNoti("#2196f3");
    setCorNoti("#fff");
    setShadowNoti("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openNoti() {
    setPainel(false);
    setSubs(false);
    setPagamentos(false);
    setNoti(true);
    setReff(false);
    setConfi(false);
    setGest(false);
  }

  var [reff, setReff] = useState(false);
  var [backcorReff, setBackcorReff] = useState(false);
  var [corReff, setCorReff] = useState(false);
  var [shadowReff, setShadowReff] = useState(false);

  if (reff === false) {
    setReff("none");
    setBackcorReff("transparent");
    setCorReff("#8c8c8c");
    setShadowReff("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (reff === true) {
    setReff("block");
    setBackcorReff("#2196f3");
    setCorReff("#fff");
    setShadowReff("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openReff() {
    setPainel(false);
    setSubs(false);
    setPagamentos(false);
    setNoti(false);
    setReff(true);
    setConfi(false);
    setGest(false);
  }

  var [confi, setConfi] = useState(false);
  var [backcorConfi, setBackcorConfi] = useState(false);
  var [corConfi, setCorConfi] = useState(false);
  var [shadowConfi, setShadowConfi] = useState(false);

  if (confi === false) {
    setConfi("none");
    setBackcorConfi("transparent");
    setCorConfi("#8c8c8c");
    setShadowConfi("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (confi === true) {
    setConfi("block");
    setBackcorConfi("#2196f3");
    setCorConfi("#fff");
    setShadowConfi("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openConfi() {
    setPainel(false);
    setSubs(false);
    setPagamentos(false);
    setNoti(false);
    setReff(false);
    setConfi(true);
    setGest(false);
  }

  var [gest, setGest] = useState(false);
  var [backcorGest, setBackcorGest] = useState(false);
  var [corGest, setCorGest] = useState(false);
  var [shadowGest, setShadowGest] = useState(false);

  if (gest === false) {
    setGest("none");
    setBackcorGest("transparent");
    setCorGest("#8c8c8c");
    setShadowGest("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (gest === true) {
    setGest("block");
    setBackcorGest("linear-gradient(60deg, #ef5350, #e53935)");
    setCorGest("#fff");
    setShadowGest("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openGest() {
    setPainel(false);
    setSubs(false);
    setPagamentos(false);
    setNoti(false);
    setReff(false);
    setConfi(false);
    setGest(true);
  }

  var [configGerais, setConfigGerais] = useState(true);
  var [backcorConfigGerais, setBackcorConfigGerais] = useState(false);
  var [corConfigGerais, setCorConfigGerais] = useState(false);
  var [shadowConfigGerais, setShadowConfigGerais] = useState(false);

  if (configGerais === false) {
    setConfigGerais("none");
    setBackcorConfigGerais("transparent");
    setCorConfigGerais("#8c8c8c");
    setShadowConfigGerais("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (configGerais === true) {
    setConfigGerais("block");
    setBackcorConfigGerais("linear-gradient(60deg, #ef5350, #e53935)");
    setCorConfigGerais("#fff");
    setShadowConfigGerais("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openConfigGerais() {
    setConfigGerais(true);
    setUtili(false);
    setProc(false);
    setInfra(false);
    setNotifi(false);
    setReuni(false);
    setEmpresas(false);
  }

  var [utili, setUtili] = useState(false);
  var [backcorUtili, setBackcorUtili] = useState(false);
  var [corUtili, setCorUtili] = useState(false);
  var [shadowUtili, setShadowUtili] = useState(false);

  if (utili === false) {
    setUtili("none");
    setBackcorUtili("transparent");
    setCorUtili("#8c8c8c");
    setShadowUtili("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (utili === true) {
    setUtili("block");
    setBackcorUtili("linear-gradient(60deg, #ef5350, #e53935)");
    setCorUtili("#fff");
    setShadowUtili("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openUtili() {
    setConfigGerais(false);
    setUtili(true);
    setProc(false);
    setInfra(false);
    setNotifi(false);
    setReuni(false);
    setEmpresas(false);
  }

  var [proc, setProc] = useState(false);
  var [backcorProc, setBackcorProc] = useState(false);
  var [corProc, setCorProc] = useState(false);
  var [shadowProc, setShadowProc] = useState(false);

  if (proc === false) {
    setProc("none");
    setBackcorProc("transparent");
    setCorProc("#8c8c8c");
    setShadowProc("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (proc === true) {
    setProc("block");
    setBackcorProc("linear-gradient(60deg, #ef5350, #e53935)");
    setCorProc("#fff");
    setShadowProc("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openProc() {
    setConfigGerais(false);
    setUtili(false);
    setProc(true);
    setInfra(false);
    setNotifi(false);
    setReuni(false);
    setEmpresas(false);
  }

  var [infra, setInfra] = useState(false);
  var [backcorInfra, setBackcorInfra] = useState(false);
  var [corInfra, setCorInfra] = useState(false);
  var [shadowInfra, setShadowInfra] = useState(false);

  if (infra === false) {
    setInfra("none");
    setBackcorInfra("transparent");
    setCorInfra("#8c8c8c");
    setShadowInfra("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (infra === true) {
    setInfra("block");
    setBackcorInfra("linear-gradient(60deg, #ef5350, #e53935)");
    setCorInfra("#fff");
    setShadowInfra("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openInfra() {
    setConfigGerais(false);
    setUtili(false);
    setProc(false);
    setInfra(true);
    setNotifi(false);
    setReuni(false);
    setEmpresas(false);
  }

  var [notifi, setNotifi] = useState(false);
  var [backcorNotifi, setBackcorNotifi] = useState(false);
  var [corNotifi, setCorNotifi] = useState(false);
  var [shadowNotifi, setShadowNotifi] = useState(false);

  if (notifi === false) {
    setNotifi("none");
    setBackcorNotifi("transparent");
    setCorNotifi("#8c8c8c");
    setShadowNotifi("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (notifi === true) {
    setNotifi("block");
    setBackcorNotifi("linear-gradient(60deg, #ef5350, #e53935)");
    setCorNotifi("#fff");
    setShadowNotifi("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openNotifi() {
    setConfigGerais(false);
    setUtili(false);
    setProc(false);
    setInfra(false);
    setNotifi(true);
    setReuni(false);
    setEmpresas(false);
  }

  var [reuni, setReuni] = useState(false);
  var [backcorReuni, setBackcorReuni] = useState(false);
  var [corReuni, setCorReuni] = useState(false);
  var [shadowReuni, setShadowReuni] = useState(false);

  if (reuni === false) {
    setReuni("none");
    setBackcorReuni("transparent");
    setCorReuni("#8c8c8c");
    setShadowReuni("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (reuni === true) {
    setReuni("block");
    setBackcorReuni("linear-gradient(60deg, #ef5350, #e53935)");
    setCorReuni("#fff");
    setShadowReuni("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openReuni() {
    setConfigGerais(false);
    setUtili(false);
    setProc(false);
    setInfra(false);
    setNotifi(false);
    setReuni(true);
    setEmpresas(false);
  }

  var [empresas, setEmpresas] = useState(false);
  var [backcorEmpresas, setBackcorEmpresas] = useState(false);
  var [corEmpresas, setCorEmpresas] = useState(false);
  var [shadowEmpresas, setShadowEmpresas] = useState(false);

  if (empresas === false) {
    setEmpresas("none");
    setBackcorEmpresas("transparent");
    setCorEmpresas("#8c8c8c");
    setShadowEmpresas("0px 0px 0px rgba(0, 0, 0, 0.0)");
  } else if (empresas === true) {
    setEmpresas("block");
    setBackcorEmpresas("linear-gradient(60deg, #ef5350, #e53935)");
    setCorEmpresas("#fff");
    setShadowEmpresas("2px 2px 10px rgba(0, 0, 0, 0.2)");
  }

  function openEmpresas() {
    setConfigGerais(false);
    setUtili(false);
    setProc(false);
    setInfra(false);
    setNotifi(false);
    setReuni(false);
    setEmpresas(true);
  }

  const getUser = async () => {
    const docRef = doc(db, "administradores", user.id);

    const docSnap = await getDoc(docRef);
    setFname(docSnap.data().fname);
    setLname(docSnap.data().lname);
    setNivel(docSnap.data().nivel);
  };
  var [x, setX] = useState(0);
  try {
    while (x == 0 && user.uid != undefined) {
      getUser();
      setX(1);
      break;
    }
  } catch (e) {
    console.log(e.message);
  }

  useEffect(() => {
    getUser();
  }, [loading]);

  return (
    <>
      {user.fname == null && loading == false ? null : (
        <nav>
          <div
            style={{
              backgroundColor: "#fff",
              width: "270px",
              height: "100%",
              position: "fixed",
              top: 0,
              boxShadow: "2px 2px 12px #e8e8e9",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid #e8e8e9",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <img
                style={{
                  marginLeft: "15%",
                  marginRight: "15%",
                  width: "70%",
                  marginTop: 25,
                  paddingBottom: 15,
                }}
                src={logo}
                alt="Logo"
              />
            </div>

            <div
              style={{
                overflow: "auto",
                backgroundColor: "#fff",
                height: "100%",
              }}
            >
              <Link style={{ textDecoration: "none" }} to="/conta">
                <div
                  onClick={openConfi}
                  style={{
                    display: "inline-flex",
                    marginLeft: 20,
                    marginRight: 20,
                    paddingBottom: 0,
                    marginTop: 20,
                  }}
                >
                  <div
                    style={{
                      borderRadius: 100,
                      backgroundColor: "#2196f3",
                      width: 50,
                      height: 50,
                      boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.20)",
                    }}
                  >
                    <h1
                      style={{
                        color: "#fff",
                        paddingTop: 1,
                        fontSize: 16,
                        textAlign: "center",
                        fontFamily: "Avenir Next",
                      }}
                    >
                      AE
                    </h1>
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    <h5
                      style={{
                        color: "#8c8c8c",
                        marginTop: 0,
                        fontFamily: "Avenir Next",
                        fontWeight: "500",
                      }}
                    >
                      Olá
                    </h5>
                    <h5
                      style={{
                        marginTop: -22,
                        fontFamily: "Avenir Next",
                        color: "#444",
                      }}
                    >
                      {fname + " " + lname}
                    </h5>
                    <h5
                      style={{
                        color: "#8c8c8c",
                        marginTop: -22,
                        fontFamily: "Avenir Next",
                        fontWeight: "500",
                      }}
                    >
                      Advogado
                    </h5>
                  </div>
                </div>
              </Link>
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 0,
                  borderBottom: "1px solid #e8e8e9",
                  borderTop: "1px solid #e8e8e9",
                  paddingTop: 20,
                }}
              >
                <h5
                  style={{
                    color: "#444",
                    marginTop: 0,
                    textAlign: "center",
                    fontFamily: "Avenir Next",
                    fontWeight: "400",
                  }}
                >
                  Telemóvel SOS
                </h5>
                <h5
                  style={{
                    marginTop: -20,
                    textAlign: "center",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  966111222
                </h5>
              </div>

              <Link style={{ textDecoration: "none" }} to="/">
                <button
                  style={{
                    border: 0,
                    backgroundColor: "transparent",
                    width: "100%",
                  }}
                  onClick={openPainel}
                >
                  <div
                    style={{
                      marginLeft: 0,
                      width: "85%",
                      borderRadius: 5,
                      backgroundColor: backcorPainel,
                      boxShadow: shadowPainel,
                      marginRight: 20,
                      marginTop: 10,
                      paddingTop: 20,
                      display: "inline-flex",
                    }}
                  >
                    <i
                      class="material-icons"
                      style={{
                        marginTop: -5,
                        paddingLeft: 20,
                        color: corPainel,
                      }}
                    >
                      dashboard
                    </i>
                    <h5
                      style={{
                        marginTop: 0,
                        textAlign: "left",
                        paddingLeft: 20,
                        fontFamily: "Avenir Next",
                        fontWeight: "500",
                        color: corPainel,
                        fontSize: "13px",
                      }}
                    >
                      Painel de Bordo
                    </h5>
                  </div>
                </button>
              </Link>
              <div style={{ display: painel }}>
                <Link style={{ textDecoration: "none" }} to="/crm">
                  <div
                    onClick={openCRM}
                    style={{
                      marginLeft: 50,
                      width: "74%",
                      borderRadius: 5,
                      backgroundColor: backcorCRM,
                      boxShadow: shadowCRM,
                      marginRight: 20,
                      marginTop: 10,
                      paddingTop: 20,
                      display: "inline-flex",
                    }}
                  >
                    <i
                      class="material-icons"
                      style={{
                        marginTop: -5,
                        paddingLeft: 20,
                        color: corCRM,
                      }}
                    >
                      all_inbox
                    </i>
                    <h5
                      style={{
                        marginTop: 0,
                        textAlign: "left",
                        paddingLeft: 20,
                        fontFamily: "Avenir Next",
                        fontWeight: "500",
                        color: corCRM,
                      }}
                    >
                      CRM
                    </h5>
                  </div>
                </Link>
                <Link style={{ textDecoration: "none" }} to="/tarefas">
                  <div
                    onClick={openAtividades}
                    style={{
                      marginLeft: 50,
                      width: "74%",
                      borderRadius: 5,
                      backgroundColor: backcorAtividades,
                      boxShadow: shadowAtividades,
                      marginRight: 20,
                      marginTop: 10,
                      paddingTop: 20,
                      display: "inline-flex",
                    }}
                  >
                    <i
                      class="material-icons"
                      style={{
                        marginTop: -5,
                        paddingLeft: 20,
                        color: corAtividades,
                      }}
                    >
                      all_inbox
                    </i>
                    <h5
                      style={{
                        marginTop: 0,
                        textAlign: "left",
                        paddingLeft: 20,
                        fontFamily: "Avenir Next",
                        fontWeight: "500",
                        color: corAtividades,
                      }}
                    >
                      Tarefas
                    </h5>
                  </div>
                </Link>
                <Link style={{ textDecoration: "none" }} to="/assuntos">
                  <div
                    onClick={openProcessos}
                    style={{
                      marginLeft: 50,
                      width: "74%",
                      borderRadius: 5,
                      backgroundColor: backcorProcessos,
                      boxShadow: shadowProcessos,
                      marginRight: 20,
                      marginTop: 10,
                      paddingTop: 20,
                      display: "inline-flex",
                    }}
                  >
                    <i
                      class="material-icons"
                      style={{
                        marginTop: -5,
                        paddingLeft: 20,
                        color: corProcessos,
                      }}
                    >
                      receipt
                    </i>
                    <h5
                      style={{
                        marginTop: 0,
                        textAlign: "left",
                        paddingLeft: 20,
                        fontFamily: "Avenir Next",
                        fontWeight: "500",
                        color: corProcessos,
                      }}
                    >
                      Processos
                    </h5>
                  </div>
                </Link>
                <Link style={{ textDecoration: "none" }} to="/reunioes">
                  <div
                    onClick={openReunioes}
                    style={{
                      marginLeft: 50,
                      width: "74%",
                      borderRadius: 5,
                      backgroundColor: backcorReunioes,
                      boxShadow: shadowReunioes,
                      marginRight: 20,
                      marginTop: 10,
                      paddingTop: 20,
                      display: "inline-flex",
                    }}
                  >
                    <i
                      class="material-icons"
                      style={{
                        marginTop: -5,
                        paddingLeft: 20,
                        color: corReunioes,
                      }}
                    >
                      today
                    </i>
                    <h5
                      style={{
                        marginTop: 0,
                        textAlign: "left",
                        paddingLeft: 20,
                        fontFamily: "Avenir Next",
                        fontWeight: "500",
                        color: corReunioes,
                      }}
                    >
                      Reuniões
                    </h5>
                  </div>
                </Link>
                {/* <Link style={{textDecoration: "none"}} to="/alertas"><div onClick={openAlerta} style={{marginLeft: 50, width: "74%", borderRadius: 5, backgroundColor: backcorAlerta, boxShadow: shadowAlerta, marginRight: 20, marginTop: 10, paddingTop: 20, display: "inline-flex",}}>
            <i class="material-icons" style={{marginTop: -5, paddingLeft: 20, color: corAlerta}}>notifications_active</i> 
            <h5 style={{marginTop: 0, textAlign: "left", paddingLeft: 20, fontFamily: "Avenir Next", fontWeight: "500", color: corAlerta}}>Alertas</h5>
          </div></Link> */}
              </div>

              <Link style={{ textDecoration: "none" }} to="/clientes">
                <button
                  style={{
                    border: 0,
                    backgroundColor: "transparent",
                    width: "100%",
                  }}
                  onClick={openSubs}
                >
                  <div
                    style={{
                      marginLeft: 0,
                      width: "85%",
                      borderRadius: 5,
                      backgroundColor: backcorSubs,
                      boxShadow: shadowSubs,
                      marginRight: 20,
                      marginTop: 10,
                      paddingTop: 20,
                      display: "inline-flex",
                    }}
                  >
                    <i
                      class="material-icons"
                      style={{ marginTop: -5, paddingLeft: 20, color: corSubs }}
                    >
                      person
                    </i>
                    <h5
                      style={{
                        marginTop: 0,
                        textAlign: "left",
                        paddingLeft: 20,
                        fontFamily: "Avenir Next",
                        fontWeight: "500",
                        color: corSubs,
                        fontSize: "13px",
                      }}
                    >
                      Clientes
                    </h5>
                  </div>
                </button>
              </Link>

              <Link style={{ textDecoration: "none" }} to="/pagamentos">
                <button
                  style={{
                    border: 0,
                    backgroundColor: "transparent",
                    width: "100%",
                  }}
                  onClick={openPagamentos}
                >
                  <div
                    style={{
                      marginLeft: 0,
                      width: "85%",
                      borderRadius: 5,
                      backgroundColor: backcorPagamentos,
                      boxShadow: shadowPagamentos,
                      marginRight: 20,
                      marginTop: 10,
                      paddingTop: 20,
                      display: "inline-flex",
                    }}
                  >
                    <i
                      class="material-icons"
                      style={{
                        marginTop: -5,
                        paddingLeft: 20,
                        color: corPagamentos,
                      }}
                    >
                      euro
                    </i>
                    <h5
                      style={{
                        marginTop: 0,
                        textAlign: "left",
                        paddingLeft: 20,
                        fontFamily: "Avenir Next",
                        fontWeight: "500",
                        color: corPagamentos,
                        fontSize: "13px",
                      }}
                    >
                      Pagamentos
                    </h5>
                  </div>
                </button>
              </Link>

              <Link style={{ textDecoration: "none" }} to="/notificacoes">
                <button
                  style={{
                    border: 0,
                    backgroundColor: "transparent",
                    width: "100%",
                  }}
                  onClick={openNoti}
                >
                  <div
                    style={{
                      marginLeft: 0,
                      width: "85%",
                      borderRadius: 5,
                      backgroundColor: backcorNoti,
                      boxShadow: shadowNoti,
                      marginRight: 20,
                      marginTop: 10,
                      paddingTop: 20,
                      display: "inline-flex",
                    }}
                  >
                    <i
                      class="material-icons"
                      style={{ marginTop: -5, paddingLeft: 20, color: corNoti }}
                    >
                      message
                    </i>
                    <h5
                      style={{
                        marginTop: 0,
                        textAlign: "left",
                        paddingLeft: 20,
                        fontFamily: "Avenir Next",
                        fontWeight: "500",
                        color: corNoti,
                        fontSize: "13px",
                      }}
                    >
                      Notificações
                    </h5>
                  </div>
                </button>
              </Link>

              <Link style={{ textDecoration: "none" }} to="/referrals">
                <button
                  style={{
                    border: 0,
                    backgroundColor: "transparent",
                    width: "100%",
                  }}
                  onClick={openReff}
                >
                  <div
                    style={{
                      marginLeft: 0,
                      width: "85%",
                      borderRadius: 5,
                      backgroundColor: backcorReff,
                      boxShadow: shadowReff,
                      marginRight: 20,
                      marginTop: 10,
                      paddingTop: 20,
                      display: "inline-flex",
                    }}
                  >
                    <i
                      class="material-icons"
                      style={{ marginTop: -5, paddingLeft: 20, color: corReff }}
                    >
                      share
                    </i>
                    <h5
                      style={{
                        marginTop: 0,
                        textAlign: "left",
                        paddingLeft: 20,
                        fontFamily: "Avenir Next",
                        fontWeight: "500",
                        color: corReff,
                        fontSize: "13px",
                      }}
                    >
                      Referrals
                    </h5>
                  </div>
                </button>
              </Link>

              <Link style={{ textDecoration: "none" }} to="/conta">
                <button
                  style={{
                    border: 0,
                    backgroundColor: "transparent",
                    width: "100%",
                  }}
                  onClick={openConfi}
                >
                  <div
                    style={{
                      marginLeft: 0,
                      width: "85%",
                      borderRadius: 5,
                      backgroundColor: backcorConfi,
                      boxShadow: shadowConfi,
                      marginRight: 20,
                      marginTop: 10,
                      paddingTop: 20,
                      display: "inline-flex",
                    }}
                  >
                    <i
                      class="material-icons"
                      style={{
                        marginTop: -5,
                        paddingLeft: 20,
                        color: corConfi,
                      }}
                    >
                      settings
                    </i>
                    <h5
                      style={{
                        marginTop: 0,
                        color: "#8c8c8c",
                        textAlign: "left",
                        paddingLeft: 20,
                        fontFamily: "Avenir Next",
                        fontWeight: "500",
                        color: corConfi,
                        fontSize: "13px",
                      }}
                    >
                      Configurações
                    </h5>
                  </div>
                </button>
              </Link>

              {nivel == "Admin" ? (
                <>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/gestaoadministrativa"
                  >
                    <button
                      style={{
                        border: 0,
                        backgroundColor: "transparent",
                        width: "100%",
                      }}
                      onClick={openGest}
                    >
                      <div
                        style={{
                          marginLeft: 0,
                          width: "85%",
                          borderTop: "1px solid #e8e8e9",
                          borderBottom: "1px solid #e8e8e9",
                          borderRadius: 5,
                          background: backcorGest,
                          boxShadow: shadowGest,
                          marginRight: 20,
                          marginTop: 10,
                          paddingTop: 20,
                          paddingBottom: pBottom,
                          display: "inline-flex",
                        }}
                      >
                        <i
                          class="material-icons"
                          style={{
                            marginTop: -5,
                            paddingLeft: 20,
                            color: corGest,
                          }}
                        >
                          supervisor_account
                        </i>
                        <h5
                          style={{
                            marginTop: 0,
                            color: "#8c8c8c",
                            textAlign: "left",
                            paddingLeft: 20,
                            fontFamily: "Avenir Next",
                            fontWeight: "500",
                            color: corGest,
                            fontSize: "13px",
                          }}
                        >
                          Gestão Administrativa
                        </h5>
                      </div>
                    </button>
                  </Link>

                  <div style={{ display: gest }}>
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/gestaoadministrativa"
                    >
                      <div
                        onClick={openConfigGerais}
                        style={{
                          marginLeft: 50,
                          width: "74%",
                          borderRadius: 5,
                          background: backcorConfigGerais,
                          boxShadow: shadowConfigGerais,
                          marginRight: 20,
                          marginTop: 10,
                          paddingTop: 20,
                          display: "inline-flex",
                        }}
                      >
                        <i
                          class="material-icons"
                          style={{
                            marginTop: -5,
                            paddingLeft: 20,
                            color: corConfigGerais,
                          }}
                        >
                          settings
                        </i>
                        <h5
                          style={{
                            marginTop: 0,
                            textAlign: "left",
                            paddingLeft: 20,
                            fontFamily: "Avenir Next",
                            fontWeight: "500",
                            color: corConfigGerais,
                          }}
                        >
                          Configurações Gerais
                        </h5>
                      </div>
                    </Link>
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/gestaoutilizadores"
                    >
                      <div
                        onClick={openUtili}
                        style={{
                          marginLeft: 50,
                          width: "74%",
                          borderRadius: 5,
                          background: backcorUtili,
                          boxShadow: shadowUtili,
                          marginRight: 20,
                          marginTop: 10,
                          paddingTop: 20,
                          display: "inline-flex",
                        }}
                      >
                        <i
                          class="material-icons"
                          style={{
                            marginTop: -5,
                            paddingLeft: 20,
                            color: corUtili,
                          }}
                        >
                          people
                        </i>
                        <h5
                          style={{
                            marginTop: 0,
                            textAlign: "left",
                            paddingLeft: 20,
                            fontFamily: "Avenir Next",
                            fontWeight: "500",
                            color: corUtili,
                          }}
                        >
                          Utilizadores
                        </h5>
                      </div>
                    </Link>
                    {/* <Link style={{textDecoration: "none"}} to="/gestaoprocessos"><div onClick={openProc} style={{marginLeft: 50, width: "74%", borderRadius: 5, background: backcorProc, boxShadow: shadowProc, marginRight: 20, marginTop: 10, paddingTop: 20, display: "inline-flex",}}>
            <i class="material-icons" style={{marginTop: -5, paddingLeft: 20, color: corProc}}>euro</i> 
            <h5 style={{marginTop: 0, textAlign: "left", paddingLeft: 20, fontFamily: "Avenir Next", fontWeight: "500", color: corProc}}>Processos</h5>
          </div></Link> */}
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/gestaoinfracoes"
                    >
                      <div
                        onClick={openInfra}
                        style={{
                          marginLeft: 50,
                          width: "74%",
                          borderRadius: 5,
                          background: backcorInfra,
                          boxShadow: shadowInfra,
                          marginRight: 20,
                          marginTop: 10,
                          paddingTop: 20,
                          display: "inline-flex",
                        }}
                      >
                        <i
                          class="material-icons"
                          style={{
                            marginTop: -5,
                            paddingLeft: 20,
                            color: corInfra,
                          }}
                        >
                          warning
                        </i>
                        <h5
                          style={{
                            marginTop: 0,
                            textAlign: "left",
                            paddingLeft: 20,
                            fontFamily: "Avenir Next",
                            fontWeight: "500",
                            color: corInfra,
                          }}
                        >
                          Infrações
                        </h5>
                      </div>
                    </Link>
                    {/* <Link style={{textDecoration: "none"}} to="/gestaonotificacoes"><div onClick={openNotifi} style={{marginLeft: 50, width: "74%", borderRadius: 5, background: backcorNotifi, boxShadow: shadowNotifi, marginRight: 20, marginTop: 10, paddingTop: 20, display: "inline-flex",}}>
            <i class="material-icons" style={{marginTop: -5, paddingLeft: 20, color: corNotifi}}>notifications_active</i> 
            <h5 style={{marginTop: 0, textAlign: "left", paddingLeft: 20, fontFamily: "Avenir Next", fontWeight: "500", color: corNotifi}}>Notificações</h5>
          </div></Link> */}
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/gestaoreunioes"
                    >
                      <div
                        onClick={openReuni}
                        style={{
                          marginLeft: 50,
                          width: "74%",
                          borderRadius: 5,
                          background: backcorReuni,
                          boxShadow: shadowReuni,
                          marginRight: 20,
                          marginTop: 10,
                          paddingTop: 20,
                          display: "inline-flex",
                        }}
                      >
                        <i
                          class="material-icons"
                          style={{
                            marginTop: -5,
                            paddingLeft: 20,
                            color: corReuni,
                          }}
                        >
                          today
                        </i>
                        <h5
                          style={{
                            marginTop: 0,
                            textAlign: "left",
                            paddingLeft: 20,
                            fontFamily: "Avenir Next",
                            fontWeight: "500",
                            color: corReuni,
                          }}
                        >
                          Reuniões
                        </h5>
                      </div>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", paddingBottom: 150 }}
                      to="/gestaoempresas"
                    >
                      <div
                        onClick={openEmpresas}
                        style={{
                          marginLeft: 50,
                          width: "74%",
                          borderRadius: 5,
                          background: backcorEmpresas,
                          boxShadow: shadowEmpresas,
                          marginRight: 20,
                          marginTop: 10,
                          paddingTop: 20,
                          display: "inline-flex",
                        }}
                      >
                        <i
                          class="material-icons"
                          style={{
                            marginTop: -5,
                            paddingLeft: 20,
                            color: corEmpresas,
                          }}
                        >
                          business
                        </i>
                        <h5
                          style={{
                            marginTop: 0,
                            textAlign: "left",
                            paddingLeft: 20,
                            fontFamily: "Avenir Next",
                            fontWeight: "500",
                            color: corEmpresas,
                          }}
                        >
                          Empresas
                        </h5>
                      </div>
                    </Link>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

export default NavBar;
