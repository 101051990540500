import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
  query,
  orderBy,
} from "firebase/firestore";
import Select from "react-select";
import { auth, db } from "../firebase";
import DatePicker from "react-date-picker";
import { Link, useNavigate } from "react-router-dom";

import "@fontsource/material-icons";

const Notificacoes = () => {
  const [showForm, setShowForm] = useState(false);
  const [dataTermo, setDataTermo] = useState(new Date());
  const [observacoes, setObservacoes] = useState("");
  const [aba, setAba] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOption, setFilterOption] = useState("Todas");
  const [isGeral, setIsGeral] = useState(true);

  const [notifications, setNotifications] = useState([]);
  const [users, setUsers] = useState([]);

  const [userId, setUserId] = useState("");

  useEffect(() => {
    fetchNotifications();
    fetchUsers();
  }, []);

  const fetchNotifications = async () => {
    try {
      const notificationsRef = collection(db, "notificacoes");
      const q = query(notificationsRef, orderBy("dataCriacao", "desc"));
      const querySnapshot = await getDocs(q);
      const notificationsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotifications(notificationsData);
    } catch (error) {
      console.error("Error fetching notifications: ", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const docList = [];
      setUsers([]);
      querySnapshot.docs.map((doc) => {
        docList.push({
          value: doc.id,
          label: doc.data().email,
        });
      });
      setUsers(docList);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!dataTermo) {
      return; // Prevent form submission if dateTermo is null
    }

    const newNotification = {
      dataTermo,
      dataCriacao: serverTimestamp(),
      observacoes,
      aba,
      userId,
    };

    try {
      const docRef = await addDoc(
        collection(db, "notificacoes"),
        newNotification
      );
      console.log("Document written with ID: ", docRef.id);

      setDataTermo(new Date());
      setObservacoes("");
      setShowForm(false);

      // Fetch notifications again to update the list
      fetchNotifications();
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (selectedOption) => {
    setFilterOption(selectedOption.value);
  };

  // Filter notifications based on the search term and filter option
  const filteredNotifications = notifications
    .filter((notification) => {
      const today = new Date();
      const isSameDay =
        notification.dataTermo.toDate().getDate() === today.getDate();

      if (filterOption === "Ativas") {
        return isSameDay || notification.dataTermo.toDate() > new Date();
      } else if (filterOption === "Expiradas") {
        return (
          isSameDay == false && notification.dataTermo.toDate() < new Date()
        );
      } else {
        return true; // Show all notifications
      }
    })
    .filter((notification) =>
      notification.observacoes.toLowerCase().includes(searchTerm.toLowerCase())
    );

  // Options for the filter select dropdown
  const filterOptions = [
    { value: "Todas", label: "Todas" },
    { value: "Ativas", label: "Ativas" },
    { value: "Expiradas", label: "Expiradas" },
  ];

  const Categories = [
    { value: "Estado dos Processos", label: "Estados dos Processos" },
    { value: "Carteira de Condutor", label: "Carteira de Condutor" },
    { value: "Gerais", label: "Noticias Gerais" },
  ];

  return (
    <div>
      <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
        Notificações
      </h1>
      <div style={{ marginLeft: 300 }}>
        <button
          style={{
            marginTop: -15,
            marginBottom: 15,
            padding: 10,
            borderRadius: 5,
            border: 0,
            width: 140,
            fontSize: 14,
            color: "#fff",
            fontWeight: "500",
            cursor: "pointer",
            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
            backgroundColor: "rgb(33, 150, 243)",
            fontFamily: "Avenir Next",
          }}
          onClick={() => setShowForm(!showForm)}
        >
          {showForm ? "Cancelar" : "Criar Notificação"}
        </button>

        {showForm && (
          <form
            style={{
              backgroundColor: "#fff",
              boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
              padding: 25,
              borderRadius: 5,
              width: 900,
            }}
            onSubmit={handleSubmit}
          >
            <div style={{ display: "flex" }}>
              <div>
                <label style={{ fontFamily: "Avenir Next" }}>Observações</label>
                <b
                  style={{
                    marginLeft: 5,
                    fontSize: 12,
                    fontFamily: "Avenir Next",
                  }}
                >
                  ({observacoes.length}/300)
                </b>

                <input
                  maxLength={300}
                  style={{
                    borderBottomWidth: 1,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderTopWidth: 0,
                    borderRadius: 0,
                    fontFamily: "Avenir Next",
                    fontSize: 14,
                    borderBottomColor: "rgb(33, 150, 243)",
                    width: "100%",
                    height: 10,
                    padding: 15,
                  }}
                  required
                  onChange={(e) => setObservacoes(e.target.value)}
                  type="text"
                />
              </div>
              <div style={{ marginLeft: 50 }}>
                <label style={{ fontFamily: "Avenir Next" }}>
                  Data do Termo
                </label>
                <br></br>
                <br></br>
                <DatePicker
                  minDate={new Date()}
                  value={dataTermo}
                  onChange={setDataTermo}
                />
                {dataTermo === null && <p>Tem que selecionar data de termo</p>}
              </div>
              <div style={{ marginLeft: 20, width: 150, marginTop: -2 }}>
                <label style={{ fontFamily: "Avenir Next" }}>Aba</label>
                <br></br>
                <br></br>
                <Select
                  options={Categories}
                  onChange={(value) => setAba(value.value)}
                  placeholder="Selecionar"
                />
              </div>

              

                {!isGeral && (
                  <>
                  <div style={{marginTop: -2, marginLeft: 10}}>
                    <label style={{ fontFamily: "Avenir Next" }}>
                      Utilizador
                    </label>
                    <br></br>
                    <br></br>
                    <Select
                      options={users}
                      onChange={(value) => setUserId(value.value)}
                      placeholder="Selecionar"
                    />
                    </div>
                  </>
                )}

              <div style={{ marginLeft: 20, width: 50,}}>
                <label style={{ fontFamily: "Avenir Next" }}>Geral?</label>
                <input
                  style={{
                    width: "100%",
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    fontFamily: "Avenir Next",
                    fontSize: 14,
                    borderBottomColor: "rgb(33, 150, 243)",
                    backgroundColor: "transparent",
                    marginLeft: 10,
                    marginTop: 25
                  }}
                  type="checkbox"
                  checked={isGeral}
                  onChange={() => setIsGeral(!isGeral)}
                />
              </div>
            </div>
            <button
              style={{
                marginTop: 15,
                padding: 10,
                borderRadius: 5,
                border: 0,
                width: 140,
                fontSize: 14,
                color: "#fff",
                fontWeight: "500",
                cursor: "pointer",
                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                backgroundColor: "rgb(33, 150, 243)",
                fontFamily: "Avenir Next",
              }}
              type="submit"
            >
              Guardar
            </button>
          </form>
        )}
        <div>
          <input
            type="text"
            placeholder="Pesquisar por observações"
            value={searchTerm}
            onChange={handleSearch}
            style={{
              width: 530,
              marginTop: 35,
              marginBottom: 15,
              paddingBottom: 5,
              backgroundColor: "transparent",
              color: "#000",
              fontFamily: "Avenir Next",
              fontSize: "14px",
              border: 0,
              borderBottom: "1px solid rgb(33, 150, 243)",
            }}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <Select
            options={filterOptions}
            defaultValue={filterOptions[0]}
            onChange={handleFilterChange}
          />
        </div>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 10,
            justifyContent: "space-between",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1, display: "flex" }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                flex: "15%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data do Termo
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                flex: "15%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de Criação
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                flex: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Observações
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                flex: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Aba
            </td>
          </tr>
        </table>
        {filteredNotifications.map((notification) => (
          // <Link
          //   style={{ textDecoration: "none" }}
          //   to={`/notificacao/${notification.id}`}
          // >
          //   <div key={notification.id}>
          //     <p>
          //       {notification.dataTermo.toDate().toLocaleDateString("en-GB")}
          //     </p>
          //     <p>
          //       {notification.dataCriacao.toDate().toLocaleDateString("en-GB")}
          //     </p>
          //     <p>{notification.observacoes}</p>
          //     <hr />
          //   </div>
          // </Link>
          <table
            border={1}
            style={{
              backgroundColor: "#fff",
              borderWidth: 0.6,
              borderColor: "#e8e8e9",
              paddingBottom: 10,
              paddingLeft: 10,
              paddingTop: 10,
              width: "100%",
            }}
          >
            <Link
              style={{ textDecoration: "none" }}
              to={`/notificacao/${notification.id}`}
            >
              <tr style={{ display: "flex" }}>
                <td
                  style={{
                    borderWidth: 0,
                    borderColor: "#000",
                    flex: "15%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                    color: "#000",
                  }}
                >
                  {notification.dataTermo.toDate().toLocaleDateString("en-GB")}
                </td>

                <td
                  style={{
                    borderWidth: 0,
                    borderColor: "#000",
                    flex: "15%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                    color: "#000",
                  }}
                >
                  {notification.dataCriacao
                    .toDate()
                    .toLocaleDateString("en-GB")}
                </td>

                <td
                  style={{
                    borderWidth: 0,
                    borderColor: "#000",
                    flex: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                    color: "#000",
                  }}
                >
                  {notification.observacoes}
                </td>
                <td
                  style={{
                    borderWidth: 0,
                    borderColor: "#000",
                    flex: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                    color: "#000",
                  }}
                >
                  {notification.aba}
                </td>
              </tr>
            </Link>
          </table>
        ))}
      </div>
    </div>
  );
};

export default Notificacoes;
