import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
  serverTimestamp,
  doc,
  getDoc,
  orderBy,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import CrmCard from "../components/cards/CrmCard";
import EditCrm from "./EditCrm";
import { Link } from "react-router-dom";
import DatePicker from "react-date-picker";

const Crm = () => {
  const [crms, setCrms] = useState([]);
  const [admins, setAdmins] = useState([]);

  const [estadoQuery, setEstadoQuery] = useState("");
  const [gestorQuery, setGestorQuery] = useState("");
  const [clienteQuery, setClienteQuery] = useState("");
  const [startDateQuery, setStartDateQuery] = useState("");
  const [endDateQuery, setEndDateQuery] = useState("");

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [admin, setAdmin] = useState("");
  const [tipo, setTipo] = useState("");
  const [dataPrev, setDataPrev] = useState(new Date());
  const [dataRea, setDataRea] = useState(new Date());
  const [feito, setFeito] = useState("");
  const [observacoes, setObservacoes] = useState("");

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const handleHoursChange = (event) => {
    const { value } = event.target;
    const parsedValue = parseInt(value, 10);

    if (value === "" || (parsedValue >= 0 && parsedValue <= 24)) {
      setHours(parsedValue);
    }
  };

  const handleMinutesChange = (event) => {
    const { value } = event.target;
    const parsedValue = parseInt(value, 10);

    if (value === "" || (parsedValue >= 0 && parsedValue <= 59)) {
      setMinutes(parsedValue);
    }
  };

  const fetchAdmins = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "administradores"));
      const docList = [];
      setAdmins([]);
      querySnapshot.docs.map((doc) =>
        docList.push({
          value: doc.id,
          label: doc.data().fname + " " + doc.data().lname,
        })
      );
      setAdmins(docList);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      dataRea.setHours(hours);
      dataRea.setMinutes(minutes);

      const dbRef = collection(db, "crm");
      addDoc(dbRef, {
        fname,
        lname,
        email,
        phone,
        gestorId: admin,
        tipo,
        dataPrev,
        dataRea,
        feito,
        observacoes,
        createdAt: serverTimestamp(),
      }).then((docRef) => {
        console.log("Document has been added successfully");
      });
      console.log("Document Added");
      fetchDocs();
      alert("Informações guardadas com sucesso.");
    } catch (e) {
      console.log(e.message);
    }
  };

  const fetchDocs = async () => {
    try {
      const q = query(collection(db, "crm"), orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);
      const documents = [];

      for (const docRef of querySnapshot.docs) {
        const docData = docRef.data();

        const gestorId = docData.gestorId;

        if (gestorId) {
          const subscriberRef = doc(db, "administradores", gestorId);

          const subscriberSnapshot = await getDoc(subscriberRef);

          if (subscriberSnapshot.exists()) {
            const subscriberData = {
              ...docData,
              gestor: {
                ...subscriberSnapshot.data(),
                id: subscriberSnapshot.id,
              },
            };

            documents.push({ id: docRef.id, ...subscriberData });
          }
        }
      }

      setCrms(documents);
    } catch (e) {
      console.log(e.message);
    }
  };

  const options = [
    { value: "Email", label: "Email" },
    { value: "Telefone", label: "Telefone" },
    { value: "SMS", label: "SMS" },
    { value: "Whatsapp", label: "Whatsapp" },
    { value: "Video-chamada", label: "Video-chamada" },
    { value: "Messenger FB", label: "Messenger FB" },
    { value: "Reunião Presencial", label: "Reunião Presencial" },
  ];
  const options2 = [
    { value: "Com Sucesso", label: "Com Sucesso" },
    { value: "Por Realizar", label: "Por Realizar" },
    { value: "Adiado", label: "Adiado" },
    { value: "Cancelado", label: "Cancelado" },
    { value: "Contacto sem Sucesso", label: "Contacto sem Sucesso" },
    {
      value: "Cliente solicita contacto em nova data/hora",
      label: "Cliente solicita contacto em nova data/hora",
    },
    { value: "Cancelado por tentativas", label: "Cancelado por tentativas" },
    {
      value: "Cancelado a pedido do cliente",
      label: "Cancelado a pedido do cliente",
    },
  ];

  const filteredOptions =
    dataRea != null ? options2.slice(2, 8) : options2.slice(1, 2);

  useEffect(() => {
    fetchDocs();
    fetchAdmins();
  }, []);

  const filteredAffairs = crms.filter((item) => {
    // Filter by estado
    console.log(item);
    if (estadoQuery && item.feito !== estadoQuery) {
      return false;
    }

    if (gestorQuery && item.gestorId !== gestorQuery) {
      return false;
    }

    /*  // Filter by client
    if (clienteQuery && item.subscriber.id !== clienteQuery) {
      return false;
    } */

    if (startDateQuery && endDateQuery) {
      if (
        item.dataPrev.toDate() < startDateQuery ||
        item.dataPrev.toDate() > endDateQuery
      ) {
        return false;
      }
    }

    return true;
  });

  return (
    <div style={{ marginLeft: 300 }}>
            
      <h1 style={{ fontFamily: "Avenir Next", marginBottom: -30 }}>CRM</h1>
            
      <form onSubmit={handleSubmit} style={{ marginRight: 25 }}>
                       
        <p style={{ fontFamily: "Avenir Next", marginBottom: -5 }}>
          Primeiro Nome
        </p>
              
        <input
          maxLength={300}
          style={{
            width: "100%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            backgroundColor: "transparent",
          }}
          onChange={(e) => setFname(e.target.value)}
          value={fname}
          type="text"
        />
        <p style={{ fontFamily: "Avenir Next", marginBottom: -5 }}>
          Último Nome
        </p>
              
        <input
          maxLength={300}
          style={{
            width: "100%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            backgroundColor: "transparent",
          }}
          onChange={(e) => setLname(e.target.value)}
          value={lname}
          type="text"
        />
        <p style={{ fontFamily: "Avenir Next", marginBottom: -5 }}>Email</p>
              
        <input
          maxLength={300}
          style={{
            width: "100%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            backgroundColor: "transparent",
          }}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type="text"
        />
        <p style={{ fontFamily: "Avenir Next", marginBottom: -5 }}>Tlm.</p>
              
        <input
          maxLength={300}
          style={{
            width: "100%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            backgroundColor: "transparent",
          }}
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
          type="text"
        />
        <p style={{ fontFamily: "Avenir Next" }}>Colaborador</p>
        <Select
          options={admins}
          onChange={(admin) => setAdmin(admin.value)}
          placeholder="Selecionar"
        />
        <p style={{ fontFamily: "Avenir Next", marginBottom: -5 }}>
          Tipo de contacto
        </p>
              
        <Select
          options={options}
          onChange={(value) => setTipo(value.value)}
          placeholder="Selecionar"
        />
                
        <div style={{ display: "flex" }}>
          <div style={{ display: "block" }}>
            <p style={{ fontFamily: "Avenir Next" }}>Data Prevista</p>
            <DatePicker value={dataPrev} onChange={setDataPrev} />
          </div>

          <div style={{ display: "block", marginLeft: 20 }}>
            <p style={{ fontFamily: "Avenir Next" }}>Data de Realização</p>
            <DatePicker onChange={setDataRea} value={dataRea} />
          </div>
          {dataRea != null && (
            <div style={{ display: "block", marginLeft: 20 }}>
              <p style={{ fontFamily: "Avenir Next" }}>Hora de Realização</p>
              <label>
                <input
                  style={{ padding: 7 }}
                  type="number"
                  value={hours}
                  onChange={handleHoursChange}
                />
                :{" "}
                <input
                  style={{ padding: 7 }}
                  type="number"
                  value={minutes}
                  onChange={handleMinutesChange}
                />
              </label>
            </div>
          )}
        </div>
        <p style={{ fontFamily: "Avenir Next", marginBottom: -5 }}>Estado</p>
                 
        <Select
          options={filteredOptions}
          onChange={(value) => setFeito(value.value)}
          placeholder="Selecionar"
        />
                        
        <p style={{ fontFamily: "Avenir Next", marginBottom: -5 }}>
          Observações
        </p>
                
        <input
          maxLength={65}
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setObservacoes(e.target.value)}
          type="text"
        />
                
        <button
          style={{
            backgroundColor: "#2196f3",
            marginBottom: 15,
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            marginLeft: -35,
          }}
          type="text"
        >
          Submeter
        </button>
                
      </form>
      <div style={{ marginBottom: 10 }}>
        <h2 style={{ fontFamily: "Avenir Next" }}>Filtrar por:</h2>
        <p>Estado</p>
        {/* <h1>{estadoQuery}</h1> */}

        <div style={{ display: "flex" }}>
          <div style={{ width: "90%" }}>
            <Select
              options={options2}
              value={
                estadoQuery === ""
                  ? { value: "", label: "Selecionar" }
                  : { value: estadoQuery, label: estadoQuery }
              }
              onChange={(value) => setEstadoQuery(value.value)}
              placeholder="Selecionar"
            />
          </div>
          <button
            style={{
              padding: 5,
              marginLeft: 10,
              borderRadius: 5,
              border: 0,
              width: 120,
              fontSize: 14,
              color: "#000",
              fontWeight: "500",
              cursor: "pointer",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
              backgroundColor: "#e8e8e9",
              fontFamily: "Avenir Next",
            }}
            onClick={() => setEstadoQuery("")}
          >
            Limpar Filtro
          </button>
        </div>
        <p>Gestor</p>

        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ width: "90%" }}>
            <Select
              options={admins}
              value={
                gestorQuery === ""
                  ? { value: "", label: "Selecionar" }
                  : admins.find((admin) => admin.value === gestorQuery)
              }
              onChange={(value) => setGestorQuery(value.value)}
              placeholder="Selecionar"
            />
          </div>

          <button
            style={{
              padding: 5,
              marginLeft: 10,
              borderRadius: 5,
              border: 0,
              width: 120,
              fontSize: 14,
              color: "#000",
              fontWeight: "500",
              cursor: "pointer",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
              backgroundColor: "#e8e8e9",
              fontFamily: "Avenir Next",
            }}
            onClick={() => setGestorQuery("")}
          >
            Limpar Filtro
          </button>
        </div>

        {/*  <DatePicker onChange={setStartDateQuery} value={startDateQuery} />
        <DatePicker onChange={setEndDateQuery} value={endDateQuery} /> */}
      </div>
      <div style={{ overflow: "scroll" }}>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 15,
            justifyContent: "space-evenly",
            width: "100%",
            marginTop: 15,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 150,
                maxWidth: 150,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Nome
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 200,
                maxWidth: 200,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Email
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 120,
                maxWidth: 120,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Telemóvel
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 180,
                maxWidth: 180,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Colaborador
            </td>

            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 120,
                maxWidth: 120,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Tipo de Contacto
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 120,
                maxWidth: 120,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Estado
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 120,
                maxWidth: 120,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data Prevista
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 150,
                maxWidth: 150,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de Realização
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 250,
                maxWidth: 250,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Observações
            </td>
          </tr>
        </table>
                
        {filteredAffairs.map((item, index) => (
          <div className="box" style={{ marginTop: -30 }} key={index}>
                       {" "}
            <Link
              style={{ textDecoration: "none" }}
              to={`/crm/${item.id}/edit`}
              state={{ item }}
            >
              <CrmCard key={item.key} value={item} />
            </Link>
                                                   
          </div>
        ))}
      </div>
          
    </div>
  );
};

export default Crm;
