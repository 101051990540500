import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Navigate, useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import AtividadeCard from "../components/cards/AtividadeCard";
import DatePicker from "react-date-picker";

function Notificacao(props) {
  const { notiId } = useParams();

  console.log(notiId);

  const navigate = useNavigate();

  const [observacoes, setObservacoes] = useState("");
  const [dataTermo, setDataTermo] = useState(null);
  const [dataCriacao, setDataCriacao] = useState(null);
  const [aba, setAba] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTarefa = async () => {
      const docRef = doc(db, "notificacoes", notiId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const atividadeData = docSnap.data();
        console.log(atividadeData);

        setObservacoes(atividadeData.observacoes);
        setDataTermo(atividadeData.dataTermo.toDate());
        setDataCriacao(atividadeData.dataCriacao.toDate());
        setAba(atividadeData.aba);
      } else {
        console.log("No such document!");
      }
      setLoading(false);
    };

    fetchTarefa();
  }, [notiId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, "notificacoes", notiId), {
        dataTermo: new Date(dataTermo),
        observacoes,
        aba,
      });
      console.log("Document Updated");
      navigate("/notificacoes");
    } catch (e) {
      console.log(e.message);
    }
  };

  const deleteNotificacao = async () => {
    const confirmDelete = window.confirm("Tem a certeza que quer eliminar?");

    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "notificacoes", notiId));
        navigate("/notificacoes");
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  const Categories = [
    { value: "Estado dos Processos", label: "Estados dos Processos" },
    { value: "Carteira de Condutor", label: "Carteira de Condutor" },
    { value: "Gerais", label: "Noticias Gerais" },
  ];

  if (loading) {
    return <h1 style={{ marginLeft: 300 }}>Loading...</h1>;
  }

  return (
    <div>
      <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
        Notificação
      </h1>
      <form onSubmit={handleSubmit}>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tbody>
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Corpo
              </td>
              <td>
                <input
                  maxLength={100}
                  style={{
                    width: "95%",
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    fontFamily: "Avenir Next",
                    fontSize: 14,
                    borderBottomColor: "rgb(33, 150, 243)",
                    backgroundColor: "transparent",
                  }}
                  onChange={(e) => setObservacoes(e.target.value)}
                  value={observacoes}
                  type="text"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tbody>
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Data de Criação
              </td>
              <td>
                <p
                  style={{
                    width: "95%",
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    fontFamily: "Avenir Next",
                    fontSize: 14,
                    borderBottomColor: "rgb(33, 150, 243)",
                    backgroundColor: "transparent",
                  }}
                >
                  {dataCriacao.toLocaleDateString("en-GB")}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tbody>
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Data de Termo
              </td>
              <td>
                <DatePicker
                  minDate={new Date()}
                  onChange={setDataTermo}
                  value={dataTermo}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tbody>
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Aba
              </td>
              <td>
                <p
                  style={{
                    width: "95%",
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    fontFamily: "Avenir Next",
                    fontSize: 14,
                    borderBottomColor: "rgb(33, 150, 243)",
                    backgroundColor: "transparent",
                  }}
                >
                  <Select
                    options={Categories}
                    value={Categories.find((option) => option.value === aba)}
                    onChange={(value) => setAba(value.value)}
                    placeholder="Selecionar"
                  />
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <button
          style={{
            backgroundColor: "#2196f3",
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
            marginLeft: 300,
          }}
          type="submit"
        >
          Guardar
        </button>
      </form>
      <button
        onClick={deleteNotificacao}
        style={{
          backgroundColor: "#f00",
          marginTop: -50,
          borderRadius: 5,
          border: 0,
          padding: 15,
          fontSize: 12,
          right: 0,
          position: "absolute",
          marginRight: 45,
          width: "100px",
          fontFamily: "Avenir Next",
          color: "#fff",
        }}
      >
        Apagar
      </button>
    </div>
  );
}

export default Notificacao;
