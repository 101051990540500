const fetchPlan = async (pacote, nrProcesso, data_fim) => {
  const url = new URL("https://servermultazero.onrender.com/getPlan");
  console.log(pacote);
  url.searchParams.append("pacote", JSON.stringify(pacote));
  url.searchParams.append("nrProcesso", nrProcesso);
  url.searchParams.append("data_fim", data_fim);

  try {
    const response = await fetch(url.toString());
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export { fetchPlan };
