import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import AtividadeCard from "../components/cards/AtividadeCard";
import UtilizadorCard from "../components/cards/UtilizadorCard";

function Empresa(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { item } = location.state;

  const [nome, setNome] = useState(item[0].nome);
  const [morada, setMorada] = useState(item[0].morada);
  const [postCode, setPostCode] = useState(item[0].postCode);
  const [pais, setPais] = useState(item[0].pais);
  const [telefone, setTelefone] = useState(item[0].telefone);
  const [email, setEmail] = useState(item[0].email);
  const [gestor, setGestor] = useState(item[0].gestor);

  const [contribuinte, setContribuinte] = useState(item[0].contribuinte);
  const [cai, setCai] = useState(item[0].cai);
  const [nipc, setNipc] = useState(item[0].nipc);
  const [codigo_protocolo, setCodigo_protocolo] = useState(
    item[0].codigo_protocolo
  );
  const [desconto, setDesconto] = useState(item[0].desconto);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateDoc(doc(db, "empresas", item[1].id), {
        nome,
        morada,
        postCode,
        pais,
        telefone,
        email,
        gestor,
        contribuinte,
        cai,

        codigo_protocolo,
        desconto,
      });
      console.log("Document Added");
      alert("Informações guardadas com sucesso.");

      navigate("/gestaoempresas");
    } catch (e) {
      console.log(e.message);
    }
  };

  const deleteEmpresa = async () => {
    try {
      await deleteDoc(doc(db, "empresas", item[1].id));
      navigate("/gestaoempresas");
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>Empresa</h1>

      <form onSubmit={handleSubmit}>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Nome
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setNome(e.target.value)}
              value={nome}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Morada
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setMorada(e.target.value)}
              value={morada}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Código Postal
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setPostCode(e.target.value)}
              value={postCode}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              País
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setPais(e.target.value)}
              value={pais}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Telefone
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setTelefone(e.target.value)}
              value={telefone}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Email
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Gestor
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setGestor(e.target.value)}
              value={gestor}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              NIPC
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setContribuinte(e.target.value)}
              value={contribuinte}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              CAE
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setCai(e.target.value)}
              value={cai}
              type="text"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Código de Protocolo
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setCodigo_protocolo(e.target.value)}
              value={codigo_protocolo}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Desconto
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setDesconto(e.target.value)}
              value={desconto}
              type="text"
            />
          </tr>
        </table>

        <button
          style={{
            backgroundColor: "#2196f3",
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
            marginLeft: 300,
          }}
          type="text"
        >
          Guardar
        </button>
        {/* <button
          onClick={deleteEmpresa}
          style={{
            backgroundColor: "#f00",
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            position: "absolute",
          }}
        >
          Apagar
        </button> */}
      </form>
      <button
        onClick={deleteEmpresa}
        style={{
          backgroundColor: "#f00",
          position: "absolute",
          marginTop: -46,
          borderRadius: 5,
          border: 0,
          padding: 15,
          fontSize: 12,
          right: 0,
          marginRight: 40,
          width: "100px",
          fontFamily: "Avenir Next",
          color: "#fff",
          right: 0,
          marginLeft: 300,
        }}
      >
        Apagar
      </button>
    </div>
  );
}

export default Empresa;
