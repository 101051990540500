import React, { useState, useEffect } from "react";

import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import CrmCard from "../components/cards/CrmCard";

import AtividadeCard from "../components/cards/AtividadeCard";
import ProcessoCard3 from "../components/cards/ProcessoCard3";
import ReuniaoCard2 from "../components/cards/ReuniaoCard2";
import { Link } from "react-router-dom";
import PagamentoCard2 from "../components/cards/PagamentoCard2";

const PainelBordo = () => {
  const [docs, setDocs] = useState([]);
  const [atividades, setAtividades] = useState([]);
  const [affairs, setAffairs] = useState([]);
  const [reunioes, setReunioes] = useState([]);

  useEffect(() => {
    const fetchDocs = async () => {
      try {
        const q = query(
          collection(db, "crm"),
          orderBy("createdAt", "desc"),
          limit(5)
        );
        const querySnapshot = await getDocs(q);
        const documents = [];

        for (const docRef of querySnapshot.docs) {
          const docData = docRef.data();

          const gestorId = docData.gestorId;

          console.log("entrou");

          if (gestorId) {
            console.log("entrou2");

            const subscriberRef2 = doc(db, "administradores", gestorId);

            const subscriberSnapshot2 = await getDoc(subscriberRef2);

            if (subscriberSnapshot2.exists()) {
              console.log("entrou3");

              const modifiedDocData = {
                ...docData,
                gestor: {
                  ...subscriberSnapshot2.data(),
                  id: subscriberSnapshot2.id,
                },
              };
              documents.push({ id: docRef.id, ...modifiedDocData });
            }
          }
        }

        setDocs(documents);
        console.log(documents);
      } catch (e) {
        console.log(e.message);
      }
    };
    const fetchAtividades = async () => {
      try {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const q1 = query(
          collection(db, "atividades"),
          where("dataPrevA", ">=", currentDate),
          orderBy("dataPrevA", "asc")
        );
        const q2 = query(
          collection(db, "atividades"),
          where("dataPrevA", "<", currentDate),
          orderBy("dataPrevA", "desc")
        );

        const querySnapshot1 = await getDocs(q1);
        const querySnapshot2 = await getDocs(q2);

        const docList1 = await Promise.all(
          querySnapshot1.docs.map(async (docu) => {
            const userDataRef = docu.data().user;
            const gestorDataRef = docu.data().admin;
            const userSnapshot = await getDoc(doc(db, "users", userDataRef));
            const userData = userSnapshot.data();
            const gestorSnapshot = await getDoc(
              doc(db, "administradores", gestorDataRef)
            );
            const gestorData = gestorSnapshot.data();
            return {
              ...docu.data(),
              id: docu.id,
              user: userData,
              gestor: gestorData,
            };
          })
        );

        const docList2 = await Promise.all(
          querySnapshot2.docs.map(async (docu) => {
            const userDataRef = docu.data().user;
            const gestorDataRef = docu.data().admin;
            const userSnapshot = await getDoc(doc(db, "users", userDataRef));
            const userData = userSnapshot.data();
            const gestorSnapshot = await getDoc(
              doc(db, "administradores", gestorDataRef)
            );
            const gestorData = gestorSnapshot.data();
            return {
              ...docu.data(),
              id: docu.id,
              user: userData,
              gestor: gestorData,
            };
          })
        );

        const combinedDocList = docList1.concat(docList2);

        setAtividades(combinedDocList);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchedAffairs = async () => {
      try {
        const affairsCollection = collection(db, "processos");
        const q = query(
          affairsCollection,
          orderBy("dataCriacao", "desc"),
          limit(5)
        );
        const affairsSnapshot = await getDocs(q);

        const fetchedAffairs = [];

        for (const docSnapshot of affairsSnapshot.docs) {
          const affair = docSnapshot.data();
          const affairId = docSnapshot.id;
          const userId = affair.userId;

          const userDocRef = doc(db, "users", userId);
          const userDocSnapshot = await getDoc(userDocRef);
          const userData = {
            ...userDocSnapshot.data(),
            id: userDocSnapshot.id,
          };
          const gestorId = userData.gestorId;

          const gestorDocRef = doc(db, "administradores", gestorId);
          const gestorDocSnapshot = await getDoc(gestorDocRef);
          const gestorData = gestorDocSnapshot.data();

          const processedAffair = {
            id: affairId,
            user: {
              ...userData,
              gestor: gestorData,
            },
            ...affair,
          };

          fetchedAffairs.push(processedAffair);
        }

        setAffairs(fetchedAffairs);
      } catch (e) {
        console.log(e.message);
      }
    };
    const fetchReunioes = async () => {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const q1 = query(
        collection(db, "reunioes"),
        where("meetingSlot", ">=", currentDate),
        orderBy("meetingSlot", "asc")
      );
      const q2 = query(
        collection(db, "reunioes"),
        where("meetingSlot", "<", currentDate),
        orderBy("meetingSlot", "desc")
      );

      const reunioesSnapshot1 = await getDocs(q1);
      const reunioesSnapshot2 = await getDocs(q2);

      const fetchedReunioes = [];

      for (const docSnapshot of reunioesSnapshot1.docs) {
        const reuniao = docSnapshot.data();
        const reuniaoId = docSnapshot.id;
        const userId = reuniao.userId;

        const userDocRef = doc(db, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);
        const userData = userDocSnapshot.data();
        const gestorId = reuniao.admin;

        const gestorDocRef = doc(db, "administradores", gestorId);
        const gestorDocSnapshot = await getDoc(gestorDocRef);
        const gestorData = gestorDocSnapshot.data();

        const processedReuniao = {
          id: reuniaoId,
          user: {
            ...userData,
            gestor: gestorData,
          },
          ...reuniao,
        };

        fetchedReunioes.push(processedReuniao);
      }

      for (const docSnapshot of reunioesSnapshot2.docs) {
        const reuniao = docSnapshot.data();
        const reuniaoId = docSnapshot.id;
        const userId = reuniao.userId;

        const userDocRef = doc(db, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);
        const userData = userDocSnapshot.data();
        const gestorId = reuniao.admin;

        const gestorDocRef = doc(db, "administradores", gestorId);
        const gestorDocSnapshot = await getDoc(gestorDocRef);
        const gestorData = gestorDocSnapshot.data();

        const processedReuniao = {
          id: reuniaoId,
          user: {
            ...userData,
            gestor: gestorData,
          },
          ...reuniao,
        };

        fetchedReunioes.push(processedReuniao);
      }

      setReunioes(fetchedReunioes);
    };

    fetchDocs();
    fetchAtividades();
    fetchedAffairs();
    fetchReunioes();
    fetchAdmins();
    fetchAdmins2();
  }, []);

  const [estadoQuery, setEstadoQuery] = useState("");
  const [estadoQuery2, setEstadoQuery2] = useState("");
  const [estadoQuery3, setEstadoQuery3] = useState("");
  const [estadoQuery4, setEstadoQuery4] = useState("");
  const [estadoQuery5, setEstadoQuery5] = useState("");

  const [gestorQuery, setGestorQuery] = useState("");
  const [gestorQuery2, setGestorQuery2] = useState("");
  const [gestorQuery3, setGestorQuery3] = useState("");
  const [gestorQuery4, setGestorQuery4] = useState("");
  const [gestorQuery5, setGestorQuery5] = useState("");

  const [userQuery, setUserQuery] = useState("");
  const [userQuery2, setUserQuery2] = useState("");
  const [userQuery3, setUserQuery3] = useState("");
  const [userQuery4, setUserQuery4] = useState("");
  const [userQuery5, setUserQuery5] = useState("");

  const [admins, setAdmins] = useState([]);
  const [admins2, setAdmins2] = useState([]);

  const fetchAdmins = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "administradores"));
      const docList = [];
      setAdmins([]);
      querySnapshot.docs.map((doc) =>
        docList.push({
          value: doc.id,
          label: doc.data().fname + " " + doc.data().lname,
        })
      );
      setAdmins(docList);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAdmins2 = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const docList = [];
      setAdmins2([]);
      querySnapshot.docs.map((doc) =>
        docList.push({
          value: doc.id,
          label: doc.data().fname + " " + doc.data().lname,
        })
      );
      setAdmins2(docList);
    } catch (error) {
      console.log(error);
    }
  };

  const filteredrefmb = affairs.filter((item) => {
    return item.estado === "Por pagar";
  });

  return (
    <div style={{ marginLeft: 300 }}>
      <h1 style={{ fontFamily: "Avenir Next" }}>Painel de Bordo</h1>

      <h3 style={{ fontFamily: "Avenir Next" }}>CRM - últimos 5</h3>
      <div style={{ overflow: "scroll" }}>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 15,
            justifyContent: "space-evenly",
            width: "100%",
            marginTop: 15,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 150,
                maxWidth: 150,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Nome
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 200,
                maxWidth: 200,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Email
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 120,
                maxWidth: 120,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Telemóvel
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 180,
                maxWidth: 180,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Colaborador
            </td>

            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 120,
                maxWidth: 120,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Tipo de Contacto
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 120,
                maxWidth: 120,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Estado
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 120,
                maxWidth: 120,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data Prevista
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 150,
                maxWidth: 150,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de Realização
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                minWidth: 250,
                maxWidth: 250,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Observações
            </td>
          </tr>
        </table>
        {docs.map((doc) => (
          <CrmCard key={doc.id} value={doc} />
        ))}
      </div>
      <h3 style={{ fontFamily: "Avenir Next" }}>Tarefas - últimas 5</h3>
      <table
        style={{
          backgroundColor: "#e8e8e9",
          paddingBottom: 10,
          marginTop: 10,
          paddingTop: 10,
          paddingLeft: 25,
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <tr style={{ borderRightWidth: 1 }}>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 150,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Processo
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Colaborador
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Cliente
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Estado
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Observações
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Data de Realização
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Data Prevista
          </td>
        </tr>
      </table>

      {atividades.slice(0, 5).map((item, index) => (
        <div className="box" key={item.id}>
          <Link
            style={{ textDecoration: "none" }}
            to={`/tarefas/${item.id}`}
            state={{ item }}
          >
            <AtividadeCard key={item.id} value={item} />
          </Link>
        </div>
      ))}
      <h3 style={{ fontFamily: "Avenir Next" }}>Processos - últimos 5</h3>
      <table
        style={{
          backgroundColor: "#e8e8e9",
          paddingBottom: 10,
          paddingTop: 10,
          paddingLeft: 10,
          justifyContent: "space-evenly",
          width: "100%",
          paddingRight: 20,
        }}
      >
        <tr style={{ borderRightWidth: 1 }}>
          <td
            style={{
              borderLeftWidth: 1,
              borderLeftColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Nº
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 150,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Data de Solicitação
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Estado
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Cliente
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            NIF
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Advogado Responsável
          </td>
        </tr>
      </table>

      {affairs.map((item) => (
        <div className="box" key={item.id}>
          <Link
            style={{ textDecoration: "none" }}
            to={`/assuntos/${item.id}`}
            state={{ item }}
          >
            <ProcessoCard3 key={item.key} value={item} />
          </Link>
        </div>
      ))}
      <h3 style={{ fontFamily: "Avenir Next" }}>Reuniões - últimas 5</h3>
      <table
        style={{
          backgroundColor: "#e8e8e9",
          marginTop: 15,
          paddingBottom: 10,
          paddingTop: 10,
          paddingLeft: 10,
          justifyContent: "space-evenly",
          width: "100%",
          paddingRight: 20,
        }}
      >
        <tr style={{ borderRightWidth: 1 }}>
          <td
            style={{
              borderLeftWidth: 1,
              borderLeftColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Cliente
          </td>
          <td
            style={{
              borderLeftWidth: 1,
              borderLeftColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Contacto
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 150,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Data
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 80,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Hora
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Gestor
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 300,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Estado
          </td>
        </tr>
      </table>
      {reunioes.slice(0, 5).map((reuniao, index) => (
        <div className="box" key={index}>
          <Link
            style={{ textDecoration: "none" }}
            to={`/reunioes/${reuniao.id}`}
          >
            <ReuniaoCard2 key={index} value={reuniao} />
          </Link>
        </div>
      ))}
      <h3 style={{ fontFamily: "Avenir Next" }}>Ref MB - últimas 5</h3>
      <table
        style={{
          backgroundColor: "#e8e8e9",
          paddingBottom: 10,
          paddingTop: 10,
          paddingLeft: 10,
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <tr style={{}}>
          <td
            style={{
              borderColor: "#000",
              width: 120,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            ID
          </td>
          <td
            style={{
              borderColor: "#000",
              width: 300,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Infração
          </td>
          <td
            style={{
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Gestor do Processo
          </td>

          <td
            style={{
              borderColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Referência
          </td>
          <td
            style={{
              borderColor: "#000",
              width: 150,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Plano
          </td>
          <td
            style={{
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Cliente
          </td>
          <td
            style={{
              borderColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            NIF
          </td>
          <td
            style={{
              borderColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Preço
          </td>

          <td
            style={{
              borderColor: "#000",
              width: 120,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Estado
          </td>
          <td
            style={{
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Data de Pagamento
          </td>
        </tr>
      </table>
      {filteredrefmb.map((pagamento, index) => (
        <div className="box" key={index}>
          <Link
            style={{ textDecoration: "none" }}
            to={`/assuntos/${pagamento.id}`}
            state={{ pagamento }}
          >
            <PagamentoCard2 key={index} value={pagamento} />
          </Link>
        </div>
      ))}
    </div>
  );
};

export default PainelBordo;
