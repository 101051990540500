import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  orderBy,
  serverTimestamp,
  addDoc,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import { db } from "../firebase";
import ProcessoCard2 from "../components/cards/ProcessoCard2";
import CarteiraCard from "../components/cards/CarteiraCard";
import AtividadeCard2 from "../components/cards/AtividadeCard2";
import ReuniaoCard from "../components/cards/ReuniaoCard";
import CrmCard2 from "../components/cards/CrmCard2";

import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  getMetadata,
  deleteObject,
} from "firebase/storage";
import { UserAuth } from "../context/AuthContext";

function Subscritor(props) {
  const [client, setClient] = useState(null);
  const [processos, setProcessos] = useState([]);
  const [carteiras, setCarteiras] = useState([]);
  const [atividades, setAtividades] = useState([]);
  const [reunioes, setReunioes] = useState([]);
  const [crms, setCrms] = useState([]);

  const { user } = UserAuth();
  const { clientId } = useParams();

  const fetchClientData = async () => {
    try {
      const userDocRef = doc(db, "users", clientId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const clientIdWithId = { ...userData, userId: userDoc.id };

        // Check if gestorId exists in user data
        if (userData.gestorId) {
          const gestorDocRef = doc(db, "administradores", userData.gestorId);
          const gestorDoc = await getDoc(gestorDocRef);

          if (gestorDoc.exists()) {
            const gestorData = gestorDoc.data();
            clientIdWithId.gestor = { ...gestorData, id: gestorDoc.id }; // Add gestor data to the client data
          } else {
            throw new Error("Gestor not found");
          }
        }

        setClient(clientIdWithId);
        fetchFileNames(clientIdWithId.files);
      } else {
        throw new Error("User not found");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  };

  const [fileNames, setFileNames] = useState([]);

  const fetchFileNames = async (files) => {
    const storage = getStorage();
    const names = await Promise.all(
      files.map(async (file) => {
        const fileRef = ref(storage, file);
        const metadata = await getMetadata(fileRef);
        return metadata.name || "Unnamed File";
      })
    );
    setFileNames(names);
  };

  const fetchProcessos = async () => {
    try {
      const processosRef = collection(db, "processos");
      const q = query(processosRef, where("userId", "==", clientId));
      const querySnapshot = await getDocs(q);
      const docList = [];
      setProcessos([]);
      querySnapshot.docs.map((doc) =>
        docList.push({ ...doc.data(), id: doc.id })
      );
      setProcessos(docList);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReunioes = async () => {
    const affairsCollection = collection(db, "reunioes");
    const affairsSnapshot = await getDocs(affairsCollection);

    const fetchedAffairs = [];

    for (const docSnapshot of affairsSnapshot.docs) {
      const affair = docSnapshot.data();
      const affairId = docSnapshot.id;
      const userId = affair.userId;
      if (userId == clientId) {
        const userDocRef = doc(db, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);
        const userData = userDocSnapshot.data();
        const gestorId = userData.gestorId;

        const gestorDocRef = doc(db, "administradores", gestorId);
        const gestorDocSnapshot = await getDoc(gestorDocRef);
        const gestorData = gestorDocSnapshot.data();

        const processedAffair = {
          id: affairId,
          user: {
            ...userData,
            gestor: gestorData,
          },
          ...affair,
        };

        fetchedAffairs.push(processedAffair);
      }
    }

    setReunioes(fetchedAffairs);
  };

  const fetchAtividades = async () => {
    const q = query(
      collection(db, "atividades"),
      where("user", "==", clientId),
      orderBy("createdAt", "desc")
    );
    const querySnapshot = await getDocs(q);
    const atividadesData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setAtividades(atividadesData);
  };

  const fetchCarteiras = async () => {
    try {
      const carteirasRef = collection(db, "matriculas");
      const q = query(carteirasRef, where("uid", "==", clientId));
      const querySnapshot = await getDocs(q);
      const docList = [];
      setCarteiras([]);
      querySnapshot.docs.map((doc) =>
        docList.push({ ...doc.data(), id: doc.id })
      );
      setCarteiras(docList);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchDocs = async () => {
    try {
      const q = query(collection(db, "crm"), orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);
      const documents = [];

      for (const docRef of querySnapshot.docs) {
        const docData = docRef.data();
        const subscriberId = docData.subscritor;
        const gestorId = docData.admin;
        const affairId = docData.processo;

        if (subscriberId && gestorId && affairId) {
          const subscriberRef = doc(db, "users", subscriberId);
          const subscriberRef2 = doc(db, "administradores", gestorId);
          const subscriberRef3 = doc(db, "processos", affairId);
          const subscriberSnapshot = await getDoc(subscriberRef);
          const subscriberSnapshot2 = await getDoc(subscriberRef2);
          const subscriberSnapshot3 = await getDoc(subscriberRef3);

          if (
            subscriberSnapshot.exists() &&
            subscriberSnapshot2.exists() &&
            subscriberSnapshot3.exists()
          ) {
            const subscriberData = {
              ...subscriberSnapshot.data(),
              id: subscriberSnapshot.id,
            };
            const subscriberData2 = {
              ...subscriberData,
              gestor: {
                ...subscriberSnapshot2.data(),
                id: subscriberSnapshot2.id,
              },
            };

            const modifiedDocData = {
              ...docData,
              subscriber: subscriberData2,
              processo: subscriberSnapshot3.id,
              id: docRef.id,
            };
            if (subscriberData.id === clientId) {
              documents.push({ id: docRef.id, ...modifiedDocData });
            }
          }
        }
      }

      setCrms(documents);
    } catch (e) {
      console.log(e.message);
    }
  };

  const addToCRM = async () => {
    try {
      const dbRef = collection(db, "crm");
      addDoc(dbRef, {
        fname: client.fname,
        lname: client.lname,
        email: client.email,
        phone: client.phone,
        gestorId: client.gestor.id,
        tipo: "",
        dataPrev: null,
        dataRea: null,

        feito: "",
        observacoes: "",
        createdAt: serverTimestamp(),
      }).then((docRef) => {
        console.log("Document has been added successfully");
      });
      console.log("Document Added");
      fetchDocs();
    } catch (e) {
      console.log(e.message);
    }
  };

  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [loading4, setLoading4] = useState(true);
  const [loading5, setLoading5] = useState(true);
  const [loading6, setLoading6] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchClientData();
        setLoading(false);
        await fetchProcessos();
        setLoading2(false);
        await fetchReunioes();
        setLoading3(false);
        await fetchAtividades();
        setLoading4(false);
        await fetchCarteiras();
        setLoading5(false);
        await fetchDocs();
        setLoading6(false);
        /*
        await fetchCarteiras(); */
      } catch (error) {
        console.error("Error fetching data:", error);

        setLoading(false);
        setLoading2(false);
        setLoading3(false);
        setLoading4(false);
        setLoading5(false);
        setLoading6(false);
      }
    };

    fetchData();
  }, []);

  function getClientStatus(client) {
    let result;

    if (!client.isMotorista && !client.isProtocolo) {
      result = "Normal";
    } else if (!client.isMotorista && client.isProtocolo) {
      result = "Com Protocolo";
    } else if (client.isMotorista && !client.isProtocolo) {
      result = "Motorista";
    } else {
      result = "Motorista com Protocolo";
    }

    return result;
  }

  return (
    <div>
      {loading ? (
        <p>carregando...</p>
      ) : (
        <>
          <div>
            <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
              Ficha de Cliente
            </h1>
            <Link
              style={{ textDecoration: "none" }}
              to={`/clientes/${client.userId}/edit`}
            >
              <button
                style={{
                  marginLeft: 300,
                  marginTop: -15,
                  marginBottom: 30,
                  padding: 10,
                  borderRadius: 5,
                  border: 0,
                  width: 120,
                  fontSize: 14,
                  color: "#000",
                  fontWeight: "500",
                  cursor: "pointer",
                  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                  backgroundColor: "#e8e8e9",
                  fontFamily: "Avenir Next",
                }}
              >
                Editar
              </button>
            </Link>
            {client.hasPayed == false && (
              <button
                style={{
                  marginLeft: 15,
                  marginTop: -15,
                  marginBottom: 30,
                  padding: 10,
                  borderRadius: 5,
                  border: 0,
                  width: 160,
                  fontSize: 14,
                  color: "#000",
                  fontWeight: "500",
                  cursor: "pointer",
                  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                  backgroundColor: "#2196f3",
                  color: "#fff",
                  fontFamily: "Avenir Next",
                }}
                onClick={async () => await addToCRM()}
              >
                Adicionar ao CRM
              </button>
            )}
            <table
              style={{
                backgroundColor: "#fff",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Nome
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {client.fname} {client.lname}
                </td>
              </tr>
            </table>

            <table
              style={{
                backgroundColor: "#e8e8e9",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Email
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {client.email}
                </td>
              </tr>
            </table>

            <table
              style={{
                backgroundColor: "#fff",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Tipo
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {getClientStatus(client)}
                </td>
              </tr>
            </table>

            <table
              style={{
                backgroundColor: "#e8e8e9",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Telemóvel
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {client.phone}
                </td>
              </tr>
            </table>

            <table
              style={{
                backgroundColor: "#fff",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  País
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {client.pais !== null && client.pais !== ""
                    ? client.pais
                    : "por definir"}
                </td>
              </tr>
            </table>

            <table
              style={{
                backgroundColor: "#e8e8e9",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Freguesia
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {client.freguesia !== null && client.freguesia !== ""
                    ? client.freguesia
                    : "por definir"}
                </td>
              </tr>
            </table>

            <table
              style={{
                backgroundColor: "#fff",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Concelho
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {client.concelho !== null && client.concelho !== ""
                    ? client.concelho
                    : "por definir"}
                </td>
              </tr>
            </table>

            <table
              style={{
                backgroundColor: "#e8e8e9",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Morada
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {client.morada !== null && client.morada !== ""
                    ? client.morada
                    : "por definir"}
                </td>
              </tr>
            </table>

            <table
              style={{
                backgroundColor: "#fff",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Ficheiros
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  <div>
                    {fileNames.map((fileName, index) => (
                      <div key={index}>
                        <p>{fileName}</p>
                        <button
                          type="button"
                          onClick={() =>
                            window.open(client.files[index], "_blank")
                          }
                        >
                          Abrir
                        </button>
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            </table>

            <table
              style={{
                backgroundColor: "#e8e8e9",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  NIF
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {client.nif !== null && client.nif !== ""
                    ? client.nif
                    : "por definir"}
                </td>
              </tr>
            </table>

            <table
              style={{
                backgroundColor: "#fff",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Gestor de cliente
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {client.gestor.fname} {client.gestor.lname}
                </td>
              </tr>
            </table>

            <table
              style={{
                backgroundColor: "#e8e8e9",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Observações
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {client.observacoes !== null && client.observacoes !== ""
                    ? client.observacoes
                    : "por definir"}
                </td>
              </tr>
            </table>

            <table
              style={{
                backgroundColor: "#fff",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Desconto
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {client.desconto !== null && client.desconto !== ""
                    ? client.desconto
                    : "por definir"}
                </td>
              </tr>
            </table>
          </div>

          <div>
            <h1 style={{ fontFamily: "Avenir Next", marginLeft: 300 }}>
              Registo Individual do Condutor
            </h1>
            <table
              style={{
                backgroundColor: "#fff",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Pontos
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {client.pontos == "" ? "por definir" : client.pontos}
                </td>
              </tr>
            </table>

            <table
              style={{
                backgroundColor: "#e8e8e9",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Infrações graves
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {client.inf_graves == "" ? "por definir" : client.inf_graves}
                </td>
              </tr>
            </table>

            <table
              style={{
                backgroundColor: "#fff",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Infrações muito graves
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {client.inf_mtgraves == ""
                    ? "por definir"
                    : client.inf_mtgraves}
                </td>
              </tr>
            </table>

            <table
              style={{
                backgroundColor: "#e8e8e9",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Crimes
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {client.crimes == "" ? "por definir" : client.crimes}
                </td>
              </tr>
            </table>

            <table
              style={{
                backgroundColor: "#fff",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: "70%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Sanções
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  {client.sancoes == "" ? "por definir" : client.sancoes}
                </td>
              </tr>
            </table>
          </div>
        </>
      )}

      {loading2 ? (
        <p>carregando...</p>
      ) : (
        <div>
          <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
            Processos
          </h1>
          <table
            style={{
              backgroundColor: "#e8e8e9",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: 100,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                ID
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: 150,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Data de Criação
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: 200,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Tipo de Infração
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: 250,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Estado do Processo
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: 100,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Gestor do Cliente
              </td>
            </tr>
          </table>
          {processos.map((item) => (
            <Link
              style={{ textDecoration: "none" }}
              to={`/assuntos/${item.id}`}
              state={{ item }}
            >
              <ProcessoCard2 key={item.id} value={{ item, client }} />
            </Link>
          ))}
        </div>
      )}
      {loading3 ? (
        <p>carregando...</p>
      ) : (
        <div>
          <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
            Tarefas
          </h1>
          <table
            style={{
              backgroundColor: "#e8e8e9",
              paddingBottom: 10,
              marginTop: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: 150,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Assunto
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: 200,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Colaborador
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: 250,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Observações
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: 200,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Data de Conclusão
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: 200,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Data Prevista
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: 0,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              ></td>
            </tr>
          </table>
          {atividades.map((item) => (
            <Link
              style={{ textDecoration: "none" }}
              to={`/assuntos/${item.id}`}
              state={{ item }}
            >
              <AtividadeCard2 key={item.id} value={{ item, client }} />
            </Link>
          ))}
        </div>
      )}
      {loading4 ? (
        <p>carregando...</p>
      ) : (
        <>
          <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
            Reuniões
          </h1>
          <table
            style={{
              backgroundColor: "#e8e8e9",
              marginTop: 15,
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: 250,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Cliente
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: 100,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Telemóvel
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: 150,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Data
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: 150,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Hora
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: 300,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Gestor
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: 300,
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Estado
              </td>
            </tr>
          </table>
          {reunioes.map((reuniao, index) => (
            <div className="box" key={index}>
              <Link
                style={{ textDecoration: "none" }}
                to={`/reunioes/${reuniao.id}`}
              >
                <ReuniaoCard key={index} value={reuniao} />
              </Link>
            </div>
          ))}
        </>
      )}

      <div style={{ marginBottom: 300 }}>
        {loading5 ? (
          <p>carregando...</p>
        ) : (
          <>
            <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
              Carteiras
            </h1>
            <table
              style={{
                backgroundColor: "#e8e8e9",
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 300,
                justifyContent: "space-evenly",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <tr style={{ borderRightWidth: 1 }}>
                <td
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "#000",
                    width: 100,
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Matricula
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: 150,
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Marca do Veículo
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: 200,
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Modelo
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: 250,
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Data de Compra
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: 100,
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Data de Venda
                </td>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: 250,
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  Documentos Configurados (max: 5)
                </td>
              </tr>
            </table>
            {carteiras.map((item, index) => (
              <Link
                style={{ textDecoration: "none" }}
                to={`/clientes/${clientId}/carteira/${item.id}`}
                state={{ item }}
              >
                <CarteiraCard key={item.id} value={{ item, client }} />
              </Link>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default Subscritor;
