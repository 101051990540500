import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  setDoc,
  Timestamp,
  serverTimestamp,
  updateDoc,
  increment,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { db } from "../firebase";
import {
  entOptions,
  veicOptions,
  prazoOptions,
  pagouCoimaOptions,
} from "../libs/options";
import { distritos, concelhos, tribunais } from "../libs/courts";
import DatePicker from "react-date-picker";
import { useNavigate } from "react-router-dom";

import { fetchPlan } from "../utils/euPago";
const AddProcesso = () => {
  const [nrProcesso, setNrProcesso] = useState("");
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const [entidade, setEntidade] = useState("");
  const [tipo_de_veiculo, setTipo_de_veiculo] = useState("");
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);

  const [distritosOptions, setDistritosOptions] = useState([]);
  const [concelhosOptions, setConcelhosOptions] = useState([]);
  const [distrito, setDistrito] = useState("");
  const [concelho, setConcelho] = useState("");
  const [tribunal, setTribunal] = useState("");
  const [dateNotifi, setDateNotifi] = useState(new Date());
  const [dateInfra, setDateInfra] = useState(new Date());
  const [prazo, setPrazo] = useState("");
  const [pagouCoima, setPagouCoima] = useState("");
  const [dateTri, setDateTri] = useState(null);
  const [envolveu_em_acidente, setEnvolveu_em_acidente] = useState(false);
  const [infracoesOptions, setInfracoesOptions] = useState([]);
  const [infracao, setInfracao] = useState(null);
  const [plano, setPlano] = useState(null);
  const [isInvoice, setIsInvoice] = useState(false);
  const [userNif, setUserNif] = useState("");
  const [userMorada, setUserMorada] = useState("");
  const [userPhone, setUserPhone] = useState("");

  const [invoiceName, setInvoiceName] = useState("");
  const [invoiceNif, setInvoiceNif] = useState("");
  const [invoiceMorada, setInvoiceMorada] = useState("");
  const [eupagoResponse, setEupagoResponse] = useState(null);
  const [invoiceData, setInvoiceData] = useState({
    nome: "Consumidor Final",
    nif: "999999990",
    morada: "Não especificado",
  });

  const [honorarios, setHonorarios] = useState(null);

  const navigate = useNavigate();

  const fetchNrProcesso = async () => {
    try {
      const docRef = doc(db, "global", "variables");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setNrProcesso(
          "MZ" +
            docSnap.data().number +
            "-" +
            new Date().getFullYear(docSnap.data().honorarios)
        );
        setHonorarios(docSnap.data().honorarios);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const docList = [];
      setUsers([]);
      querySnapshot.docs.map((doc) => {
        docList.push({
          value: { ...doc.data(), id: doc.id },
          label: doc.data().email,
        });
      });
      setUsers(docList);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchInfracoes = async () => {
    const docRef = doc(db, "global", "infracoes");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const infracoesData = docSnap.data().infracoes;

      const options = infracoesData.map((infracao) => ({
        value: infracao,
        label: infracao.infracao,
      }));

      setInfracoesOptions(options);
    } else {
      console.log("No such document!");
    }
  };

  useEffect(() => {
    fetchNrProcesso();
    fetchUsers();
    fetchInfracoes();
  }, []);

  useEffect(() => {
    const fetchOptions = () => {
      const options = distritos.map((distrito) => ({
        value: distrito,
        label: distrito,
      }));

      const options2 = concelhos.map((concelhoGroup) =>
        concelhoGroup.map((concelho) => ({ value: concelho, label: concelho }))
      );

      setDistritosOptions(options);
      setConcelhosOptions(options2);
    };

    fetchOptions();
  }, []);

  function addDaysToCurrentDate(daysToAdd) {
    var currentDate = new Date();
    var futureDate = new Date(currentDate);
    var addedDays = 0;

    while (addedDays < daysToAdd) {
      futureDate.setDate(futureDate.getDate() + 1);
      if (futureDate.getDay() !== 0 && futureDate.getDay() !== 6) {
        addedDays++;
      }
    }

    var year = futureDate.getFullYear();
    var month = (futureDate.getMonth() + 1).toString().padStart(2, "0");
    var day = futureDate.getDate().toString().padStart(2, "0");

    var formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }

  const [loadingReference, setLoadingReference] = useState(false);

  const getPlan = async (plano, nrProcesso) => {
    try {
      setLoadingReference(true);
      const data_fim = addDaysToCurrentDate(30);
      console.log(plano);
      const response = await fetchPlan(plano, nrProcesso, data_fim);
      setEupagoResponse(response);
      setLoadingReference(false);
    } catch (error) {
      console.log(error);
      // Handle error if needed
    }
  };

  const toggleSwitchFat = () => {
    if (isInvoice == false) {
      setInvoiceData({
        ...invoiceData,
        user: invoiceName,
        nif: invoiceNif,
        morada: invoiceMorada,
      });
      setIsInvoice(true);
      return;
    }
    setInvoiceData({
      ...invoiceData,
      user: "Consumidor Final",
      nif: "999999990",
      morada: "Não Especificado",
    });
    setIsInvoice(false);
  };

  const handlePhotoSaving = async (image, verso) => {
    try {
      const childPath = `Anexos/${userId}/${verso}/${nrProcesso}`;
      const storage = getStorage();
      const storageRef = ref(storage, childPath);
      const uploadTask = uploadBytesResumable(storageRef, image);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Handle progress if needed
          },
          (error) => {
            console.log("Error uploading image:", error);
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(storageRef);
              resolve(downloadURL);
            } catch (error) {
              console.log("Error getting download URL:", error);
              reject(error);
            }
          }
        );
      });
    } catch (error) {
      console.log("Error handling photo saving:", error);
      throw error;
    }
  };

  const sendEmail = async () => {
    const url = new URL(
      "https://servermultazero-v1vk.onrender.com/sendAffairCreationEmail"
    );
    const name = userId.fname + " " + userId.lname;

    url.searchParams.append("nrProcesso", nrProcesso);
    url.searchParams.append("name", name);
    url.searchParams.append("email", userId.email);
    url.searchParams.append("entidade", eupagoResponse.entidade);
    url.searchParams.append("referencia", eupagoResponse.referencia);
    url.searchParams.append(
      "price",
      parseFloat(eupagoResponse.valor).toFixed(2).replace(".", ",")
    );
    url.searchParams.append("data_limite", eupagoResponse.data_fim);

    try {
      const response = await fetch(url.toString());
      const data = await response.json();

      return;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const saveProcess = async (e) => {
    e.preventDefault();
    try {
      const variablesDocRef = doc(collection(db, "global"), "variables");
      await updateDoc(variablesDocRef, {
        number: increment(1),
      });

      let imageURL1 = null;
      let imageURL2 = null;

      if (image1) {
        imageURL1 = await handlePhotoSaving(image1, "frente");
      }

      if (image2) {
        imageURL2 = await handlePhotoSaving(image2, "verso");
      }

      const processDocRef = doc(collection(db, "processos"), nrProcesso);
      await setDoc(processDocRef, {
        dataCriacao: serverTimestamp(),
        dataEdicao: null,
        entidade,
        tipo_de_veiculo,
        distrito,
        concelho,
        tribunal,
        dateNotifi: dateNotifi == null ? null : Timestamp.fromDate(dateNotifi),
        dateInfra: dateInfra == null ? null : Timestamp.fromDate(dateInfra),
        dateTri: dateTri == null ? null : Timestamp.fromDate(dateTri),
        dataPrecricao: null,
        dataDefesa: null,
        prazoDefesa: "",
        dataDecisao: null,
        decisaoAdmin: "",
        decisaoJudicial: "",
        envolveu_em_acidenteTxt: "",
        isMorto: false,
        dataConclusao: null,
        prazo,
        pagouCoima,
        valorCoima: "",
        envolveu_em_acidente,
        infracao,
        userId: userId.id,
        id: nrProcesso,
        arquivado: false,
        plano,
        issueInvoice: isInvoice,
        source: imageURL1 || "",
        source2: imageURL2 || "",
        invoiceData,
        observacoes: "",
        estado: "Por pagar",
        dataPagamento: "N/D",
        usedDiscount: 0,
        pagamento: {
          entidade: eupagoResponse.entidade,
          referencia: eupagoResponse.referencia,
          price: parseFloat(eupagoResponse.valor).toFixed(2).replace(".", ","),

          data_limite: (() => {
            const [year, month, day] = eupagoResponse.data_fim.split("-");
            return Timestamp.fromDate(new Date(year, month - 1, day));
          })(),
        },
      });
      alert("Processo criado com sucesso.");
      navigate("/assuntos");
      await sendEmail();
    } catch (error) {
      console.log("Error saving document:", error);
    }
  };

  return (
    <div style={{ marginLeft: 300, marginBottom: 100 }}>
      <h1>Novo Assunto</h1>
      <h3>{nrProcesso}</h3>
      <form style={{ marginRight: 25 }} onSubmit={saveProcess}>
        <p style={{ fontFamily: "Avenir Next" }}>Subscritor</p>
        <Select
          options={users}
          onChange={(value) => {
            setUserId(value.value);
            setInvoiceName(value.value.fname + " " + value.value.lname);
            setInvoiceNif(value.value.nif);
            setInvoiceMorada(value.value.morada);
          }}
          placeholder="Selecionar"
        />
        <p style={{ fontFamily: "Avenir Next" }}>Entidade</p>
        <Select
          options={entOptions}
          onChange={(value) => setEntidade(value.value)}
          placeholder="Selecionar"
        />
        <p style={{ fontFamily: "Avenir Next" }}>Tipo do veículo</p>
        <Select
          options={veicOptions}
          onChange={(value) => setTipo_de_veiculo(value.value)}
          placeholder="Selecionar"
        />
        <p style={{ fontFamily: "Avenir Next" }}>Foto Frente</p>
        {image1 && (
          <div style={{ display: "flex" }}>
            <img
              style={{ width: 150, height: "100%", borderRadius: 5 }}
              src={URL.createObjectURL(image1)}
              alt="Image 1"
            />
            <button
              style={{
                padding: 10,
                borderRadius: 5,
                marginLeft: 10,
                border: 0,
                width: 100,
                height: 40,
                fontSize: 12,
                color: "#fff",
                fontWeight: "500",
                cursor: "pointer",
                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                backgroundColor: "#f00",
                fontFamily: "Avenir Next",
              }}
              onClick={() => setImage1(null)}
            >
              Apagar
            </button>
          </div>
        )}
        <input
          type="file"
          onChange={(event) => setImage1(event.target.files[0])}
        />
        <p style={{ fontFamily: "Avenir Next" }}>Foto Verso</p>
        {image2 && (
          <div style={{ display: "flex" }}>
            <img
              style={{ width: 150, height: "100%", borderRadius: 5 }}
              src={URL.createObjectURL(image2)}
              alt="Image 2"
            />
            <button
              style={{
                padding: 10,
                borderRadius: 5,
                marginLeft: 10,
                border: 0,
                width: 100,
                height: 40,
                fontSize: 12,
                color: "#fff",
                fontWeight: "500",
                cursor: "pointer",
                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                backgroundColor: "#f00",
                fontFamily: "Avenir Next",
              }}
              onClick={() => setImage2(null)}
            >
              Apagar
            </button>
          </div>
        )}
        <input
          type="file"
          onChange={(event) => setImage2(event.target.files[0])}
        />
        <p style={{ fontFamily: "Avenir Next" }}>Distrito</p>
        <Select
          options={distritosOptions}
          value={distrito ? { value: distrito, label: distrito } : null}
          onChange={(selectedOption) => {
            setDistrito(selectedOption ? selectedOption.value : "");
            setConcelho(
              selectedOption
                ? concelhos[distritos.indexOf(selectedOption.value)][0]
                : ""
            );
            setTribunal(
              selectedOption
                ? tribunais[distritos.indexOf(selectedOption.value)][0]
                : ""
            );
          }}
          placeholder="Selecionar"
        />
        <p style={{ fontFamily: "Avenir Next" }}>Concelho</p>
        <Select
          options={concelhosOptions[distritos.indexOf(distrito)]}
          value={
            concelho
              ? {
                  value: concelho,
                  label: concelho,
                }
              : null
          }
          onChange={(selectedOption) => {
            setConcelho(selectedOption ? selectedOption.value : "");
            setTribunal(
              selectedOption
                ? tribunais[distritos.indexOf(distrito)][
                    concelhos[distritos.indexOf(distrito)].indexOf(
                      selectedOption.value
                    )
                  ]
                : ""
            );
          }}
          placeholder="Selecionar"
        />
        <p style={{ fontFamily: "Avenir Next" }}>Tribunal: {tribunal}</p>
        <p style={{ fontFamily: "Avenir Next" }}>Data da Notificação</p>
        <DatePicker
          maxDate={new Date()}
          onChange={setDateNotifi}
          value={dateNotifi}
        />
        <p style={{ fontFamily: "Avenir Next" }}>Data da Infração</p>
        <DatePicker
          maxDate={new Date()}
          onChange={setDateInfra}
          value={dateInfra}
        />
        <p style={{ fontFamily: "Avenir Next" }}>Prazo</p>
        <Select
          options={prazoOptions}
          onChange={(value) => setPrazo(value.value)}
          placeholder="Selecionar"
        />
        <p style={{ fontFamily: "Avenir Next" }}>Pagou a coima?</p>
        <Select
          options={pagouCoimaOptions}
          onChange={(value) => setPagouCoima(value.value)}
          placeholder="Selecionar"
        />
        <p style={{ fontFamily: "Avenir Next" }}>Data de Julgamento</p>
        <DatePicker onChange={setDateTri} value={dateTri} />
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ fontFamily: "Avenir Next", marginRight: 10 }}>
            Envolveu um acidente de aviação
          </p>
          <input
            type="checkbox"
            checked={envolveu_em_acidente}
            onChange={() => setEnvolveu_em_acidente(!envolveu_em_acidente)}
          />
        </div>
        <Select
          options={infracoesOptions}
          value={
            infracao
              ? infracoesOptions.find(
                  (option) => option.label === infracao.infracao
                )
              : null
          }
          onChange={(value) => setInfracao(value ? value.value : null)}
          placeholder="Selecionar"
        />

        <p style={{ fontFamily: "Avenir Next" }}>Contestação</p>
        <div
          style={{
            display: "flex",
            flex: "100%",
            justifyContent: "space-between",
          }}
        >
          {honorarios &&
            honorarios.map((item) => {
              return (
                <div
                  style={{
                    backgroundColor: "#f2f2f2",
                    width: "30%",
                    paddingLeft: 20,
                    paddingTop: 10,
                    paddingRight: 20,
                    borderRadius: 5,
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: -20,
                    }}
                  >
                    <h3 style={{ fontFamily: "Avenir Next", marginRight: 10 }}>
                      {item.name}
                    </h3>
                    <input
                      type="checkbox"
                      checked={plano?.name == item.name}
                      onChange={() => setPlano(item)}
                    />
                  </div>
                  <p style={{ fontFamily: "Avenir Next" }}>
                    {item.description}
                  </p>
                  <p style={{ fontFamily: "Avenir Next" }}>
                    {parseFloat(item.price).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    € + IVA
                  </p>
                </div>
              );
            })}
        </div>

        <button
          type="button"
          onClick={() => getPlan(plano, nrProcesso)}
          style={{
            marginTop: 15,
            padding: 10,
            borderRadius: 5,
            border: 0,
            width: 180,
            fontSize: 14,
            color: "#fff",
            fontWeight: "500",
            cursor: "pointer",
            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
            backgroundColor: "rgb(33, 150, 243",
            fontFamily: "Avenir Next",
          }}
        >
          Gerar Referência
        </button>

        {loadingReference && <p>A carregar referência...</p>}
        {eupagoResponse && (
          <div>
            <p>Entidade:{eupagoResponse.entidade}</p>
            <p>
              Referência:
              {Number(eupagoResponse.referencia).toLocaleString("pt-PT")}
            </p>
            <p>
              Valor:{" "}
              {eupagoResponse.valor &&
                parseFloat(eupagoResponse.valor).toLocaleString("pt-PT", {
                  style: "currency",
                  currency: "EUR",
                  minimumFractionDigits: 2,
                })}
            </p>
            <p>Data Fim:{eupagoResponse.data_fim}</p>
          </div>
        )}

        <br></br>
        <button
          type="button"
          onClick={toggleSwitchFat}
          style={{
            marginTop: 15,
            padding: 10,
            borderRadius: 5,
            border: 0,
            width: 120,
            fontSize: 14,
            color: "#fff",
            fontWeight: "500",
            cursor: "pointer",
            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
            backgroundColor: "rgb(33, 150, 243",
            fontFamily: "Avenir Next",
          }}
        >
          Emitir fatura?
        </button>
        <br></br>
        {isInvoice && (
          <div style={{ fontFamily: "Avenir Next", display: "flex" }}>
            <label>
              <br></br>
              Nome
              <br></br>
              <input
                style={{ padding: 10, marginRight: 15 }}
                type="text"
                value={invoiceName}
                onChange={(e) => {
                  setInvoiceName(e.target.value);
                }}
              />
            </label>
            <br />
            <label>
              <br></br>
              NIF
              <br></br>
              <input
                style={{ padding: 10, marginRight: 15 }}
                type="text"
                value={invoiceNif}
                onChange={(e) => {
                  setInvoiceNif(e.target.value);
                }}
              />
            </label>
            <br />
            <label>
              <br></br>
              Morada
              <br></br>
              <input
                style={{ padding: 10, marginRight: 15 }}
                type="text"
                value={invoiceMorada}
                onChange={(e) => {
                  setInvoiceMorada(e.target.value);
                }}
              />
            </label>
            <br />
          </div>
        )}
        <label style={{ fontFamily: "Avenir Next" }}>
          <br></br>
          Telemóvel
          <br></br>
          <input
            type="text"
            style={{ padding: 10 }}
            value={userId.phone || ""}
            readOnly={userId.phone}
            onChange={(e) => {
              setUserPhone(e.target.value);
            }}
          />
        </label>

        <br></br>
        <button
          style={{
            backgroundColor: "#2196f3",
            marginTop: 25,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
          }}
          type="text"
        >
          Guardar
        </button>
      </form>
    </div>
  );
};

export default AddProcesso;
