import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  doc,
  setDoc,
  deleteDoc,
  collection,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";

import { auth, db } from "../firebase";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true); // Loading state

  const createUser = async (email, password, userId) => {
    setLoading(true); // Set loading to true during async operation

    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const usersCollectionRef = collection(db, "administradores");
      const querySnapshot = await getDocs(
        query(usersCollectionRef, where("email", "==", email))
      );

      if (!querySnapshot.empty) {
        // Assuming email is unique, there should be only one matching document
        const userDoc = querySnapshot.docs[0];
        const docRef = doc(collection(db, "administradores"), userDoc.id);
        await updateDoc(docRef, { uid: user.uid, id: userDoc.id });
      } else {
        console.log("User not found");
      }

      return user;
    } catch (error) {
      console.log("Error creating user", error);
    } finally {
      setLoading(false); // Set loading to false after async operation completes
    }
  };

  const signIn = async (email, password) => {
    setLoading(true); // Set loading to true during async operation

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      return userCredential;
    } catch (error) {
      console.log("Error signing in", error);
    } finally {
      setLoading(false); // Set loading to false after async operation completes
    }
  };

  const logout = async () => {
    setLoading(true); // Set loading to true during async operation

    try {
      await signOut(auth);
    } catch (error) {
      console.log("Error logging out", error);
    } finally {
      setLoading(false); // Set loading to false after async operation completes
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      console.log(currentUser);

      if (currentUser) {
        const usersCollectionRef = collection(db, "administradores");
        const querySnapshot = await getDocs(
          query(usersCollectionRef, where("uid", "==", currentUser.uid))
        );
        if (!querySnapshot.empty) {
          // Assuming email is unique, there should be only one matching document
          const userDoc = querySnapshot.docs[0];
          const docRef = doc(collection(db, "administradores"), userDoc.id);
          const userDocSnapshot = await getDoc(docRef);
          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            setUser(userData);
          }
        } else {
          console.log("User not found", currentUser.uid);
        }
      }

      setLoading(false); // Set loading to false after fetching user data
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{ createUser, user, logout, signIn, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
