import React, { useState, useEffect } from "react";
import Select from "react-select";
import { collection, getDocs, addDoc, doc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { Link } from "react-router-dom";
import EmpresaCard from "../components/cards/EmpresaCard";
import { useNavigate } from "react-router-dom";

const GestEmpresas = () => {
  const [empresas, setEmpresas] = useState([]);

  const navigate = useNavigate();

  const fetchEmpresas = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "empresas"));
      const docList = [];
      setEmpresas([]);
      querySnapshot.docs.map((doc) =>
        docList.push([doc.data(), { id: doc.id }])
      );
      setEmpresas(docList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEmpresas();
  }, []);

  const [nome, setNome] = useState("");
  const [morada, setMorada] = useState("");
  const [postCode, setPostCode] = useState("");
  const [pais, setPais] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [gestor, setGestor] = useState("");
  const [contribuinte, setContribuinte] = useState("");
  const [cai, setCai] = useState("");
  const [nipc, setNipc] = useState("");
  const [codigo_protocolo, setCodigo_protocolo] = useState("");
  const [desconto, setDesconto] = useState("");
  const [tipo, setTipo] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dbRef = collection(db, "empresas");
      addDoc(dbRef, {
        nome,
        morada,
        postCode,
        pais,
        telefone,
        email,
        gestor,
        contribuinte,
        cai,

        codigo_protocolo,
        desconto,
        tipo,
      }).then((docRef) => {
        console.log("Document has been added successfully");
        alert("Empresa adicionada com sucesso.");
        navigate("/gestaoempresas");
        fetchEmpresas();
      });
      console.log("Document Added");
    } catch (e) {
      console.log(e.message);
    }
  };

  const options = [
    { value: "Motorista Profissional", label: "Motorista Profissional" },
    { value: "Com Protocolo", label: "Com Protocolo" },
  ];

  return (
    <div style={{ marginLeft: 300 }}>
      <h1 style={{ fontFamily: "Avenir Next", marginBottom: 50 }}>Empresas</h1>
      <form onSubmit={handleSubmit} style={{ marginRight: 25 }}>
        <p style={{ fontFamily: "Avenir Next" }}>Nome</p>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setNome(e.target.value)}
          type="text"
        />
        <p style={{ fontFamily: "Avenir Next" }}>Morada</p>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setMorada(e.target.value)}
          type="text"
        />
        <p style={{ fontFamily: "Avenir Next" }}>Código Postal</p>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setPostCode(e.target.value)}
          type="text"
        />
        <p style={{ fontFamily: "Avenir Next" }}>País</p>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setPais(e.target.value)}
          type="text"
        />
        <p style={{ fontFamily: "Avenir Next" }}>Telefone</p>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setTelefone(e.target.value)}
          type="text"
        />
        <p style={{ fontFamily: "Avenir Next" }}>Email</p>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
        />
        <p style={{ fontFamily: "Avenir Next" }}>Gestor</p>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setGestor(e.target.value)}
          type="text"
        />
        <p style={{ fontFamily: "Avenir Next" }}>NIPC</p>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setContribuinte(e.target.value)}
          type="text"
        />
        <p style={{ fontFamily: "Avenir Next" }}>CAE</p>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setCai(e.target.value)}
          type="text"
        />

        <p style={{ fontFamily: "Avenir Next" }}>Tipo</p>

        <Select
          options={options}
          onChange={(value) => setTipo(value.value)}
          placeholder="Selecionar"
        />

        <p style={{ fontFamily: "Avenir Next" }}>Desconto</p>
        <input
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setDesconto(e.target.value)}
          type="text"
        />
        <button
          style={{
            backgroundColor: "#2196f3",
            marginBottom: 15,
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
          }}
          type="text"
        >
          Submeter
        </button>
      </form>

      <table
        style={{
          backgroundColor: "#e8e8e9",
          paddingBottom: 10,
          paddingLeft: 10,
          paddingTop: 10,
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <tr style={{ borderRightWidth: 0 }}>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Nome
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 120,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Contribuinte
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            CAE
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            NIPC
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 260,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Tipo
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 260,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Desconto
          </td>
        </tr>
      </table>

      {empresas.map((item, index) => (
        <div className="box" key={index}>
          <Link
            style={{ textDecoration: "none" }}
            to={`/empresa/${index}`}
            state={{ item }}
          >
            <EmpresaCard key={index} value={item} />
          </Link>
        </div>
      ))}
    </div>
  );
};

export default GestEmpresas;
