const EmpresaCard = (props) => {
  return (
    <div>
      <table
        border={1}
        style={{
          backgroundColor: "#fff",
          borderWidth: 0.6,
          paddingLeft: 10,
          borderColor: "#e8e8e9",
          paddingBottom: 10,
          paddingTop: 10,
          justifyContent: "space-evenly",
          width: "100%",
          paddingRight: 20,
        }}
      >
        <tr style={{ borderWidth: 0 }}>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value[0].nome}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 120,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value[0].contribuinte}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value[0].cai}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value[0].contribuinte}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value[0].tipo}
          </td>
          <td
            style={{
              borderWidth: 0,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value[0].desconto != "" && props.value[0].desconto + " %"}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default EmpresaCard;
