const distritos = [
  "Aveiro",
  "Açores",
  "Beja",
  "Braga",
  "Bragança",
  "Castelo Branco",
  "Coimbra",
  "Évora",
  "Faro",
  "Guarda",
  "Leiria",
  "Lisboa",
  "Madeira",
  "Portalegre",
  "Porto",
  "Santarém",
  "Setúbal",
  "Viana Do Castelo",
  "Vila Real",
  "Viseu",
];
const concelhos = [
  [
    "Águeda",
    "Albergaria A Velha",
    "Anadia",
    "Arouca",
    "Aveiro",
    "Castelo de Paiva",
    "Espinho",
    "Estarrreja",
    "Ílhavo",
    "Mealhada",
    "Murtosa",
    "Oliveira de Azeméis",
    "Oliveira do Bairro",
    "Ovar",
    "Santa Maria da Feira",
    "São João da Madeira",
    "Sever do Vouga",
    "Vagos",
    "Vale de Cambra",
  ],
  [
    "Angra do Heroísmo",
    "Calheta",
    "Corvo",
    "Horta",
    "Lagoa",
    "Lajes das Flores",
    "Lages do Pico",
    "Madalena",
    "Nordeste",
    "Ponta Delgada",
    "Povoação",
    "Praia da Vitória",
    "Ribeira Grande",
    "Santa Cruz da Graciosa",
    "Santa Cruz das Flores",
    "São Roque do Pico",
    "Velas",
    "Vila do Porto",
    "Vila Franca do Campo",
  ],
  [
    "Aljustrel",
    "Almodôvar",
    "Alvito",
    "Barrancos",
    "Beja",
    "Castro Verde",
    "Cuba",
    "Ferreira do Alentejo",
    "Mértola",
    "Moura",
    "Odemira",
    "Ourique",
    "Serpa",
    "Vidigueira",
  ],
  [
    "Amares",
    "Barcelos",
    "Braga",
    "Cabeceiras de Basto",
    "Celorico de Basto",
    "Esposende",
    "Fafe",
    "Guimarães",
    "Póvoa de Lanhoso",
    "Terras de Bouro",
    "Vieira do Minho",
    "Vila Nova de Famalicão",
    "Vila Verde",
    "Vizela",
  ],
  [
    "Alfândega da Fé",
    "Bragança",
    "Carrazeda de Ansiães",
    "Freixo de Espada à Cinta",
    "Macedo de Cavaleiros",
    "Miranda do Douro",
    "Mirandela",
    "Mogadouro",
    "Torre de Moncorvo",
    "Vila Flor",
    "Vimioso",
    "Vinhais",
  ],
  [
    "Belmonte",
    "Castelo Branco",
    "Covilhã",
    "Fundão",
    "Idanha-a-Nova",
    "Oleiros",
    "Penamacor",
    "Proença a Nova",
    "Sertã",
    "Vila de Rei",
    "Vila Velha de Ródão",
  ],
  [
    "Arganil",
    "Cantanhede",
    "Coimbra",
    "Condeixa a Nova",
    "Figueira da Foz",
    "Góis",
    "Lousã",
    "Mira",
    "Miranda do Corvo",
    "Montemor o Velho",
    "Oliveira do Hospital",
    "Pampilhosa da Serra",
    "Penacova",
    "Penela",
    "Soure",
    "Tábua",
    "Vila Nova de Poiares",
  ],
  [
    "Alandroal",
    "Arraiolos",
    "Borba",
    "Estremoz",
    "Évora",
    "Montemor-o-Novo",
    "Mora",
    "Mourão",
    "Portel",
    "Redondo",
    "Reguengos de Monsaraz",
    "Vendas Novas",
    "Viana do Alentejo",
    "Vila Viçosa",
  ],
  [
    "Albufeira",
    "Alcoutim",
    "Aljezur",
    "Castro Marim",
    "Faro",
    "Lagoa",
    "Lagos",
    "Loulé",
    "Monchique",
    "Olhão",
    "Portimão",
    "São Brás de Alportel",
    "Silves",
    "Tavira",
    "Vila do Bispo",
    "Vila Real de Santo António",
  ],
  [
    "Aguiar da Beira",
    "Almeida",
    "Celorico da Beira",
    "Figueira de Castelo Rodrigo",
    "Fornos de Algodres",
    "Gouveia",
    "Guarda",
    "Manteigas",
    "Meda",
    "Pinhel",
    "Sabugal",
    "Seia",
    "Trancoso",
    "Vila Nova de Foz Côa",
  ],
  [
    "Alcobaça",
    "Alvaiázere",
    "Ansião",
    "Batalha",
    "Bombarral",
    "Caldas da Rainha",
    "Castanheira de Pêra",
    "Figueiró dos Vinhos",
    "Leiria",
    "Marinha Grande",
    "Nazaré",
    "Óbidos",
    "Pedrógão Grande",
    "Peniche",
    "Pombal",
    "Porto de Mós",
  ],
  [
    "Almada",
    "Alcochete",
    "Alenquer",
    "Amadora",
    "Arruda dos Vinhos",
    "Azambuja",
    "Barreiro",
    "Cadaval",
    "Cascais",
    "Lisboa",
    "Loures",
    "Lourinhã",
    "Mafra",
    "Moita",
    "Montijo",
    "Odivelas",
    "Oeiras",
    "Seixal",
    "Sintra",
    "Sobral de Monte Agraço",
    "Torres Vedras",
    "Vila Franca de Xira",
  ],
  [
    "Calheta",
    "Câmara de Lobos",
    "Funchal",
    "Machico",
    "Ponta do Sol",
    "Porto Moniz",
    "Porto Santo",
    "Ribeira Brava",
    "Santa Cruz",
    "Santana",
    "São Vicente",
  ],
  [
    "Alter do Chão",
    "Arronches",
    "Avis",
    "Campo Maior",
    "Castelo de Vide",
    "Crato",
    "Elvas",
    "Fronteira",
    "Gavião",
    "Marvão",
    "Monforte",
    "Nisa",
    "Ponte de Sor",
    "Portalegre",
    "Sousel",
  ],
  [
    "Amarante",
    "Baião",
    "Felgueiras",
    "Gondomar",
    "Lousada",
    "Maia",
    "Marco de Canaveses",
    "Matosinhos",
    "Paços de Ferreira",
    "Paredes",
    "Penafiel",
    "Porto",
    "Póvoa de Varzim",
    "Santo Tirso",
    "Trofa",
    "Valongo",
    "Vila do Conde",
    "Vila Nova de Gaia",
  ],
  [
    "Abrantes",
    "Alcanena",
    "Almeirim",
    "Alpiarça",
    "Benavente",
    "Cartaxo",
    "Chamusca",
    "Constância",
    "Coruche",
    "Entroncamento",
    "Ferreira do Zêzere",
    "Golegã",
    "Mação",
    "Ourém",
    "Rio Maior",
    "Salvaterra de Magos",
    "Santarém",
    "Sardoal",
    "Tomar",
    "Torres Novas",
    "Vila Nova da Barquinha",
  ],
  [
    "Alcácer do Sal",
    "Grândola",
    "Palmela",
    "Santiago do Cacém",
    "Sesimbra",
    "Setúbal",
    "Sines",
  ],
  [
    "Arcos de Valdevez",
    "Caminha",
    "Melgaço",
    "Monção",
    "Paredes de Coura",
    "Ponte da Barca",
    "Ponte de Lima",
    "Valença",
    "Viana do Castelo",
    "Vila Nova de Cerveira",
  ],
  [
    "Alijó",
    "Boticas",
    "Chaves",
    "Mesão Frio",
    "Mondim de Basto",
    "Montalegre",
    "Murça",
    "Peso da Régua",
    "Ribeira da Pena",
    "Sabrosa",
    "Santa Marta de Penaguião",
    "Valpaços",
    "Vila Pouca de Aguiar",
    "Vila Real",
  ],
  [
    "Armamar",
    "Carregal do Sal",
    "Castro Daire",
    "Cinfães",
    "Lamêgo",
    "Mangualde",
    "Moimenta da Beira",
    "Mortágua",
    "Nelas",
    "Oliveira de Frades",
    "Penalva do Castelo",
    "Penedono",
    "Resende",
    "Santa Comba Dão",
    "São João da Pesqueira",
    "São Pedro do Sul",
    "Sátão",
    "Sernancelhe",
    "Tabuaço",
    "Tarouca",
    "Tondela",
    "Vila Nova de Paiva",
    "Viseu",
    "Vouzela",
  ],
];
const tribunais = [
  [
    "Comarca de Aveiro - Juízo Local Criminal Águeda",
    "Comarca de Aveiro - Juízo Competência Genérica Albergaria A Velha",
    "Comarca de Aveiro - Juízo Local Criminal Anadia",
    "Comarca de Aveiro - Juízo Local Criminal Arouca",
    "Comarca de Aveiro - Juízo Local Criminal Aveiro",
    "Comarca de Aveiro - Juízo Competência Genérica Castelo de Paiva",
    "Comarca de Aveiro - Juízo Competência Genérica Espinho",
    "Comarca de Aveiro - Juízo Competência Genérica Estarreja",
    "Comarca de Aveiro - Juízo Competência Genérica Ílhavo",
    "Comarca de Aveiro - Juízo Competência Genérica Mealhada",
    "Comarca de Aveiro - Juízo Competência Genérica Estarreja",
    "Comarca de Aveiro - Juízo Local Criminal Oliveira de Azeméis",
    "Comarca de Aveiro - Juízo Competência Genérica Oliveira do Bairro",
    "Comarca de Aveiro - Juízo Local Criminal Ovar",
    "Comarca de Aveiro - Juízo Local Criminal Santa Maria da Feira",
    "Comarca de Aveiro - Juízo Competência Genérica São João da Madeira",
    "Comarca de Aveiro - Juízo Competência Genérica Albergaria A Velha",
    "Comarca de Aveiro - Juízo Competência Genérica Vagos",
    "Comarca de Aveiro - Juízo Competência Genérica Vale de Cambra",
  ],
  [
    "Comarca dos Açores - Juízo Local Criminal Angra do Heroísmo",
    "Comarca dos Açores - Juízo Competência Genérica Velas",
    "Comarca dos Açores - Juízo Competência Genérica Santa Cruz das Flores",
    "Comarca dos Açores - Juízo Competência Genérica Horta",
    "Comarca dos Açores - Juízo Local Criminal Ponta Delgada",
    "Comarca dos Açores - Juízo Competência Genérica Santa Cruz das Flores",
    "Comarca dos Açores - Juízo Competência Genérica São Roque do Pico",
    "Comarca dos Açores - Juízo Competência Genérica São Roque do Pico",
    "Comarca dos Açores - Juízo Competência Genérica São Roque do Pico",
    "Comarca dos Açores - Juízo Local Criminal Ponta Delgada",
    "Comarca dos Açores - Juízo Competência Genérica Vila Franca do Campo",
    "Comarca dos Açores - Juízo Competência Genérca Praia da Vitória",
    "Comarca dos Açores - Juízo Local Criminal Ribeira Grande",
    "Comarca dos Açores - Juízo Competência Genérica Santa Cruz da Graciosa",
    "Comarca dos Açores - Juízo Competência Genérica Santa Cruz das Flores",
    "Comarca dos Açores - Juízo Competência Genérica São Roque do Pico",
    "Comarca dos Açores - Juízo Local Competência Genérica Velas",
    "Comarca dos Açores - Juízo Competência Genérica Vila do Porto",
    "Comarca dos Açores - Juízo Competência Genérica Vila Franca do Campo",
  ],
  [
    "Comarca de Beja - Juízo Competência Genérica Ourique",
    "Comarca de Beja - Juízo Competência Genérica Almodôvar",
    "Comarca de Beja - Juízo Competência Genérica Cuba",
    "Comarca de Beja - Juízo Competência Genérica Moura",
    "Comarca de Beja -  Juízo Local Criminal Beja",
    "Comarca de Beja - Juízo Competência Genérica Almodôvar",
    "Comarca de Beja - Juízo Competência Genérica Cuba",
    "Comarca de Beja - Juízo Competência Genérica Ferreira do Alentejo",
    "Comarca de Beja -  Juízo Local Criminal Beja",
    "Comarca de Beja - Juízo Competência Genérica Moura",
    "Comarca de Beja - Juízo Competência Genérica Odemira",
    "Comarca de Beja - Juízo Competência Genérica Ourique",
    "Comarca de Beja - Juízo Competência Genérica Serpa",
    "Comarca de Beja - Juízo Competência Genérica Cuba",
  ],
  [
    "Comarca de Braga -  Juízo Local Criminal Amares",
    "Comarca de Braga - Juízo Local Criminal Barcelos",
    "Comarca de Braga -  Juízo Local Criminal Braga",
    "Comarca de Braga -  Juízo Competência Genérica Basto",
    "Comarca de Braga -  Juízo Competência Genérica Celorico de Basto",
    "Comarca de Braga -  Juízo Competência Genérica Esposende",
    "Comarca de Braga - Juízo Local Criminal Fafe",
    "Comarca de Braga - Juízo Local Criminal Guimarães",
    "Comarca de Braga - Juízo Local Criminal Póvoa de Lanhoso",
    "Comarca de Braga - Juízo Local Criminal Vila Verde",
    "Comarca de Braga - Juízo Local Criminal Vieira do Minho",
    "Comarca de Braga - Juízo Local Criminal Vila Nova de Famalicão",
    "Comarca de Braga - Juízo Local Criminal Vila Verde",
    "Comarca de Braga - Juízo Local Criminal Guimarães",
  ],
  [
    "Comarca de Bragança - Juízo de Competência Genérica Mogadouro",
    "Comarca de Bragança - Juízo Local Criminal Bragança",
    "Comarca de Bragança - Juízo Competência Genérica Vila Flôr",
    "Comarca de Bragança - Juízo Competência Genérica Torre de Moncorvo",
    "Comarca de Bragança - Juízo Competência Genérica Macedo de Cavaleiros",
    "Comarca de Bragança - Juízo Competência Genérica Miranda do Douro",
    "Comarca de Bragança - Juízo Competência Genérica Mirandela",
    "Comarca de Bragança - Juízo Competência Genérica Mogadouro",
    "Comarca de Bragança - Juízo Competência Genérica Torre de Moncorvo",
    "Comarca de Bragança - Juízo Competência Genérica Vila Flôr",
    "Comarca de Bragança - Juízo Competência Genérica Miranda do Douro",
    "Comarca de Bragança - Juízo Local Criminal Bragança",
  ],
  [
    "Comarca de Castelo Branco - Juízo Local Criminal Covilhã",
    "Comarca de Castelo Branco - Juízo Local Criminal Castelo Branco",
    "Comarca de Castelo Branco - Juízo Local Criminal Covilhã",
    "Comarca de Castelo Branco - Juízo Local Criminal Fundão",
    "Comarca de Castelo Branco - Juízo Competência Genérica Idanha a Nova",
    "Comarca de Castelo Branco - Juízo Competência Genérica Oleiros",
    "Comarca de Castelo Branco - Juízo Local Criminal Fundão",
    "Comarca de Castelo Branco - Juízo Competência Genérica Oleiros",
    "Comarca de Castelo Branco - Juízo Competência Genérica Sertã",
    "Comarca de Castelo Branco - Juízo Competência Genérica Sertã",
    "Comarca de Castelo Branco - Juízo Local Criminal Castelo Branco",
  ],
  [
    "Comarca de Coimbra - Juízo Competência Genérica Arganil",
    "Comarca de Coimbra - Juízo Local Criminal Cantanhede",
    "Comarca de Coimbra - Juízo Local Criminal Coimbra",
    "Comarca de Coimbra - Juízo Competência Genérica Condeixa a Nova",
    "Comarca de Coimbra - Juízo Local Criminal Figueira da Foz",
    "Comarca de Coimbra - Juízo Competência Genérica Arganil",
    "Comarca de Coimbra - Juízo Competência Genérica Lousã",
    "Comarca de Coimbra - Juízo Local Criminal Cantanhede",
    "Comarca de Coimbra - Juízo Competência Genérica Lousã",
    "Comarca de Coimbra - Juízo Competência Genérica Montemor o Velho",
    "Comarca de Coimbra - Juízo Competência Genérica Oliveira do Hospital",
    "Comarca de Coimbra - Juízo Competência Genérica Lousã",
    "Comarca de Coimbra - Juízo Competência Genérica Penacova",
    "Comarca de Coimbra - Juízo Competência Genérica Condeixa a Nova",
    "Comarca de Coimbra - Juízo Competência Genérica Soure",
    "Comarca de Coimbra - Juízo Competência Genérica Tábua",
    "Comarca de Coimbra - Juízo Competência Genérica Penacova",
  ],
  [
    "Comarca de Évora - Juízo Cometência Genérica Redondo",
    "Comarca de Évora - Juízo Local Criminal Évora",
    "Comarca de Évora - Juízo Cometência Genérica Vila Viçosa",
    "Comarca de Évora - Juízo Proximidade Estremoz",
    "Comarca de Évora - Juízo Local Criminal Évora",
    "Comarca de Évora - Juízo Cometência Genérica Montemor o Novo",
    "Comarca de Évora - Juízo Local Criminal Évora",
    "Comarca de Évora - Juízo Cometência Genérica Reguengos de Monsaraz",
    "Comarca de Évora - Juízo Local Criminal Évora",
    "Comarca de Évora - Juízo Cometência Genérica Redondo",
    "Comarca de Évora - Juízo Cometência Genérica Reguengos de Monsaraz",
    "Comarca de Évora - Juízo Cometência Genérica Montemor o Novo",
    "Comarca de Évora - Juízo Local Criminal Évora",
    "Comarca de Évora - Juízo Cometência Genérica Vila Viçosa",
  ],
  [
    "Comarca de Faro - Juízo Local Criminal de Albufeira",
    "Comarca de Faro - Juízo Competência Genérica Vila Real de Santo António",
    "Comarca de Faro - Juízo Competência Genérica Lagos",
    "Comarca de Faro - Juízo Competência Genérica Vila Real de Santo António",
    "Comarca de Faro - Juízo Local Criminal Faro",
    "Comarca de Faro - Juízo Local Criminal Portimão",
    "Comarca de Faro - Juízo Competência Genérica Lagos",
    "Comarca de Faro - Juízo Local Criminal de Loulé",
    "Comarca de Faro - Juízo Local Criminal Portimão",
    "Comarca de Faro - Juízo Competência Genérica Olhão da Restauração",
    "Comarca de Faro - Juízo Local Criminal Portimão",
    "Comarca de Faro - Juízo Local Criminal Faro",
    "Comarca de Faro - Juízo Competência Genérica Silves",
    "Comarca de Faro - Juízo Competência Genérica Tavira",
    "Comarca de Faro - Juízo Competência Genérica Lagos",
    "Comarca de Faro - Juízo Competência Genérica Vila Real de Santo António",
  ],
  [
    "Comarca da Guarda - Juízo Competência Genérica Trancoso",
    "Comarca da Guarda - Juízo Competência Genérica Almeida",
    "Comarca da Guarda - Juízo Competência Genérica Celorico da Beira",
    "Comarca da Guarda - Juízo Competência Genérica Figueira de Castelo Rodrigo",
    "Comarca da Guarda - Juízo Competência Genérica Celorico da Beira",
    "Comarca da Guarda - Juízo Competência Genérica Gouveia",
    "Comarca da Guarda - Juízo Local Criminal Guarda",
    "Comarca da Guarda - Juízo Local Criminal Guarda",
    "Comarca da Guarda - Juízo Competência Genérica Vila Nova de Foz Côa",
    "Comarca da Guarda - Juízo Competência Genérica Pinhel",
    "Comarca da Guarda - Juízo Local Criminal Guarda",
    "Comarca da Guarda - Juízo Competência Genérica Seia",
    "Comarca da Guarda - Juízo Competência Genérica Trancoso",
    "Comarca da Guarda - Juízo Competência Genérica Vila Nova de Foz Côa",
  ],
  [
    "Comarca de Leiria - Juízo Local Criminal Alcobaça",
    "Comarca de Leiria - Juízo Competência Genérica Figueiró dos Vinhos",
    "Comarca de Leiria - Juízo Local Criminal Pombal",
    "Comarca de Leiria - Juízo Local Criminal Porto de Mós",
    "Comarca de Leiria - Juízo Local Criminal Caldas da Rainha",
    "Comarca de Leiria - Juízo Local Criminal Caldas da Rainha",
    "Comarca de Leiria - Juízo Competência Genérica Figueiró dos Vinhos",
    "Comarca de Leiria - Juízo Competência Genérica Figueiró dos Vinhos",
    "Comarca de Leiria - Juízo Local Criminal Leiria",
    "Comarca de Leiria - Juízo Competência Genérica Marinha Grande",
    "Comarca de Leiria - Juízo Competência Genérica Nazaré",
    "Comarca de Leiria - Juízo Local Criminal Caldas da Rainha",
    "Comarca de Leiria - Juízo Competência Genérica Figueiró dos Vinhos",
    "Comarca de Leiria - Juízo Competência Genérica Peniche",
    "Comarca de Leiria - Juízo Local Criminal Pombal",
    "Comarca de Leiria - Juízo Local Criminal Porto de Mós",
  ],
  [
    "Comarca de Lisboa - Juízo Local Criminal Almada",
    "Comarca de Lisboa - Juízo Local Criminal Montijo",
    "Comarca de Lisboa Norte - Juízo Local Criminal Alenquer",
    "Comarca de Lisboa Oeste - Juízo Local Criminal Amadora",
    "Comarca de Lisboa Norte - Juízo Local Criminal Vila Franca de Xira",
    "Comarca de Lisboa Norte - Juízo Local Criminal Alenquer",
    "Comarca de Lisboa - Juízo Local Criminal Barreiro",
    "Comarca de Lisboa Norte - Juízo Local Criminal de Torres Vedras",
    "Comarca de Lisboa Oeste - Juízo Local de Pequena Criminalidade Cascais",
    "Comarca de Lisboa - Juízo de Pequena Criminalidade Lisboa",
    "Comarca de Lisboa Norte - Juízo Local Criminal  Loures",
    "Comarca de Lisboa - Juízo Competência Genérica Lourinhã",
    "Comarca de Lisboa Oeste - Juízo Local Criminal Mafra",
    "Comarca de Lisboa - Juízo Local Criminal Barreiro",
    "Comarca de Lisboa - Juízo Local Criminal Montijo",
    "Comarca de Lisboa - Juízo de Pequena Criminalidade Lisboa",
    "Comarca de Lisboa Oeste - Juízo Local Criminal Oeiras",
    "Comarca de Lisboa - Juízo Local Criminal Seixal",
    "Comarca de Lisboa Oeste - Juízo de Pequena Criminalidade Sintra",
    "Comarca de Lisboa Norte - Juízo Local Criminal Torres Vedras",
    "Comarca de Lisboa Norte - Juízo Local Criminal Torres Vedras",
    "Comarca de Lisboa Norte - Juízo Local Criminal Vila Franca de Xira",
  ],
  [
    "Comarca da Madeira - Juízo Competência Genérica Ponta do Sol",
    "Comarca da Madeira - Juízo Local Criminal Funchal",
    "Comarca da Madeira - Juízo Local Criminal Funchal",
    "Comarca da Madeira - Juízo Local Criminal Santa Cruz",
    "Comarca da Madeira - Juízo Competência Genérica Ponta do Sol",
    "Comarca da Madeira - Juízo Local Criminal Funchal",
    "Comarca da Madeira - Juízo Competência Genérica Porto Santo",
    "Comarca da Madeira - Juízo Competência Genérica Ponta do Sol",
    "Comarca da Madeira - Juízo Local Criminal Santa Cruz",
    "Comarca da Madeira - Juízo Local Criminal Funchal",
    "Comarca da Madeira - Juízo Local Criminal Funchal",
  ],
  [
    "Comarca de Portalegre - Juízo Competência Genérica Fronteira",
    "Comarca de Portalegre - Juízo Local Criminal Portalegre",
    "Comarca de Portalegre - Juízo Competência Genérica Fronteira",
    "Comarca de Portalegre - Juízo Local Criminal Elvas",
    "Comarca de Portalegre - Juízo Competência Genérica Nisa",
    "Comarca de Portalegre - Juízo Local Criminal Portalegre",
    "Comarca de Portalegre - Juízo Local Criminal Elvas",
    "Comarca de Portalegre - Juízo Competência Genérica Fronteira",
    "Comarca de Portalegre - Juízo Competência Genérica Ponte de Sôr",
    "Comarca de Portalegre - Juízo Local Criminal Portalegre",
    "Comarca de Portalegre - Juízo Local Criminal Portalegre",
    "Comarca de Portalegre - Juízo Competência Genérica Nisa",
    "Comarca de Portalegre - Juízo Competência Genérica Ponte de Sôr",
    "Comarca de Portalegre - Juízo Local Criminal Portalegre",
    "Comarca de Portalegre - Juízo Competência Genérica Fronteira",
  ],
  [
    "Comarca do Porto Este - Juízo Local Criminal Amarante",
    "Comarca do Porto Este - Juízo Competência Genérica Baião",
    "Comarca do Porto Este - Juízo Local Criminal Felgueiras",
    "Comarca do Porto - Juízo Local Criminal Gondomar",
    "Comarca do Porto Este - Juízo Local Criminal Felgueiras",
    "Comarca do Porto -  Juízo Local Criminal Maia",
    "Comarca do Porto Este - Juízo Local Criminal Marco de Canaveses",
    "Comarca do Porto - Juízo Local Criminal Matosinhos",
    "Comarca do Porto Este - Juízo Local Criminal Paços de Ferreira",
    "Comarca do Porto Este - Juízo Local Criminal Paredes",
    "Comarca do Porto Este - Juízo Local Criminal Penafiel",
    "Comarca do Porto - Juízo Local  de Pequena Criminalidade Porto",
    "Comarca do Porto - Juízo Local Criminal Póvoa de Varzim",
    "Comarca do Porto - Juízo Local Criminal Santo Tirso",
    "Comarca do Porto - Juízo Local Criminal Santo Tirso",
    "Comarca do Porto - Juízo Local Criminal Valongo",
    "Comarca do Porto - Juízo Local Criminal Vila do Conde",
    "Comarca do Porto - Juízo Local Criminal Vila Nova de Gaia",
  ],
  [
    "Comarca de Santarém - Juízo Local Criminal Abrantes",
    "Comarca de Santarém - Juízo Local Criminal Torres Novas",
    "Comarca de Santarém - Juízo Competência Genérica Almeirim",
    "Comarca de Santarém - Juízo Competência Genérica Almeirim",
    "Comarca de Santarém - Juízo Competência Genérica Benavente",
    "Comarca de Santarém - Juízo Competência Genérica Cartaxo",
    "Comarca de Santarém - Juízo Competência Genérica Entoncamento",
    "Comarca de Santarém - Juízo Local Criminal Abrantes",
    "Comarca de Santarém - Juízo Competência Genérica Coruche",
    "Comarca de Santarém - Juízo Competência Genérica Entoncamento",
    "Comarca de Santarém - Juízo Local Criminal Tomar",
    "Comarca de Santarém - Juízo Competência Genérica Entoncamento",
    "Comarca de Santarém - Juízo Local Criminal Abrantes",
    "Comarca de Santarém - Juízo Local Criminal Ourém",
    "Comarca de Santarém - Juízo Competência Genérica Rio Maior",
    "Comarca de Santarém - Juízo Competência Genérica Benavente",
    "Comarca de Santarém - Juízo Local Criminal Santarém",
    "Comarca de Santarém - Juízo Local Criminal Abrantes",
    "Comarca de Santarém - Juízo Local Criminal Tomar",
    "Comarca de Santarém - Juízo Local Criminal Torres Novas",
    "Comarca de Santarém - Juízo Competência Genérica Entoncamento",
  ],
  [
    "Comarca de Setúbal - Juízo Local Criminal Grândola",
    "Comarca de Setúbal - Juízo Local Criminal Grândola",
    "Comarca de Setúbal - Juízo Local Criminal Setúbal",
    "Comarca de Setúbal - Juízo Local Criminal Santiago do Cacém",
    "Comarca de Setúbal - Juízo Cometência Genérica Sesimbra",
    "Comarca de Setúbal - Juízo Local Criminal Setúbal",
    "Comarca de Setúbal - Juízo Local Criminal Santiago do Cacém",
  ],
  [
    "Comarca de Viana do Castelo - Juízo Local Criminal Arcos de Valdevez",
    "Comarca de Viana Do Castelo - Juízo Competência Genérica Caminha",
    "Comarca de Viana Do Castelo - Juízo Competência Genérica Melgaço",
    "Comarca de Viana Do Castelo - Juízo Competência Genérica Monção",
    "Comarca de Viana Do Castelo - Juízo Competência Genérica Paredes de Coura",
    "Comarca de Viana Do Castelo - Juízo Local Criminal Ponte da Barca",
    "Comarca de Viana Do Castelo - Juízo Local Criminal Ponte de Lima",
    "Comarca de Viana Do Castelo - Juízo Competência Genérica Valença",
    "Comarca de Viana Do Castelo - Juízo Local Criminal Viana do Castelo",
    "Comarca de Viana Do Castelo - Juízo Competência Genérica Vila Nova de Cerveira",
  ],
  [
    "Comarca de Vila Real - Juízo Local Competência Genérica Alijó",
    "Comarca de Vila Real - Juízo Local Criminal Chaves",
    "Comarca de Vila Real - Juízo Local Criminal Chaves",
    "Comarca de Vila Real - Juízo Local Criminal Peso da Régua",
    "Comarca de Vila Real - Juízo Local Criminal Vila Real",
    "Comarca de Vila Real - Juízo Competência Genérica Montalegre",
    "Comarca de Vila Real - Juízo Local Criminal Vila Real",
    "Comarca de Vila Real - Juízo Local Criminal Peso da Régua",
    "Comarca de Vila Real - Juízo Competência Genérica Vila Pouca de Aguiar",
    "Comarca de Vila Real - Juízo Local Criminal Vila Real",
    "Comarca de Vila Real - Juízo Local Criminal Peso da Régua",
    "Comarca de Vila Real - Juízo Competência Genérica Valpaços",
    "Comarca de Vila Real - Juízo Competência Genérica Vila Pouca de Aguiar",
    "Comarca de Vila Real - Juízo Local Criminal Vila Real",
  ],
  [
    "Comarca de Viseu - Juízo Local Criminal Lamêgo",
    "Comarca de Viseu - Juízo Competência Genérica Santa Comba Dão",
    "Comarca de Viseu - Juízo Competência Genérica Castro Daire",
    "Comarca de Viseu - Juízo Competência Genérica Cinfães",
    "Comarca de Viseu  - Juízo Local Criminal Lamêgo",
    "Comarca de Viseu  - Juízo Local Criminal Mangualde",
    "Comarca de Viseu - Juízo Competência Genérica Moimenta da Beira",
    "Comarca de Viseu - Juízo Competência Genérica Santa Comba Dão",
    "Comarca de Viseu - Juízo Competência Genérica Nelas",
    "Comarca de Viseu - Juízo Competência Genérica Oliveira de Frades",
    "Comarca de Viseu - Juízo Local Criminal Mangualde",
    "Comarca de Viseu - Juízo Competência Genérica Moimenta da Beira",
    "Comarca de Viseu - Juízo Local Criminal Lamêgo",
    "Comarca de Viseu - Juízo Competência Genérica Santa Comba Dão",
    "Comarca de Viseu - Juízo Competência Genérica Moimenta da Beira",
    "Comarca de Viseu - Juízo Competência Genérica São Pedro do Sul",
    "Comarca de Viseu - Juízo Competência Genérica Sátão",
    "Comarca de Viseu - Juízo Competência Genérica Moimenta da Beira",
    "Comarca de Viseu - Juízo Competência Genérica Moimenta da Beira",
    "Comarca de Viseu  - Juízo Local Criminal Lamêgo",
    "Comarca de Viseu - Juízo Competência Genérica Tondela",
    "Comarca de Viseu - Juízo Competência Genérica Sátão",
    "Comarca de Viseu - Juízo Local Criminal Viseu",
    "Comarca de Viseu - Juízo Competência Genérica São Pedro do Sul",
  ],
];

export { distritos, concelhos, tribunais };
