import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  collection,
  query,
  orderBy,
  getDocs,
  doc,
  getDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import ReuniaoCard from "../components/cards/ReuniaoCard";

const Reunioes = (props) => {
  const [reunioes, setReunioes] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchedAffairs = async () => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const q1 = query(
      collection(db, "reunioes"),
      where("meetingSlot", ">=", currentDate),
      orderBy("meetingSlot", "asc")
    );
    const q2 = query(
      collection(db, "reunioes"),
      where("meetingSlot", "<", currentDate),
      orderBy("meetingSlot", "desc")
    );

    const reunioesSnapshot1 = await getDocs(q1);
    const reunioesSnapshot2 = await getDocs(q2);

    const fetchedReunioes = [];

    for (const docSnapshot of reunioesSnapshot1.docs) {
      const reuniao = docSnapshot.data();
      const reuniaoId = docSnapshot.id;
      const userId = reuniao.userId;

      const userDocRef = doc(db, "users", userId);
      const userDocSnapshot = await getDoc(userDocRef);
      const userData = userDocSnapshot.data();
      const gestorId = reuniao.admin;

      const gestorDocRef = doc(db, "administradores", gestorId);
      const gestorDocSnapshot = await getDoc(gestorDocRef);
      const gestorData = gestorDocSnapshot.data();

      const processedReuniao = {
        id: reuniaoId,
        user: {
          ...userData,
          gestor: gestorData,
        },
        ...reuniao,
      };

      fetchedReunioes.push(processedReuniao);
    }

    for (const docSnapshot of reunioesSnapshot2.docs) {
      const reuniao = docSnapshot.data();
      const reuniaoId = docSnapshot.id;
      const userId = reuniao.userId;

      const userDocRef = doc(db, "users", userId);
      const userDocSnapshot = await getDoc(userDocRef);
      const userData = userDocSnapshot.data();
      const gestorId = reuniao.admin;

      const gestorDocRef = doc(db, "administradores", gestorId);
      const gestorDocSnapshot = await getDoc(gestorDocRef);
      const gestorData = gestorDocSnapshot.data();

      const processedReuniao = {
        id: reuniaoId,
        user: {
          ...userData,
          gestor: gestorData,
        },
        ...reuniao,
      };

      fetchedReunioes.push(processedReuniao);
    }

    setReunioes(fetchedReunioes);
  };

  useEffect(() => {
    fetchedAffairs();
  }, []);

  return (
    <div>
      <div>
        <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>Reuniões</h1>
        <Link style={{ textDecoration: "none" }} to={`/nova-reuniao`}>
          <button
            style={{
              marginLeft: 300,
              marginTop: -15,
              marginBottom: 10,
              padding: 10,
              borderRadius: 5,
              border: 0,
              width: 180,
              fontSize: 14,
              color: "#fff",
              fontWeight: "500",
              cursor: "pointer",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
              backgroundColor: "rgb(33, 150, 243",
              fontFamily: "Avenir Next",
            }}
          >
            Criar nova reunião
          </button>
        </Link>

        <div style={{ flex: "33%", marginRight: 10 }}></div>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            marginTop: 15,
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: 300,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Cliente
            </td>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: 150,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Contacto
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: 150,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: 150,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Hora
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: 300,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Gestor
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: 300,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Estado
            </td>
          </tr>
        </table>
        {reunioes.map((reuniao, index) => (
          <div className="box" key={index}>
            <Link
              style={{ textDecoration: "none" }}
              to={`/reunioes/${reuniao.id}`}
            >
              <ReuniaoCard key={index} value={reuniao} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reunioes;
