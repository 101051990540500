import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  addDoc,
  serverTimestamp,
  orderBy,
} from "firebase/firestore";

import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  getMetadata,
  deleteObject,
} from "firebase/storage";
import { Link, useNavigate } from "react-router-dom";
import AtividadeCard2 from "../components/cards/AtividadeCard2";
import { db } from "../firebase";
import Select from "react-select";
import DatePicker from "react-date-picker";

function Processo(props) {
  const { affairId } = useParams();
  const navigate = useNavigate();

  const [affair, setAffair] = useState(null);
  const [atividades, setAtividades] = useState([]);
  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [processos, setProcessos] = useState([]);

  const [selectedUser, setSelectedUsers] = useState(null);
  const [processo, setProcesso] = useState("");
  const [admin, setAdmin] = useState("");
  const [observacoes, setObservacoes] = useState("");
  const [dataPrevA, setDataPrevA] = useState(new Date());
  const [dataConclu, setDataConclu] = useState(new Date());
  const [estado, setEstado] = useState("");

  const [novaAtividade, setNovaAtividade] = useState(false);
  const [arquivado, setArquivado] = useState(null);

  const dataHoje = new Date();

  const fetchAtividades = async () => {
    const q = query(
      collection(db, "atividades"),
      where("processo", "==", affairId),
      orderBy("createdAt", "desc")
    );
    const querySnapshot = await getDocs(q);
    const atividadesData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setAtividades(atividadesData);
  };

  useEffect(() => {
    const fetchAffair = async () => {
      const docRef = doc(db, "processos", affairId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const affairData = docSnap.data();
        const userId = affairData.userId;

        if (userId) {
          const userDocRef = doc(db, "users", userId);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            const affairWithUserData = {
              id: docSnap.id,
              ...affairData,
              user: userData,
            };
            setAffair(affairWithUserData);
            setArquivado(affairWithUserData.arquivado);
            fetchFileNames(affairWithUserData.files);
          } else {
            console.log("User document does not exist!");
          }
        } else {
          console.log("User field is not present in the document!");
        }
      } else {
        console.log("No such document!");
      }

      setLoading(false); // Set loading to false after fetching the data
    };

    const fetchAdmins = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "administradores"));
        const docList = [];
        setAdmins([]);
        querySnapshot.docs.map((doc) =>
          docList.push({
            value: doc.id,
            label: doc.data().fname + " " + doc.data().lname,
          })
        );
        setAdmins(docList);
      } catch (error) {
        console.log(error);
      }
    };

    fetchAffair();
    fetchAtividades();

    fetchAdmins();
  }, [affairId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dbRef = collection(db, "atividades");
      addDoc(dbRef, {
        user: affair.userId,
        processo: affairId,
        admin,
        observacoes,
        dataPrevA,
        dataConclu,
        estado,
        createdAt: serverTimestamp(),
      }).then((docRef) => {
        console.log("Document has been added successfully");
        fetchAtividades();
      });
      setNovaAtividade(false);
      console.log("Document Added");
    } catch (e) {
      console.log(e.message);
    }
  };

  const [fileNames, setFileNames] = useState([]);

  const fetchFileNames = async (files) => {
    const storage = getStorage();
    const names = await Promise.all(
      files.map(async (file) => {
        const fileRef = ref(storage, file);
        const metadata = await getMetadata(fileRef);
        return metadata.name || "Unnamed File";
      })
    );
    setFileNames(names);
  };

  const Arquivar = async () => {
    try {
      setArquivado(!arquivado);
      await updateDoc(doc(db, "processos", affairId), {
        arquivado: !arquivado,
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  const options = [
    { value: "", label: "Sem Filtro" },
    { value: "Por Realizar", label: "Por Realizar" },
    { value: "Realizada", label: "Realizada" },
    {
      value: "Cancelada a pedido do cliente",
      label: "Cancelada a pedido do cliente",
    },
    { value: "Cancelada", label: "Cancelada" },
  ];

  const filteredOptions =
    dataConclu != null ? options.slice(2, 5) : options.slice(1, 2);

  if (loading) {
    return <h1 style={{ marginLeft: 300 }}>Loading...</h1>;
  }

  return (
    <div>
      <div>
        <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>Processo</h1>
        <Link
          style={{ textDecoration: "none" }}
          to={`/assuntos/${affair.id}/editar`}
          state={{ affair }}
        >
          <button
            style={{
              marginLeft: 300,
              marginTop: -15,
              marginBottom: 30,
              padding: 10,
              borderRadius: 5,
              border: 0,
              width: 120,
              fontSize: 14,
              color: "#000",
              fontWeight: "500",
              cursor: "pointer",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
              backgroundColor: "#e8e8e9",
              fontFamily: "Avenir Next",
            }}
          >
            Editar
          </button>
        </Link>
        {/*  <button
        style={{
          marginLeft: 15,
              marginTop: -15,
              marginBottom: 30,
              padding: 10,
              borderRadius: 5,
              border: 0,
              width: 120,
              fontSize: 14,
              color: "#fff",
              fontWeight: "500",
              cursor: "pointer",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
              backgroundColor: "rgb(33, 150, 243)",
              fontFamily: "Avenir Next",
        }}
        onClick={() => Arquivar()}>
          {arquivado ? "Arquivado" : "Arquivar"}
        </button> */}

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              N.º do processo
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.id}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Cliente
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              <Link
                style={{ textDecoration: "none", color: "rgb(33, 150, 243)" }}
                to={`/clientes/${affair.userId}`}
              >
                {affair.user.fname} {affair.user.lname}
              </Link>
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Entidade atuante
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.entidade}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Tipo de veículo
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.tipo_de_veiculo}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Local da infração
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.concelho}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Tribunal
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.tribunal}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data da notificação
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.dateNotifi != null
                ? affair.dateNotifi.toDate().toLocaleDateString("en-GB")
                : "Não definido"}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de infração
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.dateInfra != null
                ? affair.dateInfra.toDate().toLocaleDateString("en-GB")
                : "Não definido"}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Prazo na carta para se defender
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.prazo}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Pagou a coima?
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.pagouCoima != "" ? affair.pagouCoima : "Não definido"}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data do julgamento
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.dateTri != null
                ? affair.dateTri.toDate().toLocaleDateString("en-GB")
                : "Não definido"}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Envolveu um acidente de aviação
            </td>
            {affair.envolveu_em_acidente == true ? (
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Sim
                {affair.envolveu_em_acidenteTxt == null ||
                affair.envolveu_em_acidenteTxt == ""
                  ? ""
                  : ", " + affair.envolveu_em_acidenteTxt}
              </td>
            ) : (
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Não
                {affair.envolveu_em_acidenteTxt == null ||
                affair.envolveu_em_acidenteTxt == ""
                  ? ""
                  : ", " + affair.envolveu_em_acidenteTxt}
              </td>
            )}
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Infração
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.infracao.infracao}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Moldura de coima
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {Number(affair.infracao.moldura).toFixed(2).replace(".", ",")} € -{" "}
              {Number(affair.infracao.moldura2).toFixed(2).replace(".", ",")} €
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Sanção acessória
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.infracao.sancao != null
                ? affair.infracao.sancao
                : "Não existente"}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Pontos da Carta de Condução
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.infracao.pontos}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de defesa
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.dataDefesa != null
                ? affair.dataDefesa.toDate().toLocaleDateString("en-GB")
                : "Não definido"}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de Prescrição
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.dataPrecricao != null
                ? affair.dataPrecricao.toDate().toLocaleDateString("en-GB")
                : "Não definido"}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de Decisão
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.dataDecisao != null
                ? affair.dataDecisao.toDate().toLocaleDateString("en-GB")
                : "Não definido"}
            </td>
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de Conclusão
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.dataConclusao != null
                ? affair.dataConclusao.toDate().toLocaleDateString("en-GB")
                : "Não definido"}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Estado do Processo
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.isMorto == true ? "Morto" : affair.estado}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Decisão Administrativa
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.decisaoAdmin != "" ? affair.decisaoAdmin : "Não definido"}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Decisão Judicial
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.decisaoJudicial != ""
                ? affair.decisaoJudicial
                : "Não definido"}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Fotos do auto
            </td>
            <div style={{ display: "inline-flex" }}>
              <a download target={"_blank"} href={affair.source}>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  <i className="fa fa-download" />
                  <img
                    style={{ borderRadius: 15, width: 110 }}
                    src={affair.source}
                  ></img>
                </td>
              </a>
              <a download target={"_blank"} href={affair.source2}>
                <td
                  style={{
                    borderWidth: 1,
                    borderColor: "#000",
                    width: "30%",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                  }}
                >
                  <i className="fa fa-download" />
                  <img
                    style={{ borderRadius: 15, width: 110 }}
                    src={affair.source2}
                  ></img>
                </td>
              </a>
            </div>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Observações
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {affair.observacoes == "" ? "Não definido" : affair.observacoes}
            </td>
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Ficheiros
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              <div>
                {fileNames.map((fileName, index) => (
                  <div key={index}>
                    <p>{fileName}</p>
                    <button
                      style={{
                        marginTop: -15,
                        marginBottom: 10,
                        padding: 10,
                        borderRadius: 5,
                        border: 0,
                        width: 120,
                        fontSize: 14,
                        color: "#fff",
                        fontWeight: "500",
                        cursor: "pointer",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                        backgroundColor: "rgb(33, 150, 243",
                        fontFamily: "Avenir Next",
                      }}
                      type="button"
                      onClick={() => window.open(affair.files[index], "_blank")}
                    >
                      Abrir
                    </button>
                  </div>
                ))}
              </div>
            </td>
          </tr>
        </table>
      </div>
      <h1
        style={{ paddingLeft: 300, marginTop: 25, fontFamily: "Avenir Next" }}
      >
        Referência MB
      </h1>
      <table
        style={{
          backgroundColor: "#e8e8e9",
          paddingBottom: 10,
          paddingTop: 10,
          paddingLeft: 300,
          justifyContent: "space-evenly",
          width: "100%",
          paddingRight: 20,
        }}
      >
        <tr style={{ borderRightWidth: 1 }}>
          <td
            style={{
              borderLeftWidth: 1,
              borderLeftColor: "#000",
              width: "70%",
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Ref Multibanco
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "30%",
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            {affair.pagamento.referencia}
          </td>
        </tr>
      </table>

      <table
        style={{
          backgroundColor: "#fff",
          paddingBottom: 10,
          paddingTop: 10,
          paddingLeft: 300,
          justifyContent: "space-evenly",
          width: "100%",
          paddingRight: 20,
        }}
      >
        <tr style={{ borderRightWidth: 1 }}>
          <td
            style={{
              borderLeftWidth: 1,
              borderLeftColor: "#000",
              width: "70%",
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Fatura/Recibo emitida
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "30%",
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            {affair.fatura ? (
              <button
                onClick={() =>
                  window.open(affair.fatura, "_blank", "noopener,noreferrer")
                }
              >
                Abrir Fatura
              </button>
            ) : (
              "Não definido"
            )}
          </td>
        </tr>
      </table>

      <table
        style={{
          backgroundColor: "#e8e8e9",
          paddingBottom: 10,
          paddingTop: 10,
          paddingLeft: 300,
          justifyContent: "space-evenly",
          width: "100%",
          paddingRight: 20,
        }}
      >
        <tr style={{ borderRightWidth: 1 }}>
          <td
            style={{
              borderLeftWidth: 1,
              borderLeftColor: "#000",
              width: "70%",
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Montante
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "30%",
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            {affair.pagamento.price}€
          </td>
        </tr>
      </table>
      <table
        style={{
          backgroundColor: "#fff",
          paddingBottom: 10,
          paddingTop: 10,
          paddingLeft: 300,
          justifyContent: "space-evenly",
          width: "100%",
          paddingRight: 20,
        }}
      >
        <tr style={{ borderRightWidth: 1 }}>
          <td
            style={{
              borderLeftWidth: 1,
              borderLeftColor: "#000",
              width: "70%",
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Data de pagamento
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "30%",
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            {affair.dataPagamento == "N/D"
              ? affair.dataPagamento
              : affair.dataPagamento.toDate().toLocaleDateString("en-GB")}
          </td>
        </tr>
      </table>

      <h1
        style={{ paddingLeft: 300, marginTop: 25, fontFamily: "Avenir Next" }}
      >
        Tarefas
      </h1>
      {novaAtividade ? (
        <button
          onClick={() => setNovaAtividade(false)}
          style={{
            marginLeft: 300,
            marginTop: -15,
            marginBottom: 10,
            padding: 10,
            borderRadius: 5,
            border: 0,
            width: 120,
            fontSize: 14,
            color: "#fff",
            fontWeight: "500",
            cursor: "pointer",
            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#f00",
            fontFamily: "Avenir Next",
          }}
        >
          Fechar
        </button>
      ) : (
        <button
          onClick={() => setNovaAtividade(true)}
          style={{
            marginLeft: 300,
            marginTop: -15,
            marginBottom: 10,
            padding: 10,
            borderRadius: 5,
            border: 0,
            width: 120,
            fontSize: 14,
            color: "#fff",
            fontWeight: "500",
            cursor: "pointer",
            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
            backgroundColor: "rgb(33, 150, 243",
            fontFamily: "Avenir Next",
          }}
        >
          Criar Atividade
        </button>
      )}

      {novaAtividade && (
        <div style={{ paddingLeft: 300 }}>
          <form onSubmit={handleSubmit}>
            <p style={{ fontFamily: "Avenir Next" }}>Processo: {affairId}</p>
            <p style={{ fontFamily: "Avenir Next" }}>Colaborador</p>
            <Select
              options={admins}
              onChange={(admin) => setAdmin(admin.value)}
              placeholder="Selecionar"
            />
            <h1 style={{ fontFamily: "Avenir Next" }}>{admin.value}</h1>
            <p style={{ fontFamily: "Avenir Next" }}>Observações</p>
            <input
              maxLength={65}
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                width: "100%",
              }}
              onChange={(e) => setObservacoes(e.target.value)}
              type="text"
            />

            <div style={{ display: "inline-flex" }}>
              <div>
                <p style={{ fontFamily: "Avenir Next" }}>Data de Realização</p>
                <DatePicker
                  maxDate={dataHoje}
                  onChange={setDataConclu}
                  value={dataConclu}
                />
              </div>

              <div style={{ marginLeft: 25 }}>
                <p style={{ fontFamily: "Avenir Next" }}>Data Prevista</p>
                <DatePicker
                  minDate={dataHoje}
                  onChange={setDataPrevA}
                  value={dataPrevA}
                />
              </div>
              <div style={{ marginLeft: 25 }}>
                <p style={{ fontFamily: "Avenir Next" }}>Estado</p>
                <Select
                  options={filteredOptions}
                  onChange={(value) => setEstado(value.value)}
                  placeholder="Selecionar"
                />
              </div>
            </div>
            <br></br>
            <button
              style={{
                backgroundColor: "#2196f3",
                marginBottom: 15,
                marginTop: 15,
                borderRadius: 5,
                border: 0,
                padding: 15,
                fontSize: 12,
                right: 0,
                marginRight: 45,
                width: "100px",
                fontFamily: "Avenir Next",
                color: "#fff",
                right: 0,
              }}
              type="text"
            >
              Submeter
            </button>
          </form>
        </div>
      )}
      <table
        style={{
          backgroundColor: "#e8e8e9",
          paddingBottom: 10,
          marginTop: 10,
          paddingTop: 10,
          paddingLeft: 300,
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <tr style={{ borderRightWidth: 1 }}>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 150,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Processo
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Colaborador
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Observações
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Data de Conclusão
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Data Prevista
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 0,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          ></td>
        </tr>
      </table>

      <div style={{ marginBottom: 50 }}>
        {atividades.map((item, index) => (
          <AtividadeCard2 value={{ item }} navigate={navigate} />
        ))}
      </div>
    </div>
  );
}

export default Processo;
