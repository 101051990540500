import React, { useState, useEffect } from "react";
import {
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import InfracoesCard from "../components/cards/InfracoesCard";
import { Link, useNavigate } from "react-router-dom";

const GestInfracoes = () => {
  const [infracao, setInfracao] = useState("");
  const [pontos, setPontos] = useState("");
  const [moldura, setMoldura] = useState("");
  const [moldura2, setMoldura2] = useState("");

  const [infracoes, setInfracoes] = useState([]);
  const [loading, setLoading] = useState(true);

  const getInfracoes = async () => {
    const docRef = doc(db, "global", "infracoes");

    const docSnap = await getDoc(docRef);
    setInfracoes(docSnap.data().infracoes);
    if (loading == true) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInfracoes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      infracoes.push({ infracao, pontos, moldura, moldura2 });
      await setDoc(doc(db, "global", "infracoes"), { infracoes }).then(
        (docRef) => {
          console.log("Document has been added successfully");
        }
      );

      console.log("Document Added");
      alert("Infração criada com sucesso.");
      getInfracoes();
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div style={{ marginLeft: 300 }}>
      <h1 style={{ fontFamily: "Avenir Next" }}>
        Gestão Administrativa - Infrações
      </h1>
      <form onSubmit={handleSubmit} style={{ marginRight: 25 }}>
        <div style={{ display: "inline-flex", width: "100%" }}>
          <div style={{ width: "100%", marginRight: 15 }}>
            <label style={{ fontFamily: "Avenir Next" }}>Infração</label>
            <b
              style={{ marginLeft: 5, fontSize: 12, fontFamily: "Avenir Next" }}
            >
              ({infracao.length}/100)
            </b>

            <input
              maxLength={100}
              style={{
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                width: "100%",
              }}
              onChange={(e) => setInfracao(e.target.value)}
              type="text"
            />
          </div>
          <div>
            <label style={{ fontFamily: "Avenir Next" }}>Pontos</label>
            <input
              maxLength={3}
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                width: "100%",
              }}
              onChange={(e) => setPontos(e.target.value)}
              type="text"
            />
          </div>
        </div>
        <p style={{ fontFamily: "Avenir Next", marginTop: 30 }}>Moldura</p>
        <div style={{ display: "inline-flex", width: "100%" }}>
          <div style={{}}>
            <div style={{ display: "inline-flex" }}>
              <p tyle={{ fontFamily: "Avenir Next" }}>De</p>
              <input
                style={{
                  width: "100%",
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  fontFamily: "Avenir Next",
                  fontSize: 14,
                  borderBottomColor: "rgb(33, 150, 243)",
                  width: "100%",
                }}
                onChange={(e) => setMoldura(e.target.value)}
                type="text"
              />
              <p tyle={{ fontFamily: "Avenir Next" }}>€</p>
            </div>
          </div>
          <div style={{ marginLeft: 25 }}>
            <div style={{ display: "inline-flex" }}>
              <p tyle={{ fontFamily: "Avenir Next" }}>A</p>
              <input
                style={{
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  fontFamily: "Avenir Next",
                  fontSize: 14,
                  borderBottomColor: "rgb(33, 150, 243)",
                  width: "100%",
                }}
                onChange={(e) => setMoldura2(e.target.value)}
                type="text"
              />
              <p tyle={{ fontFamily: "Avenir Next" }}>€</p>
            </div>
          </div>
        </div>

        <button
          style={{
            backgroundColor: "#2196f3",
            marginBottom: 15,
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
          }}
          type="text"
        >
          Submeter
        </button>
      </form>
      <table
        style={{
          backgroundColor: "#e8e8e9",
          marginTop: 15,
          paddingBottom: 10,
          paddingLeft: 10,
          paddingTop: 10,
          justifyContent: "space-evenly",
          width: "100%",
          paddingRight: 20,
        }}
      >
        <tr style={{ borderRightWidth: 1 }}>
          <td
            style={{
              borderLeftWidth: 1,
              borderLeftColor: "#000",
              width: 500,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Infração
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Pontos
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            De
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            A
          </td>
        </tr>
      </table>
      {loading == false ? (
        <>
          {infracoes.map((item, index) => (
            <div>
              <div className="box" key={index}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/infracao/${index}`}
                  state={{ item: { item, index, infracoes } }}
                >
                  <InfracoesCard key={index} value={item} />
                </Link>
              </div>
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
};

export default GestInfracoes;
