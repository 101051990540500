import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { db } from "../firebase";

const EditReferral = () => {
  const clientId = useParams();
  console.log(clientId.clientId);

  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState("");
  const [referal, setReferal] = useState("");
  const [phone, setPhone] = useState("");
  const [nif, setNif] = useState("");

  const [descontoGiven, setDescontoGiven] = useState("");
  const [referralUses, setReferralUses] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const fetchClient = async () => {
        const docRef = doc(db, "users", clientId.clientId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const affairData = docSnap.data();

          setPhone(affairData.phone);
          setName(affairData.fname + " " + affairData.lname);
          setReferal(affairData.referal);

          setNif(affairData.nif);

          setDescontoGiven(affairData.desconto_given);
          setReferralUses(affairData.referral_uses);
        } else {
          console.log("No such document!");
        }
      };

      await Promise.all([fetchClient()]);

      setLoading(false); // Set loading state to false
    };

    fetchData();
  }, [clientId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, "users", clientId.clientId), {
        desconto_given: descontoGiven,
      });
      console.log("Document Added");
      alert("Informações guardadas com sucesso.");
    } catch (e) {
      console.log(e.message);
    }
  };

  if (loading) {
    return (
      <div style={{ marginLeft: 300 }}>
        <h1>Loading</h1>
      </div>
    );
  }

  return (
    <div style={{ marginLeft: 300 }}>
      <h1 style={{ fontFamily: "Avenir Next" }}>Editar Desconto</h1>

      <form onSubmit={handleSubmit}>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 10,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Nome
            </td>
            <p
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                fontWeight: "500",
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
            >
              <Link to={`/clientes/${clientId.clientId}`}>{name}</Link>
            </p>
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 10,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              NIF
            </td>
            <p
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                fontWeight: "500",
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
            >
              {nif != "" ? nif : "Não definido"}
            </p>
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 10,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Telemóvel
            </td>
            <p
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                fontWeight: "500",
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
            >
              {phone != "" ? phone : "Não definido"}
            </p>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 10,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Código
            </td>
            <p
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                fontWeight: "500",
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
            >
              {referal}
            </p>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 10,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Desconto
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              value={descontoGiven}
              onChange={(e) => setDescontoGiven(e.target.value)}
              type="text"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 10,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Número de usos
            </td>
            <p
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
            >
              {referralUses}
            </p>
          </tr>
        </table>

        <button
          style={{
            backgroundColor: "#2196f3",
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
            marginLeft: 0,
          }}
          type="text"
        >
          Guardar
        </button>
      </form>
    </div>
  );
};

export default EditReferral;
