import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  getMetadata,
  deleteObject,
} from "firebase/storage";

import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import Select from "react-select";

function EditSubscritor(props) {
  const { clientId } = useParams();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [clientFiles, setClientFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [isProtocolo, setIsProtocolo] = useState(false);
  const [isMotorista, setIsMotorista] = useState(false);
  const [pais, setPais] = useState("");
  const [freguesia, setFreguesia] = useState("");
  const [concelho, setConcelho] = useState("");
  const [morada, setMorada] = useState("");
  const [pontos, setPontos] = useState("");
  const [nif, setNif] = useState("");
  const [gestor, setGestor] = useState(null);
  const [observacoes, setObservacoes] = useState("");
  const [desconto, setDesconto] = useState("");
  const [inf_graves, setInf_graves] = useState("");
  const [inf_mtgraves, setInf_mtgraves] = useState("");
  const [crimes, setCrimes] = useState("");
  const [sancoes, setSancoes] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [admins, setAdmins] = useState();

  const fetchAdmins = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "administradores"));
      const docList = [];
      setAdmins([]);
      querySnapshot.docs.map((doc) =>
        docList.push({
          value: { ...doc.data(), id: doc.id },
          label: doc.data().fname + " " + doc.data().lname,
        })
      );
      setAdmins(docList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchAdmins();
  }, [clientId]);

  const fetchData = async () => {
    const fetchClient = async () => {
      const docRef = doc(db, "users", clientId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const affairData = docSnap.data();
        const gestorId = affairData.gestorId;

        if (gestorId) {
          const gestorDocRef = doc(db, "administradores", gestorId);
          const gestorDocSnap = await getDoc(gestorDocRef);

          if (gestorDocSnap.exists()) {
            const gestorData = {
              ...gestorDocSnap.data(),
              id: gestorDocSnap.id,
            };
            console.log(gestorData);

            setEmail(affairData.email);
            setPhone(affairData.phone);
            setFname(affairData.fname);
            setGestor(gestorData);
            setLname(affairData.lname);
            setPais(affairData.pais);
            setFreguesia(affairData.freguesia);
            setConcelho(affairData.concelho);
            setMorada(affairData.morada);
            setPontos(affairData.pontos);
            setIsProtocolo(affairData.isProtocolo);
            setIsMotorista(affairData.isMotorista);
            setNif(affairData.nif);
            setObservacoes(affairData.observacoes);
            setDesconto(affairData.desconto);
            setInf_graves(affairData.inf_graves);
            setInf_mtgraves(affairData.inf_mtgraves);
            setCrimes(affairData.crimes);
            setSancoes(affairData.sancoes);
            setClientFiles(affairData.files);
            fetchFileNames(affairData.files);
          } else {
            console.log("User document does not exist!");
          }
        } else {
          console.log("User field is not present in the document!");
        }
      } else {
        console.log("No such document!");
      }
    };

    await Promise.all([fetchClient()]);

    setIsLoading(false); // Set loading state to false
  };

  const fetchFileNames = async (files) => {
    const storage = getStorage();
    const names = await Promise.all(
      files.map(async (file) => {
        const fileRef = ref(storage, file);
        const metadata = await getMetadata(fileRef);
        return metadata.name || "Unnamed File";
      })
    );
    setFileNames(names);
  };

  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files);
    setSelectedFiles(filesArray);
  };

  const handleUpload = async () => {
    // Upload files to Firebase Storage and get download URLs
    const storage = getStorage();

    const downloadURLs = await Promise.all(
      selectedFiles.map(async (file) => {
        const storageRef = ref(storage, `Clientes/${clientId}/${file.name}`);
        await uploadBytes(storageRef, file);
        return getDownloadURL(storageRef);
      })
    );

    // Save download URLs in Firestore under the user's document
    const userRef = doc(db, "users", clientId);
    await updateDoc(userRef, { files: [...clientFiles, ...downloadURLs] });

    console.log("Download URLs:", downloadURLs);

    // Update the fetched data
    fetchData();
  };

  const handleDelete = async (index, fileURL) => {
    const storage = getStorage();
    const fileRef = ref(storage, fileURL);

    // Delete the file from Firebase Storage
    await deleteObject(fileRef);

    // Remove the file URL from clientFiles state
    const updatedClientFiles = clientFiles.filter((url, i) => i !== index);
    setClientFiles(updatedClientFiles);

    // Update the fetched data
    const userRef = doc(db, "users", clientId);
    await updateDoc(userRef, { files: updatedClientFiles });

    // Update the file names
    fetchFileNames(updatedClientFiles);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedFiles.length > 0) {
      handleUpload();
    }
    console.log(gestor.id);
    try {
      await updateDoc(doc(db, "users", clientId), {
        fname,
        lname,
        email,
        phone,
        isProtocolo,
        isMotorista,
        nif,
        pais,
        freguesia,
        morada,
        pontos,
        gestorId: gestor.id,
        observacoes,
        desconto,
        inf_graves,
        inf_mtgraves,
        crimes,
        sancoes,
      });
      console.log("Document Added");
      alert("Informações guardadas com sucesso.");
    } catch (e) {
      console.log(e.message);
    }
  };

  if (isLoading) {
    return (
      <div style={{ marginLeft: 300 }}>
        <h1>Loading</h1>
      </div>
    );
  }

  return (
    <div>
      <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
        Editar: Ficha de Cliente
      </h1>
      <form onSubmit={handleSubmit}>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Primeiro Nome
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setFname(e.target.value)}
              value={fname}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Último Nome
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setLname(e.target.value)}
              value={lname}
              type="text"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Email
            </td>
            <input
              style={{
                width: "95%",

                fontFamily: "Avenir Next",
                fontSize: 14,

                backgroundColor: "transparent",
              }}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="text"
              readOnly={true}
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Protocolo
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              type="checkbox"
              checked={isProtocolo}
              onChange={() => setIsProtocolo(!isProtocolo)}
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Motorista
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              type="checkbox"
              checked={isMotorista}
              onChange={() => setIsMotorista(!isMotorista)}
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Telemóvel
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              type="text"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              País
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setPais(e.target.value)}
              value={pais}
              type="text"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Freguesia
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setFreguesia(e.target.value)}
              value={freguesia}
              type="text"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Concelho
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setConcelho(e.target.value)}
              value={concelho}
              type="text"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Morada
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setMorada(e.target.value)}
              value={morada}
              type="text"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              <div>
                {fileNames.map((fileName, index) => (
                  <div key={index}>
                    <p>{fileName}</p>
                    <button
                      type="button"
                      onClick={() => window.open(clientFiles[index], "_blank")}
                    >
                      Abrir
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDelete(index, clientFiles[index])}
                    >
                      Apagar
                    </button>
                  </div>
                ))}
              </div>
            </td>
            <input type="file" multiple onChange={handleFileChange} />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              NIF
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setNif(e.target.value)}
              value={nif}
              type="text"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Gestor de cliente
            </td>
            <Select
              options={admins}
              value={admins.find(
                (option) => option.label === gestor.fname + " " + gestor.lname
              )}
              onChange={(value) => setGestor(value.value)}
              placeholder="Selecionar"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Observações
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setObservacoes(e.target.value)}
              value={observacoes}
              type="text"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Desconto
            </td>
            <input
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setDesconto(e.target.value)}
              value={desconto}
              type="text"
            />
          </tr>
        </table>

        <div>
          <h1 style={{ fontFamily: "Avenir Next", marginLeft: 300 }}>
            Registo Individual do Condutor
          </h1>
          <table
            style={{
              backgroundColor: "#fff",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Pontos
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                <input
                  style={{
                    width: "95%",
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    fontFamily: "Avenir Next",
                    fontSize: 14,
                    borderBottomColor: "rgb(33, 150, 243)",
                    backgroundColor: "transparent",
                  }}
                  onChange={(e) => setPontos(e.target.value)}
                  value={pontos}
                  type="text"
                />
              </td>
            </tr>
          </table>

          <table
            style={{
              backgroundColor: "#e8e8e9",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Infrações graves
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                <input
                  style={{
                    width: "95%",
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    fontFamily: "Avenir Next",
                    fontSize: 14,
                    borderBottomColor: "rgb(33, 150, 243)",
                    backgroundColor: "transparent",
                  }}
                  onChange={(e) => setInf_graves(e.target.value)}
                  value={inf_graves}
                  type="text"
                />
              </td>
            </tr>
          </table>

          <table
            style={{
              backgroundColor: "#fff",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Infrações muito graves
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                <input
                  style={{
                    width: "95%",
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    fontFamily: "Avenir Next",
                    fontSize: 14,
                    borderBottomColor: "rgb(33, 150, 243)",
                    backgroundColor: "transparent",
                  }}
                  onChange={(e) => setInf_mtgraves(e.target.value)}
                  value={inf_mtgraves}
                  type="text"
                />
              </td>
            </tr>
          </table>

          <table
            style={{
              backgroundColor: "#e8e8e9",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Crimes
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                <input
                  style={{
                    width: "95%",
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    fontFamily: "Avenir Next",
                    fontSize: 14,
                    borderBottomColor: "rgb(33, 150, 243)",
                    backgroundColor: "transparent",
                  }}
                  onChange={(e) => setCrimes(e.target.value)}
                  value={crimes}
                  type="text"
                />
              </td>
            </tr>
          </table>

          <table
            style={{
              backgroundColor: "#fff",
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 300,
              justifyContent: "space-evenly",
              width: "100%",
              paddingRight: 20,
            }}
          >
            <tr style={{ borderRightWidth: 1 }}>
              <td
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "#000",
                  width: "70%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Sanções
              </td>
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                <input
                  style={{
                    width: "95%",
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    fontFamily: "Avenir Next",
                    fontSize: 14,
                    borderBottomColor: "rgb(33, 150, 243)",
                    backgroundColor: "transparent",
                  }}
                  onChange={(e) => setSancoes(e.target.value)}
                  value={sancoes}
                  type="text"
                />
              </td>
            </tr>
          </table>
        </div>

        <button
          style={{
            backgroundColor: "#2196f3",
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
            marginLeft: 300,
          }}
          type="text"
        >
          Guardar
        </button>
      </form>
    </div>
  );
}

export default EditSubscritor;
