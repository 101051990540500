import React from 'react'


function Footer() {
  return (
    <div style={{width: "100%", bottom: 0, position: "fixed", display: "inline-flex", zIndex: -1}}>
        <h3 style={{marginLeft: 25, color: "#444", fontFamily: "Avenir Next", fontWeight: "500", fontSize: 14}}>MULTA ZERO</h3>
        <h3 style={{right: 25, position: "absolute", color: "#444", fontFamily: "Avenir Next", fontWeight: "500", fontSize: 14}}>© 2022 <a href="https://multazero.pt" target="_blank" style={{color: "#2196f3"}} >Multa Zero</a>.</h3>
    </div>
  )
}

export default Footer