import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../firebase";
import { Link } from "react-router-dom";

const Referrals = () => {
  const [subscritores, setSubscritores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queryy, setQueryy] = useState("");

  const fetchSubscritores = async () => {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, "users"), orderBy("created_at", "desc"))
      );

      const docList = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setSubscritores(docList);

      if (loading) {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching subscritores:", error);
    }
  };

  useEffect(() => {
    fetchSubscritores();
  });

  return (
    <div style={{ marginLeft: 300 }}>
      <h1 style={{ fontFamily: "Avenir Next" }}>Referrals</h1>
      <input
        placeholder="Pesquisar por nome"
        type="text"
        value={queryy}
        onChange={(e) => {
          setQueryy(e.target.value);
        }}
        style={{
          width: 530,
          paddingBottom: 5,
          backgroundColor: "transparent",
          color: "#000",
          fontFamily: "Avenir Next",
          fontSize: "14px",
          border: 0,
          borderBottom: "1px solid rgb(33, 150, 243)",
        }}
      />

      <table
        style={{
          backgroundColor: "#e8e8e9",
          marginTop: 15,
          paddingBottom: 10,
          paddingLeft: 10,
          paddingTop: 10,
          justifyContent: "space-evenly",
          width: "100%",
          paddingRight: 20,
        }}
      >
        <tr style={{ borderRightWidth: 1 }}>
          <td
            style={{
              borderLeftWidth: 1,
              borderLeftColor: "#000",
              width: 300,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Nome
          </td>
          <td
            style={{
              borderLeftWidth: 1,
              borderLeftColor: "#000",
              width: 300,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            NIF
          </td>
          <td
            style={{
              borderLeftWidth: 1,
              borderLeftColor: "#000",
              width: 300,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Telemóvel
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 300,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Código
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Desconto
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Nº de usos
          </td>
        </tr>
      </table>
      {loading == false ? (
        <div>
          {subscritores
            .filter((user) => {
              if (queryy === "") {
                return user;
              } else if (
                user.fname.toLowerCase().includes(queryy.toLowerCase())
              ) {
                return user;
              } else if (
                user.email.toLowerCase().includes(queryy.toLowerCase())
              ) {
                return user;
              } else if (
                user.phone.toLowerCase().includes(queryy.toLowerCase())
              ) {
                return user;
              } else if (
                user.nif.toLowerCase().includes(queryy.toLowerCase())
              ) {
                return user;
              }
            })
            .map((item, index) => (
              <div>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/referrals/${item.id}/edit`}
                  state={{ item }}
                >
                  <table
                    border={1}
                    style={{
                      backgroundColor: "#fff",
                      borderColor: "#e8e8e9",
                      borderWidth: 0.6,
                      paddingBottom: 10,
                      paddingLeft: 10,
                      marginTop: 5,
                      paddingTop: 10,
                      justifyContent: "space-evenly",
                      width: "100%",
                      paddingRight: 20,
                    }}
                  >
                    <tr style={{ borderRightWidth: 1 }}>
                      <td
                        style={{
                          borderWidth: 0,
                          width: 300,
                          fontFamily: "Avenir Next",
                          fontWeight: "500",
                          color: "#000",
                        }}
                      >
                        {item.fname} {item.lname}
                      </td>
                      <td
                        style={{
                          borderWidth: 0,
                          width: 300,
                          fontFamily: "Avenir Next",
                          fontWeight: "500",
                          color: "#000",
                        }}
                      >
                        {item.nif != "" ? item.nif : "Não definido"}
                      </td>
                      <td
                        style={{
                          borderWidth: 0,
                          width: 300,
                          fontFamily: "Avenir Next",
                          fontWeight: "500",
                          color: "#000",
                        }}
                      >
                        {item.phone != "" ? item.phone : "Não definido"}
                      </td>
                      <td
                        style={{
                          borderWidth: 0,
                          width: 300,
                          fontFamily: "Avenir Next",
                          fontWeight: "500",
                          color: "#000",
                        }}
                      >
                        {item.referal}
                      </td>
                      <td
                        style={{
                          borderWidth: 0,
                          width: 100,
                          fontFamily: "Avenir Next",
                          fontWeight: "500",
                          color: "#000",
                        }}
                      >
                        {item.desconto_given}%
                      </td>
                      <td
                        style={{
                          borderWidth: 0,
                          width: 100,
                          fontFamily: "Avenir Next",
                          fontWeight: "500",
                          color: "#000",
                        }}
                      >
                        {item.referral_uses.length}
                      </td>
                    </tr>
                  </table>
                </Link>
              </div>
            ))}
        </div>
      ) : null}
    </div>
  );
};

export default Referrals;
