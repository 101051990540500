import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { Link, useParams } from "react-router-dom";
import { db } from "../firebase";

function Atividade(props) {
  const { tarefaId } = useParams();

  const [tarefa, setTarefa] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTarefa = async () => {
      const docRef = doc(db, "atividades", tarefaId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const atividadeData = docSnap.data();
        atividadeData.id = docSnap.id; // Add the document ID as id field
        const userDocRef = doc(db, "users", atividadeData.user);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          atividadeData.user = userData;
          setTarefa(atividadeData);
        } else {
          console.log("User document not found!");
        }
      } else {
        console.log("No such document!");
      }

      setLoading(false);
    };

    fetchTarefa();
  }, [tarefaId]);

  if (loading) {
    return <h1 style={{ marginLeft: 300 }}>Loading...</h1>;
  }

  return (
    <div>
      <div>
        <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>Tarefa</h1>
        <Link
          style={{ textDecoration: "none" }}
          to={`/tarefas/${tarefa.id}/edit`}
        >
          <button
            style={{
              marginLeft: 300,
              marginTop: -15,
              marginBottom: 30,
              padding: 10,
              borderRadius: 5,
              border: 0,
              width: 120,
              fontSize: 14,
              color: "#000",
              fontWeight: "500",
              cursor: "pointer",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
              backgroundColor: "#e8e8e9",
              fontFamily: "Avenir Next",
            }}
          >
            Editar
          </button>
        </Link>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Processo
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              <Link
                style={{ textDecoration: "none" }}
                to={`/assuntos/${tarefa.processo}`}
              >
                {tarefa.processo}
              </Link>
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Colaborador
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {tarefa.admin}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Observações
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: "30%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              {tarefa.observacoes}
            </td>
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de Conclusão
            </td>
            {tarefa.dataConclu != null ? (
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                {String(new Date(tarefa.dataConclu.seconds * 1000).getDate()) +
                  "/" +
                  String(
                    new Date(tarefa.dataConclu.seconds * 1000).getMonth() + 1
                  ) +
                  "/" +
                  String(
                    new Date(tarefa.dataConclu.seconds * 1000).getFullYear()
                  )}
              </td>
            ) : (
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Não definida
              </td>
            )}
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data Prevista
            </td>
            {tarefa.dataPrevA != null ? (
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                {String(new Date(tarefa.dataPrevA.seconds * 1000).getDate()) +
                  "/" +
                  String(
                    new Date(tarefa.dataPrevA.seconds * 1000).getMonth() + 1
                  ) +
                  "/" +
                  String(
                    new Date(tarefa.dataPrevA.seconds * 1000).getFullYear()
                  )}
              </td>
            ) : (
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Não definida
              </td>
            )}
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Estado
            </td>
            {tarefa.dataConclu != null ? (
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                {tarefa.estado}
              </td>
            ) : (
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Não definida
              </td>
            )}
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Cliente
            </td>
            {tarefa.dataConclu != null ? (
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                {tarefa.user.fname} {tarefa.user.lname}
              </td>
            ) : (
              <td
                style={{
                  borderWidth: 1,
                  borderColor: "#000",
                  width: "30%",
                  fontFamily: "Avenir Next",
                  fontWeight: "500",
                }}
              >
                Não definida
              </td>
            )}
          </tr>
        </table>
      </div>
    </div>
  );
}

export default Atividade;
