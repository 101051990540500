import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Navigate, useLocation, useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import AtividadeCard from "../components/cards/AtividadeCard";
import DatePicker from "react-date-picker";
import { UserAuth } from "../context/AuthContext";

function EditCrm(props) {
  const { crmId } = useParams();

  const { user } = UserAuth();
  const navigate = useNavigate();

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [contacto, setContacto] = useState("");
  const [estado, setEstado] = useState("");
  const [dataPrevi, onChange] = useState(null);
  const [dataRea, onChange2] = useState(null);
  const [observacoes, setObservacoes] = useState("");
  const [gestorId, setGestorId] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCrm = async () => {
      const docRef = doc(db, "crm", crmId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const atividadeData = docSnap.data();
        atividadeData.id = docSnap.id;
        setFname(atividadeData.fname);
        setLname(atividadeData.lname);
        setEmail(atividadeData.email);
        setPhone(atividadeData.phone);
        setContacto(atividadeData.tipo);
        setEstado(atividadeData.feito);
        onChange(
          atividadeData.dataPrev != null
            ? new Date(atividadeData.dataPrev.seconds * 1000)
            : atividadeData.dataPrev
        );
        onChange2(
          atividadeData.dataRea != null
            ? new Date(atividadeData.dataRea.seconds * 1000)
            : atividadeData.dataRea
        );
        setGestorId(atividadeData.gestorId);
      }

      setLoading(false);
    };

    fetchCrm();
  }, [crmId]);

  if (loading) {
    <h1 style={{ marginLeft: 300 }}>A carregar...</h1>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, "crm", crmId), {
        fname,
        lname,
        email,
        phone,
        feito: estado,
        tipo: contacto,
        dataPrev: dataPrevi,
        dataRea,
        observacoes,
      });
      console.log("Document Added");
      alert("Informações guardadas com sucesso.");
      navigate("/crm");
    } catch (e) {
      console.log(e.message);
    }
  };

  const deleteCrm = async () => {
    try {
      await deleteDoc(doc(db, "crm", crmId));
      navigate("/");
    } catch (e) {
      console.log(e.message);
    }
  };

  const generateReferal = () => {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const becomeClient = async () => {
    try {
      const dbRef = collection(db, "users");
      const addedDocRef = await addDoc(dbRef, {
        fname: fname,
        lname: lname,
        email: email,
        phone: phone,
        referral: "",
        isMotorista: false,
        isProtocolo: false,
        referal: generateReferal(),

        pais: "",
        freguesia: "",
        concelho: "",
        morada: "",

        nif: "",
        gestorId: gestorId,
        observacoes: observacoes,
        desconto: "",
        pontos: 12,

        nifMotorista: "",
        nifComProtocolo: "",
        files: [],
        inf_graves: "",
        inf_mtgraves: "",
        crimes: "",
        sancoes: "",
        bo_created: true,
        referral_uses: 0,
        desconto_given: "5",
        hasPayed: false,
        addedFiles: [],
        FrontCartadeConducao: "",
        BackCartadeConducao: "",
        FrontDocumentodeIdentificacao: "",
        BackDocumentodeIdentificacao: "",
        dateExpCartadeConducao: null,
        dateExpDocumentodeIdentificacao: null,
        descontoEmpresa: "0",
        referals: [],
        usedReferals: [],
      });
      const addedDocId = addedDocRef.id;
      await deleteCrm();
      navigate(`/clientes/${addedDocId}`);
    } catch (e) {
      console.log(e.message);
    }
  };

  const options = [
    { value: "Email", label: "Email" },
    { value: "Telefone", label: "Telefone" },
    { value: "SMS", label: "SMS" },
    { value: "Whatsapp", label: "Whatsapp" },
    { value: "Video-chamada", label: "Video-chamada" },
    { value: "Messenger FB", label: "Messenger FB" },
    { value: "Reunião Presencial", label: "Reunião Presencial" },
  ];
  const options2 = [
    { value: "Com Sucesso", label: "Com Sucesso" },
    { value: "Por Realizar", label: "Por Realizar" },
    { value: "Adiado", label: "Adiado" },
    { value: "Cancelado", label: "Cancelado" },
    { value: "Contacto sem Sucesso", label: "Contacto sem Sucesso" },
    {
      value: "Cliente solicita contacto em nova data/hora",
      label: "Cliente solicita contacto em nova data/hora",
    },
    { value: "Cancelado por tentativas", label: "Cancelado por tentativas" },
    {
      value: "Cancelado a pedido do cliente",
      label: "Cancelado a pedido do cliente",
    },
  ];

  return (
    <div>
      <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>Editar CRM</h1>

      <form onSubmit={handleSubmit}>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Nome
            </td>
            <input
              maxLength={65}
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setFname(e.target.value)}
              value={fname}
              type="text"
            />
            <input
              maxLength={65}
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setLname(e.target.value)}
              value={lname}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Email
            </td>
            <input
              maxLength={65}
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Telemóvel
            </td>
            <input
              maxLength={65}
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              type="text"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Tipo de contacto
            </td>
            <Select
              options={options}
              value={options.find((option) => option.value === contacto)}
              onChange={(value) => setContacto(value.value)}
              placeholder="Selecionar"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Estado
            </td>
            <Select
              options={options2}
              value={options2.find((option) => option.value === estado)}
              onChange={(value) => setEstado(value.value)}
              placeholder="Selecionar"
            />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data Prevista
            </td>
            <DatePicker onChange={onChange} value={dataPrevi} />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Data de Realização
            </td>
            <DatePicker onChange={onChange2} value={dataRea} />
          </tr>
        </table>

        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Observações
            </td>
            <input
              maxLength={65}
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setObservacoes(e.target.value)}
              value={observacoes}
              type="text"
            />
          </tr>
        </table>

        <button
          style={{
            backgroundColor: "#2196f3",
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 15,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
            marginLeft: 300,
          }}
          type="text"
        >
          Guardar
        </button>
        <button
          style={{
            borderWidth: 1,
            borderColor: "#2196f3",
            backgroundColor: "#fff",
            marginTop: 15,
            borderRadius: 5,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
            marginLeft: 0,
            color: "#2196f3",
          }}
          onClick={() => becomeClient()}
        >
          Cliente
        </button>
      </form>
      <button
        onClick={deleteCrm}
        style={{
          backgroundColor: "#f00",
          marginTop: -50,
          borderRadius: 5,
          border: 0,
          padding: 15,
          fontSize: 12,
          right: 0,
          position: "absolute",
          marginRight: 45,
          width: "100px",
          fontFamily: "Avenir Next",
          color: "#fff",
        }}
      >
        Apagar
      </button>
    </div>
  );
}

export default EditCrm;
