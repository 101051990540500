import React from "react";
import { Link } from "react-router-dom";
import "@fontsource/material-icons";

import { UserAuth } from "../context/AuthContext";

const Header = () => {
  const { user } = UserAuth();

  if (user.fname == null) {
    return;
  }
  return (
    <nav
      style={{
        position: "absolute",
        width: "100%",
        marginTop: 0,
        backgroundColor: "#e8e8e9",
      }}
    >
      <div style={{ position: "absolute", right: 30, display: "inline-flex" }}>
        {/* <Link to="/"><i class="material-icons" style={{marginTop: -5, marginLeft: 35, color: "#8c8c8c"}}>dashboard</i></Link> 
        <i class="material-icons" style={{marginTop: -5, marginLeft: 35, color: "#8c8c8c"}}>notifications_active</i>  */}
        <Link to="/conta">
          <i
            class="material-icons"
            style={{ marginTop: -5, marginLeft: 35, color: "#8c8c8c" }}
          >
            settings
          </i>
        </Link>
      </div>
    </nav>
  );
};

export default Header;
