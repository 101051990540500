import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  getDoc,
  serverTimestamp,
  arrayUnion,
} from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { auth, db } from "../firebase";
import UtilizadorCard from "../components/cards/UtilizadorCard";
import Select from "react-select";
import { fetchSignInMethodsForEmail } from "firebase/auth";

const GestUtilizadores = () => {
  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [nif, setNif] = useState("");
  const [nivel, setNivel] = useState("");
  const [estatuto, setEstatuto] = useState("");
  const [number, setNumber] = useState(0);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [adminId, setAdminId] = useState("");
  const fetchUserCount = async () => {
    try {
      const docRef = doc(db, "global", "variables");
      const docSnap = await getDoc(docRef);
      const userCount = docSnap.data().number2;
      const currentYear = new Date().getFullYear();
      const paddedCount = userCount.toString().padStart(3, "0");
      const userId = `B-${paddedCount}-${currentYear}`;

      setAdminId(userId);
      setNumber(userCount);
    } catch (error) {
      console.error("Error fetching user count:", error);
    }
  };
  const fetchUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "administradores"));
      const docList = [];
      setUsers([]);
      querySnapshot.docs.map((doc) =>
        docList.push({ ...doc.data(), id: doc.id })
      );
      setUsers(docList);

      if (loading) {
        setLoading(false);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    fetchUserCount();
    fetchUsers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);

      if (signInMethods && signInMethods.length > 0) {
        // Email already exists, display an alert and return early
        alert(
          "O endereço de e-mail já está associado a uma conta de utilizador na app MZ. Por favor, escolha outro endereço de e-mail."
        );
        return;
      }
    } catch (error) {}

    try {
      await setDoc(doc(db, "administradores", adminId), {
        email,
        fname,
        lname,
        estatuto: estatuto.value,
        nif,
        nivel: nivel.value,
        creation: serverTimestamp(),
        adminId,
        isActive: true,
      });

      if (estatuto.value === "Advogado") {
        await updateDoc(doc(db, "global", "gestores"), {
          docList: arrayUnion(adminId),
        });
      }

      console.log("Document Added");
      await updateDoc(doc(db, "global", "variables"), {
        number2: parseInt(number) + 1,
      });
      fetchUserCount();
      fetchUsers();
      alert("Informações guardadas com sucesso.");
    } catch (e) {
      console.log(e.message);
    }
  };

  const options = [
    { value: "Advogado", label: "Advogado" },
    { value: "Gestor de cliente", label: "Gestor de cliente" },
    { value: "CEO", label: "CEO" },
    { value: "COO", label: "COO" },
    {
      value: "Coordenador de equipa jurídica",
      label: "Coordenador de equipa jurídica",
    },
  ];
  const options2 = [
    { value: "Geral", label: "Geral" },
    { value: "Admin", label: "Admin" },
  ];
  return (
    <div style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
      <div>
        <h1 style={{ fontFamily: "Avenir Next" }}>Adicionar Utilizador</h1>

        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: 30 }}>
            <label style={{ fontFamily: "Avenir Next" }}>Primeiro Nome</label>
            <b
              style={{ marginLeft: 5, fontSize: 12, fontFamily: "Avenir Next" }}
            >
              ({fname.length}/20)
            </b>

            <br></br>
            <input
              maxLength={20}
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
              }}
              onChange={(e) => setFname(e.target.value)}
              type="text"
            />
          </div>
          <div style={{ marginBottom: 30 }}>
            <label style={{ fontFamily: "Avenir Next" }}>Último Nome</label>
            <b
              style={{ marginLeft: 5, fontSize: 12, fontFamily: "Avenir Next" }}
            >
              ({lname.length}/20)
            </b>

            <br></br>
            <input
              maxLength={20}
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
              }}
              onChange={(e) => setLname(e.target.value)}
              type="text"
            />
          </div>
          <div style={{ marginBottom: 30 }}>
            <label style={{ fontFamily: "Avenir Next" }}>Email</label>
            <b
              style={{ marginLeft: 5, fontSize: 12, fontFamily: "Avenir Next" }}
            >
              ({email.length}/30)
            </b>

            <br></br>
            <input
              maxLength={30}
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
              }}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
          </div>
          <div style={{ marginBottom: 30 }}>
            <label style={{ fontFamily: "Avenir Next" }}>NIF</label>
            <b
              style={{ marginLeft: 5, fontSize: 12, fontFamily: "Avenir Next" }}
            >
              ({nif.length}/9)
            </b>

            <br></br>
            <input
              maxLength={9}
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
              }}
              onChange={(e) => setNif(e.target.value)}
              type="text"
            />
          </div>
          <div style={{ marginBottom: 30, width: "95%" }}>
            <label style={{ fontFamily: "Avenir Next" }}>Estatuto</label>
            <br></br>
            <Select
              options={options}
              onChange={setEstatuto}
              placeholder="Selecionar"
            />
          </div>
          <div style={{ marginBottom: 30, width: "95%" }}>
            <label style={{ fontFamily: "Avenir Next" }}>Nivel de acesso</label>
            <br></br>
            <Select
              options={options2}
              onChange={setNivel}
              placeholder="Selecionar"
            />
          </div>

          <button
            style={{
              backgroundColor: "#2196f3",
              marginTop: 15,
              borderRadius: 5,
              border: 0,
              padding: 15,
              fontSize: 12,
              right: 0,
              marginRight: 45,
              width: "100px",
              fontFamily: "Avenir Next",
              color: "#fff",
              right: 0,
            }}
            type="text"
          >
            Criar Conta
          </button>
        </form>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            marginTop: 15,
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 10,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: 300,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Nome
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: 200,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              E-mail
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: 100,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              NIF
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: 150,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Estatuto
            </td>
            <td
              style={{
                borderWidth: 1,
                borderColor: "#000",
                width: 150,
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Nível de Acesso
            </td>
          </tr>
        </table>
        {users.map((user, index) => (
          <div className="box" key={index}>
            <Link
              style={{ textDecoration: "none" }}
              to={`/utilizador/${user.id}`}
              state={{ user }}
            >
              <UtilizadorCard key={index} value={user} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GestUtilizadores;
