import React from 'react'


const Ausencias = () => {
  return (
    <div>
      <h1 style={{marginLeft: 300}}>Ausencias</h1>
    </div>
  )
}

export default Ausencias