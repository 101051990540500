import React, { useState, useEffect } from "react";
import { doc, updateDoc, getFirestore, onSnapshot } from "firebase/firestore";

import { db } from "../firebase";

const GestReuni = () => {
  const [timeSlots, setTimeSlots] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({});

  const daysOfWeek = ["seg", "ter", "qua", "qui", "sex", "sab"];

  // Generate time slots from 09:00 to 22:00
  const generateTimeSlots = () => {
    const slots = [];
    const startTime = 9;
    const endTime = 22;

    for (let hour = startTime; hour <= endTime; hour++) {
      for (let minute = 0; minute <= 40; minute += 20) {
        const time = `${hour.toString().padStart(2, "0")}:${minute
          .toString()
          .padStart(2, "0")}`;
        slots.push(time);
      }
    }

    setTimeSlots(slots);
  };

  // Checkbox change handler
  const handleCheckboxChange = (timeSlot, dayOfWeek) => async (event) => {
    const formattedDayOfWeek = mapDayOfWeek(dayOfWeek);
    const formattedTimeSlot = `${formattedDayOfWeek}-${timeSlot.replace(
      ":",
      "_"
    )}`;

    try {
      await updateDoc(doc(db, "global", "reunioes"), {
        [formattedTimeSlot]: event.target.checked,
      });

      console.log("Checkbox state saved in Firestore.");
    } catch (error) {
      console.error("Error saving checkbox state:", error);
    }
  };

  // Fetch checkbox states from Firestore and subscribe to changes on component mount
  useEffect(() => {
    const docRef = doc(db, "global", "reunioes");
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        setCheckboxStates(data);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // Get the day of the week and map it to the desired format
  const mapDayOfWeek = (dayOfWeek) => {
    const mapping = {
      sábado: "sab",
    };

    return mapping[dayOfWeek.toLowerCase()] || dayOfWeek;
  };

  // Initialize time slots on component mount
  useEffect(() => {
    generateTimeSlots();
  }, []);

  return (
    <div style={{ marginLeft: 300, marginBottom: 30 }}>
      <h1 style={{ fontFamily: "Avenir Next" }}>Reuniões</h1>
      <table>
        <thead>
          <tr
            style={{
              borderWidth: 1,
              alignSelf: "center",
              fontFamily: "Avenir Next",
            }}
          >
            <th style={{ paddingBottom: 5, paddingRight: 10 }}></th>
            {daysOfWeek.map((day) => (
              <th
                style={{
                  width: 80,
                  textAlign: "center",
                  fontWeight: "500",
                  paddingBottom: 5,
                  fontFamily: "Avenir Next",
                }}
                key={day}
              >
                {day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {timeSlots.map((timeSlot) => (
            <tr
              style={{ fontFamily: "Avenir Next", alignSelf: "center" }}
              key={timeSlot}
            >
              <td style={{ paddingRight: 15 }}>{timeSlot}</td>
              {daysOfWeek.map((day) => {
                const formattedDayOfWeek = mapDayOfWeek(day);
                const formattedTimeSlot = `${formattedDayOfWeek}-${timeSlot.replace(
                  ":",
                  "_"
                )}`;
                const isChecked = checkboxStates[formattedTimeSlot] || false;

                return (
                  <td
                    style={{
                      width: 80,
                      textAlign: "center",
                      backgroundColor: isChecked
                        ? "rgb(33, 150, 243)"
                        : "#f2f2f2",
                      borderRadius: 5,
                    }}
                    key={day}
                  >
                    <input
                      type="checkbox"
                      style={{
                        width: "50px",
                        height: "50px",
                        marginLeft: 30,
                        backgroundColor: "rgb(33, 150, 243)",
                        borderRadius: "4px",
                        border: "none",
                        cursor: "pointer",
                        alignSelf: "center",
                      }}
                      onChange={handleCheckboxChange(timeSlot, day)}
                      checked={isChecked}
                    />
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GestReuni;
