const UserCard = (props) => {
  return (
    <div>
      <table
        border={1}
        style={{
          backgroundColor: "#fff",
          borderColor: "#e8e8e9",
          borderWidth: 0.6,
          paddingBottom: 10,
          marginTop: 5,
          paddingTop: 10,
          paddingLeft: 300,
          justifyContent: "space-evenly",
          width: "100%",
          paddingRight: 20,
        }}
      >
        <tr style={{ borderRightWidth: 1 }}>
          <td
            style={{
              borderWidth: 0,
              width: 300,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.fname} {props.value.lname}
          </td>
          <td
            style={{
              borderWidth: 0,
              width: 300,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.email}
          </td>
          <td
            style={{
              borderWidth: 0,
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.phone}
          </td>
          <td
            style={{
              borderWidth: 0,
              width: 100,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            NIF
          </td>
          <td
            style={{
              borderWidth: 0,
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {props.value.gestor.fname} {props.value.gestor.lname}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default UserCard;
