import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  query,
  where,
  orderBy,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { Link } from "react-router-dom";
import AtividadeCard from "../components/cards/AtividadeCard";
import DatePicker from "react-date-picker";

const Atividades = () => {
  const [selectedUser, setSelectedUsers] = useState(null);
  const [processo, setProcesso] = useState("");
  const [admin, setAdmin] = useState("");
  const [observacoes, setObservacoes] = useState("");
  const [dataPrevA, setDataPrevA] = useState(new Date());
  const [dataConclu, setDataConclu] = useState(new Date());
  const [estado, setEstado] = useState("");

  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [atividades, setAtividades] = useState([]);
  const [processos, setProcessos] = useState([]);

  const [filtro, setFiltro] = useState("");

  const [showDataConclu, setShowDataConclu] = useState(false);

  const dataHoje = new Date();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const fetchUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const docList = [];
      setUsers([]);
      querySnapshot.docs.map((doc) => {
        docList.push({
          value: { ...doc.data(), id: doc.id },
          label: doc.data().fname + " " + doc.data().lname,
        });
      });
      setUsers(docList);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAdmins = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "administradores"));
      const docList = [];
      setAdmins([]);
      querySnapshot.docs.map((doc) =>
        docList.push({
          value: doc.id,
          label: doc.data().fname + " " + doc.data().lname,
        })
      );
      setAdmins(docList);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAtividades = async () => {
    try {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const q1 = query(
        collection(db, "atividades"),
        where("dataPrevA", ">=", currentDate),
        orderBy("dataPrevA", "asc")
      );
      const q2 = query(
        collection(db, "atividades"),
        where("dataPrevA", "<", currentDate),
        orderBy("dataPrevA", "desc")
      );

      const querySnapshot1 = await getDocs(q1);
      const querySnapshot2 = await getDocs(q2);

      const docList1 = await Promise.all(
        querySnapshot1.docs.map(async (docu) => {
          const userDataRef = docu.data().user;
          const gestorDataRef = docu.data().admin;
          const userSnapshot = await getDoc(doc(db, "users", userDataRef));
          const userData = userSnapshot.data();
          const gestorSnapshot = await getDoc(
            doc(db, "administradores", gestorDataRef)
          );
          const gestorData = gestorSnapshot.data();
          return {
            ...docu.data(),
            id: docu.id,
            user: userData,
            gestor: gestorData,
          };
        })
      );

      const docList2 = await Promise.all(
        querySnapshot2.docs.map(async (docu) => {
          const userDataRef = docu.data().user;
          const gestorDataRef = docu.data().admin;
          const userSnapshot = await getDoc(doc(db, "users", userDataRef));
          const userData = userSnapshot.data();
          const gestorSnapshot = await getDoc(
            doc(db, "administradores", gestorDataRef)
          );
          const gestorData = gestorSnapshot.data();
          return {
            ...docu.data(),
            id: docu.id,
            user: userData,
            gestor: gestorData,
          };
        })
      );

      const combinedDocList = docList1.concat(docList2);

      setAtividades(combinedDocList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchAdmins();
    fetchAtividades();
  }, []);

  const fetchProcessos = async (userId) => {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, "processos"), where("userId", "==", userId))
      );

      const docList = [];
      querySnapshot.docs.map((doc) =>
        docList.push({ value: doc.id, label: doc.id })
      );

      setProcessos(docList);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dbRef = collection(db, "atividades");
      addDoc(dbRef, {
        user: selectedUser.id,
        processo,
        admin,
        observacoes,
        dataPrevA,
        dataConclu,
        estado,
        createdAt: serverTimestamp(),
      }).then((docRef) => {
        console.log("Document has been added successfully");
        fetchAtividades();
      });
      console.log("Document Added");
      alert("Informações guardadas com sucesso.");
    } catch (e) {
      console.log(e.message);
    }
  };

  const options = [
    { value: "", label: "Sem Filtro" },
    { value: "Por Realizar", label: "Por Realizar" },
    { value: "Realizada", label: "Realizada" },
    {
      value: "Cancelada a pedido do cliente",
      label: "Cancelada a pedido do cliente",
    },
    { value: "Cancelada", label: "Cancelada" },
  ];

  const filteredOptions = options;

  return (
    <div style={{ marginLeft: 300 }}>
      <h1 style={{ fontFamily: "Avenir Next" }}>Tarefas</h1>
      <h3 style={{ fontFamily: "Avenir Next", fontWeight: "bold" }}>
        Criar Nova
      </h3>
      <form onSubmit={handleSubmit} style={{ marginRight: 25 }}>
        <p style={{ fontFamily: "Avenir Next" }}>Cliente</p>
        <Select
          options={users}
          onChange={(user) => {
            setSelectedUsers(user.value);
            fetchProcessos(user.value.id);
          }}
          placeholder="Selecionar"
        />
        {selectedUser && (
          <>
            <h4 style={{ fontFamily: "Avenir Next" }}>
              {selectedUser.fname} {selectedUser.lname} - {selectedUser.email}
            </h4>

            <p style={{ fontFamily: "Avenir Next" }}>Processo</p>
            <Select
              options={processos}
              onChange={(processo) => setProcesso(processo.value)}
              placeholder="Selecionar"
            />
            <h1 style={{ fontFamily: "Avenir Next" }}>{processo.value}</h1>
          </>
        )}
        <p style={{ fontFamily: "Avenir Next" }}>Colaborador</p>
        <Select
          options={admins}
          onChange={(admin) => setAdmin(admin.value)}
          placeholder="Selecionar"
        />
        <h1 style={{ fontFamily: "Avenir Next" }}>{admin.value}</h1>
        <label style={{ fontFamily: "Avenir Next" }}>Observações</label>
        <b style={{ fontSize: 12, marginLeft: 5, fontFamily: "Avenir Next" }}>
          ({observacoes.length}/65)
        </b>

        <input
          maxLength={65}
          style={{
            width: "95%",
            borderWidth: 0,
            borderBottomWidth: 1,
            fontFamily: "Avenir Next",
            fontSize: 14,
            borderBottomColor: "rgb(33, 150, 243)",
            width: "100%",
          }}
          onChange={(e) => setObservacoes(e.target.value)}
          type="text"
        />

        <div style={{ display: "inline-flex" }}>
          <div>
            <p style={{ fontFamily: "Avenir Next" }}>Data de Conclusão</p>
            <DatePicker
              maxDate={dataHoje}
              onChange={setDataConclu}
              value={dataConclu}
            />
          </div>

          <div style={{ marginLeft: 25 }}>
            <p style={{ fontFamily: "Avenir Next" }}>Data Prevista</p>
            <DatePicker
              minDate={dataHoje}
              onChange={setDataPrevA}
              value={dataPrevA}
            />
          </div>

          <div style={{ marginLeft: 25 }}>
            <p style={{ fontFamily: "Avenir Next", marginBottom: 12 }}>
              Estado
            </p>
            <Select
              options={filteredOptions}
              onChange={(value) => setEstado(value.value)}
              placeholder="Selecionar"
            />
          </div>
        </div>
        <br></br>
        <button
          style={{
            backgroundColor: "#2196f3",
            marginBottom: 15,
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
          }}
          type="text"
        >
          Submeter
        </button>
      </form>
      <hr />
      <p style={{ fontFamily: "Avenir Next" }}>Filtrar:</p>
      <Select
        options={options}
        onChange={(filtro) => setFiltro(filtro.value)}
        placeholder="Selecionar"
      />
      <div>
        <p>Selecionar Intervalo de Datas:</p>
        <div>
          <DatePicker
            onChange={(date) => setStartDate(date)}
            value={startDate}
            clearIcon={null}
          />
          <span> - </span>
          <DatePicker
            onChange={(date) => setEndDate(date)}
            value={endDate}
            clearIcon={null}
          />
        </div>
      </div>
      <table
        style={{
          backgroundColor: "#e8e8e9",
          paddingBottom: 10,
          marginTop: 10,
          paddingTop: 10,
          paddingLeft: 25,
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <tr style={{ borderRightWidth: 1 }}>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 150,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Processo
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Colaborador
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Cliente
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Estado
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 250,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Observações
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Data de Conclusão
          </td>
          <td
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: 200,
              fontFamily: "Avenir Next",
              fontWeight: "500",
            }}
          >
            Data Prevista
          </td>
        </tr>
      </table>

      {atividades
        .filter((item) => {
          if (startDate && !endDate) {
            const itemDate = item.dataPrevA.toDate(); // Convert Firestore timestamp to Date
            return itemDate >= startDate;
          } else if (!startDate && endDate) {
            const itemDate = item.dataPrevA.toDate(); // Convert Firestore timestamp to Date
            return itemDate <= endDate;
          } else if (startDate && endDate) {
            const itemDate = item.dataPrevA.toDate(); // Convert Firestore timestamp to Date
            return itemDate >= startDate && itemDate <= endDate;
          } else {
            return true; // If both startDate and endDate are null, don't apply date filter.
          }
        })
        .map((item, index) => (
          <div className="box" key={item.id}>
            <Link
              style={{ textDecoration: "none" }}
              to={`/tarefas/${item.id}`}
              state={{ item }}
            >
              <AtividadeCard key={item.id} value={item} />
            </Link>
          </div>
        ))}
    </div>
  );
};

export default Atividades;
