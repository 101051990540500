import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useParams, useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  getDoc,
  runTransaction,
  writeBatch,
} from "firebase/firestore";
import {
  getAuth,
  updateEmail,
  deleteUser as deleteAuthUser,
} from "firebase/auth";

import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase";

function Utilizador(props) {
  const adminId = useParams();

  const navigate = useNavigate();

  const { user, logout } = UserAuth();

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [nivel, setNivel] = useState("");
  const [estatuto, setEstatuto] = useState("");
  const [isActive, setIsActive] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAffair = async () => {
      const docRef = doc(db, "administradores", adminId.adminId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const adminData = docSnap.data();
        setFname(adminData.fname);
        setLname(adminData.lname);
        setEmail(adminData.email);
        setEmail2(adminData.email);
        setNivel(adminData.nivel);
        setEstatuto(adminData.estatuto);
        setIsActive(adminData.isActive);
      } else {
        console.log("No such document!");
      }

      setLoading(false); // Set loading to false after fetching the data
    };

    fetchAffair();
  }, [adminId]);

  const deleteUser = async () => {
    const confirmDelete = window.confirm("Tem a certeza que quer eliminar?");

    if (confirmDelete) {
      try {
        const adminDocRef = doc(db, "administradores", adminId.adminId);
        const globalDocRef = doc(db, "global", "gestores");

        const globalDocSnapshot = await getDoc(globalDocRef);
        const globalDocData = globalDocSnapshot.data();

        // Remove adminId.adminId from the docList array
        const updatedDocList = globalDocData.docList.filter(
          (id) => id !== adminId.adminId
        );

        // Update the global document with the modified docList
        await updateDoc(globalDocRef, {
          docList: updatedDocList,
        });

        // Query the "atividades" collection for documents with matching adminId.adminId
        const atividadesQuery = query(
          collection(db, "atividades"),
          where("admin", "==", adminId.adminId)
        );

        const reunioesQuery = query(
          collection(db, "reunioes"),
          where("admin", "==", adminId.adminId)
        );

        const usersQuery = query(
          collection(db, "users"),
          where("gestorId", "==", adminId.adminId)
        );

        // Fetch the documents that match the query
        const querySnapshot = await getDocs(atividadesQuery);
        const querySnapshot2 = await getDocs(reunioesQuery);
        const querySnapshot3 = await getDocs(usersQuery);

        // Update each matching document with a random admin value
        const randomIndex = Math.floor(Math.random() * updatedDocList.length);
        const randomAdmin = updatedDocList[randomIndex];
        querySnapshot.forEach(async (document) => {
          const atividadeDocRef = doc(db, "atividades", document.id);
          await updateDoc(atividadeDocRef, {
            admin: randomAdmin,
          });
        });
        querySnapshot2.forEach(async (document) => {
          const atividadeDocRef = doc(db, "reunioes", document.id);
          await updateDoc(atividadeDocRef, {
            admin: randomAdmin,
          });
        });
        querySnapshot3.forEach(async (document) => {
          const atividadeDocRef = doc(db, "users", document.id);
          await updateDoc(atividadeDocRef, {
            gestorId: randomAdmin,
          });
        });
        await deleteDoc(adminDocRef);
        navigate("/gestaoutilizadores");
      } catch (e) {
        console.error(e.message);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Update the email in Firestore
      await updateDoc(doc(db, "administradores", adminId.adminId), {
        fname,
        lname,
        email,
        nivel,
        estatuto,
        isActive,
      });

      // Update the email in Firebase Authentication

      const auth = getAuth();
      if (email != email2) {
        await updateEmail(auth.currentUser, email);
      }

      console.log("Document Added");
      alert("Informações guardadas com sucesso.");

      navigate("/gestaoutilizadores");
    } catch (e) {
      if (e.message == "Firebase: Error (auth/requires-recent-login).") {
        alert("Sessão expirada. Necessário efeturar o login novamente");
        logout();
        window.location.reload();
        return;
      }
      alert("Erro");
      console.log(e.message);
    }
  };
  const options = [
    { value: "Advogado", label: "Advogado" },
    { value: "Gestor de cliente", label: "Gestor de cliente" },
    { value: "CEO", label: "CEO" },
    { value: "COO", label: "COO" },
    {
      value: "Coordenador de equipa jurídica",
      label: "Coordenador de equipa jurídica",
    },
  ];
  const options2 = [
    { value: "Geral", label: "Geral" },
    { value: "Admin", label: "Admin" },
  ];
  const options3 = [
    { value: true, label: "Sim" },
    { value: false, label: "Não" },
  ];

  if (loading) {
    return (
      <div style={{ marginLeft: 300 }}>
        <h1>Loading</h1>
      </div>
    );
  }

  return (
    <div>
      <h1 style={{ marginLeft: 300, fontFamily: "Avenir Next" }}>
        Editar Utilizador
      </h1>
      <form onSubmit={handleSubmit}>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Primeiro Nome
            </td>
            <input
              maxLength={65}
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setFname(e.target.value)}
              value={fname}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Último Nome
            </td>
            <input
              maxLength={65}
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setLname(e.target.value)}
              value={lname}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Email
            </td>
            <input
              maxLength={65}
              style={{
                width: "95%",
                borderWidth: 0,
                borderBottomWidth: 1,
                fontFamily: "Avenir Next",
                fontSize: 14,
                borderBottomColor: "rgb(33, 150, 243)",
                backgroundColor: "transparent",
              }}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="text"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Estatuto
            </td>
            <Select
              options={options}
              value={options.find((option) => option.value === estatuto)}
              onChange={(value) => setEstatuto(value.value)}
              placeholder="Selecionar"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#e8e8e9",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Nivel
            </td>
            <Select
              options={options2}
              value={options2.find((option) => option.value === nivel)}
              onChange={(value) => setNivel(value.value)}
              placeholder="Selecionar"
            />
          </tr>
        </table>
        <table
          style={{
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 300,
            justifyContent: "space-evenly",
            width: "100%",
            paddingRight: 20,
          }}
        >
          <tr style={{ borderRightWidth: 1 }}>
            <td
              style={{
                borderLeftWidth: 1,
                borderLeftColor: "#000",
                width: "70%",
                fontFamily: "Avenir Next",
                fontWeight: "500",
              }}
            >
              Ativo
            </td>
            <Select
              options={options3}
              value={options3.find((option) => option.value === isActive)}
              onChange={(value) => setIsActive(value.value)}
              placeholder="Selecionar"
            />
          </tr>
        </table>

        <button
          style={{
            backgroundColor: "#2196f3",
            marginTop: 15,
            borderRadius: 5,
            border: 0,
            padding: 15,
            fontSize: 12,
            right: 0,
            marginRight: 45,
            width: "100px",
            fontFamily: "Avenir Next",
            color: "#fff",
            right: 0,
            marginLeft: 300,
          }}
          type="text"
        >
          Guardar
        </button>
      </form>
      <button
        onClick={deleteUser}
        style={{
          backgroundColor: "#f00",
          marginTop: -50,
          borderRadius: 5,
          border: 0,
          padding: 15,
          fontSize: 12,
          right: 0,
          position: "absolute",
          marginRight: 45,
          width: "100px",
          fontFamily: "Avenir Next",
          color: "#fff",
        }}
      >
        Apagar
      </button>
    </div>
  );
}

export default Utilizador;
